import RootStore from "../store";
import { Mqtt } from "../store/domainstore/mqtt";
import { ModalUiStore } from "../store/uistore/modal/ModalUiStore";

import { getCookie } from "../utils/cookie";

const LikuConfig = async () => {
  const {LikuStore} = RootStore();

  ModalUiStore.setOpen('bluetoothModal');

  let getLikus = await getCookie('likuData');
  console.log("config");
  await LikuStore.setLikus(getLikus);
  Mqtt.mqttConenct();
  Mqtt.setImageTopic();
  // console.log("config2");
};

export default LikuConfig;