import React, { useState } from 'react'
import { IconImageWrap, ImageButton, LangImage, MenuSubtitle, MenuTitle, OnOffImage } from '../style/home/HomeMenuBoxPresenter'
import { WiFiControlBox } from '../style/home/HomeWiFiAndControllPresenter'
// import korean from '../../resource/img/korean.svg';
import korean from '../../resource/img/korean.png';
import korean_Off from '../../resource/img/korean_Off.png';
import english from '../../resource/img/english.png';
import english_on from '../../resource/img/english_On.png';
// import on from '../../resource/img/on.svg';
// import off from '../../resource/img/off.svg';
import on from '../../resource/img/on.png';
import off from '../../resource/img/off.png';
import { Mqtt } from "../../store/domainstore/mqtt";
import RootStore from '../../store';
import { observer } from 'mobx-react';
import {MusicalModeModalUiStore} from "../../store/uistore/modal/MusicalModeModalUiStore";

const LikuLangPresneter = observer(() => {
    const { LikuUiStore } = RootStore();
  const [koreanOn, setKoreanOn] = useState(true);
  // console.log('koreaOn', koreanOn);
  return (
    <>
      <WiFiControlBox>
        <MenuTitle>리쿠 언어 선택</MenuTitle>
        <IconImageWrap>
          <ImageButton>
            <LangImage
              src={LikuUiStore.thisLikuLang == 'ko' ? korean : korean_Off}
            />
            <OnOffImage
              onClick={() => {
                  if (LikuUiStore.thisLikuState !== '') {
                      if (LikuUiStore.thisLikuState === 'musical') {
                          MusicalModeModalUiStore.setVisible(true);
                      } else {
                          if (LikuUiStore.thisLikuLang == 'ko') {
                              Mqtt.setLanguageEn();
                          } else {
                              Mqtt.setLanguageKo();
                          }
                      }
                  }
            }}
             src={LikuUiStore.thisLikuLang == 'ko' ? on : off} />
            {/* <LangImage*/}
            {/*  src={LikuUiStore.thisLikuLang == 'en' ? english_on : english}*/}
            {/*/>*/}
          </ImageButton>
          <ImageButton>
            <LangImage
              src={LikuUiStore.thisLikuLang === 'ko' ? english : english_on}
              // src={koreanOn ? english : english_on}
            />
            <OnOffImage
                onClick={() => {
                    if (LikuUiStore.thisLikuState !== '') {
                        if (LikuUiStore.thisLikuState === 'musical') {
                            MusicalModeModalUiStore.setVisible(true);
                        } else {
                            if (LikuUiStore.thisLikuLang == 'ko') {
                                Mqtt.setLanguageEn();
                            } else {
                                Mqtt.setLanguageKo();
                            }
                        }
                    }
            }}
             src={LikuUiStore.thisLikuLang == 'en' ? on : off} />
          </ImageButton>
        </IconImageWrap>
        <MenuSubtitle>선택언어로 대화/발음합니다.</MenuSubtitle>

      </WiFiControlBox>
    </>
  )
});

export default LikuLangPresneter
