import styled from "styled-components";
import { defaultTheme } from "../Theme";

export const SliderWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  height: 40%;
  width: 85%;
  /* margin-bottom: 0.25rem; */
  /* background-color: darkcyan; */
  `;

export const SliderMainWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  height: 40%;
  width: 85%;
  /* margin-bottom: 0.25rem; */
  /* background-color: darkcyan; */
  `;

export const SliderText = styled.div`
  font-size: .625rem;
  text-align: center;
  width: 20%;
  margin-right: 0.5rem;
  ${defaultTheme.device.tablet} {
    font-size: 1rem;
  }
`;

export const SliderMainText = styled.div`
  font-size: .625rem;
  text-align: center;
  width: 10%;
  margin-right: 0.5rem;

  ${defaultTheme.device.tablet} {
    font-size: 1rem;
  }
`;

export const VolumeSlider = styled.input`
  overflow: hidden;
  display: block;
  appearance: none;
  max-width: 700px;
  width: 70%;
  margin: 0;
  height: 26px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.325rem;
    border-radius: 0.5rem;
    /* background: rebeccapurple; */
    background: #939393;
    /* box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2); */
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: 1rem;
    width: 1rem;
    background: white;
    border-radius: 100%;
    border: 1px solid #cecece;
    top: 50%;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.15);
    transform: translateY(-50%);
    transition: background-color 150ms;
  }

`;

export const VolumeMainSlider = styled.input`
  overflow: hidden;
  display: block;
  appearance: none;
  max-width: 700px;
  width: 80%;
  margin: 0;
  height: 26px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.325rem;
    border-radius: 0.5rem;
    /* background: rebeccapurple; */
    background: #939393;
    /* box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2); */
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: 1rem;
    width: 1rem;
    background: white;
    border-radius: 100%;
    border: 1px solid #cecece;
    top: 50%;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.15);
    transform: translateY(-50%);
    transition: background-color 150ms;
  }

`;
