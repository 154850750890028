import { observer } from 'mobx-react';
import React from 'react'

import { Mqtt } from '../../../store/domainstore/mqtt';
import { VolumeBox, VolumeBoxMain, VolumeTitle, VolumeTitleWrap, VolumeUp } from '../../style/home/HomeVolumePresenter'
import { SliderMainText, SliderMainWrap, SliderText, SliderWrap, VolumeMainSlider, VolumeSlider } from '../../style/Slider/Slider';
import { defaultTheme } from '../../style/Theme';

const HomeVolumeMainPresenter = observer( props => {
  // console.log(Mqtt.volumeAppList.indexOf(Number(Mqtt.likuVolume)));
  const renderSlider = optionsArray => (
    <VolumeMainSlider 
      type="range" 
      step="1"
      min="0" 
      max={optionsArray.length - 1}
      // defaultValue={
      //  optionsArray.indexOf(Number(Mqtt.likuVolume))
      // }
      value={optionsArray.indexOf(Number(Mqtt.likuVolume))}
      onChange={(e) => {
        Mqtt.setLikuVolume(optionsArray[e.target.value]);
        Mqtt.setVolumeTopic(optionsArray[e.target.value]);
      }}
    />
  );

  return (
    <VolumeBoxMain>
      <VolumeTitleWrap>
        <VolumeUp />
        {/* <VolumeTitle>볼륨 설정</VolumeTitle> */}
      </VolumeTitleWrap>
      <SliderMainWrap>
        <SliderMainText>{Mqtt.likuVolume}</SliderMainText>
        {/* <SliderText>크기</SliderText> */}
        {renderSlider(Mqtt.volumeAppList)}
      </SliderMainWrap>
    </VolumeBoxMain>
  )
});

export default HomeVolumeMainPresenter
