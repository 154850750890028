import React from 'react'
import { GrClose } from 'react-icons/gr'
import RootStore from '../../../store'
import { Close, CloseBtn, CloseBtnTop, ModalWrapper, SubmitButtons } from '../../style/modal/connect/LikuConnectModalPresenter'
import { ButtoContent, ModalDelWrap, ModalTitle2, ModalTitleBoldText, ModalTitleText } from '../../style/modal/modal'
import { defaultTheme } from '../../style/Theme'

const DeleteLikuModalPresenter = ({
  open,
  setOpen,
  history
   }) => {
    const { LikuUiStore, ModalUiStore } = RootStore();

    return (
		<ModalWrapper
		visible={open}
	>
    <ModalDelWrap>
      <ModalTitle2>
        {/* <ModalTitleBoldText>
          {Liku}
        </ModalTitleBoldText> */}
        <ModalTitleText>리쿠를 삭제하시겠습니까?</ModalTitleText>
        <CloseBtnTop  onClick={() => ModalUiStore.setOpen('deleteLikuModal')} >
          <Close />
        </CloseBtnTop>
      </ModalTitle2>
      <ButtoContent>
        {/* <SubmitButtons title='취소' onClick={() => setOpen('deleteLikuModal')}>취소</SubmitButtons> */}
        <SubmitButtons title='삭제' onClick={async() => {
             await ModalUiStore.setOpen('waitModal');
            await ModalUiStore.setOpen('deleteLikuModal');
            await LikuUiStore.updateLikuStop();
            await history.push('/');
            await LikuUiStore.removeLiku();
        }}>삭제</SubmitButtons>
      </ButtoContent>
    </ModalDelWrap>
  </ModalWrapper>
  )
}

export default DeleteLikuModalPresenter;
