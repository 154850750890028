import React, { useState } from 'react'
import { CheckContent, Item, MainText, OutlineCheck, OutlineChecks, RadioButton, RadioButtonLabel, RadioWrap, SubText, TextWrap, Wrapper } from '../../../style/modal/LikuStateCheckPresenter'

const LikuNetworkCheckPresenter = () => {
	const [selectNetwork, setSelectNetwork] = useState('ntcomplete');
	const [clicks, setClicks] = useState(0);
  const handleClicks = () => setClicks(1);
	const handleNetwork = (e) => {
		setSelectNetwork(e.target.value);
		handleClicks();
	}

	return (
		<CheckContent>
        <TextWrap>
          <MainText>네트워크</MainText>
          <SubText>(챗봇, Wi-Fi등)</SubText>
        </TextWrap>
        <RadioWrap>
				<Wrapper>
          <Item>
            원활
            <RadioButton
              type="radio"
              name="radio"
              value="ntcomplete"
              color='blue'
							checked={selectNetwork === "ntcomplete"}
							onChange={handleNetwork} />
            <RadioButtonLabel />
            {/* <OutlineCheck check={clicks ? 1 : 0} /> */}
          </Item>
          <Item>
            <div>문제</div>
            <RadioButton
              type="radio"
              name="radio"
              color='red'
              value="ntproblem"
							checked={selectNetwork === "ntproblem"}
							onChange={handleNetwork} />
            <RadioButtonLabel />
            {/* <OutlineChecks check={clicks ? 1 : 0} /> */}
          </Item>
        </Wrapper>
        </RadioWrap>
		</CheckContent>
	)
}

export default LikuNetworkCheckPresenter;
