import { LikuStore } from "./domainstore/LikuStore";
import { LikuWebPostStore } from "./uistore/LikuWebPostStore";
import { ModalUiStore } from "./uistore/modal/ModalUiStore";
import { LikuUiStore } from "./uistore/LikuUiStore";
import { LikuConnectModalUiStore } from './uistore/modal/LikuConnectModalUiStore';
import { SuccessFailureModalUiStore } from "./uistore/modal/SuccessFailureModalUiStore";
import { WiFiSettingModalUiStore } from "./uistore/modal/WiFiSettingModalUiStore"
import { DrawerStore } from  "./uistore/DrawerStore";
import { SpeakingUiStore } from  "./uistore/SpeakingUiStore";
import { UserStore } from  "./domainstore/UserStore";
import { ServerStore } from  "./domainstore/ServerStore";
import { UserUiStore } from  "./uistore/UserUiStore";
import { ControlUiStore } from  "./uistore/ControlUiStore";
import { VisualizationUiStore } from  "./uistore/VisualizationUiStore";
import { DeleteModalUiStore } from  "./uistore/modal/DeleteModalUiStore";
import { LikuConnectDataStore } from  "./uistore/modal/LikuConnectDataStore";
import { BluetoothStore } from  "./domainstore/BluetoothStore";
import {MainUpdateModalUiStore} from "./uistore/MainUpdateModalUiStore";
import {MusicalModeModalUiStore} from "./uistore/modal/MusicalModeModalUiStore";

const RootStore = () => {
  return {
    LikuStore,
    ModalUiStore,
    LikuWebPostStore,
    SuccessFailureModalUiStore,
    LikuConnectModalUiStore,
    LikuConnectDataStore,
    LikuUiStore,
    WiFiSettingModalUiStore,
    DrawerStore,
    ControlUiStore,
    SpeakingUiStore,
    UserStore,
    UserUiStore,
    ServerStore,
    DeleteModalUiStore,
    VisualizationUiStore,
    BluetoothStore,
    MainUpdateModalUiStore,
    MusicalModeModalUiStore,
  };
};

export default RootStore;
