import React from 'react'
import { MotionBtnText, MotionBtnWrap } from '../../style/control/MotionBtnPrensenter'

const MotionBtnPrensenter = ({title}) => {
  return (
    <MotionBtnWrap>
      <MotionBtnText>{title}</MotionBtnText>
    </MotionBtnWrap> 
  )
}

export default MotionBtnPrensenter
