import { observer } from 'mobx-react';
import React from 'react'

import { Mqtt } from '../../../store/domainstore/mqtt';
import { VolumeBox, VolumeTitle, VolumeTitleWrap, VolumeUp } from '../../style/home/HomeVolumePresenter'
import { SliderText, SliderWrap, VolumeSlider } from '../../style/Slider/Slider';
import { defaultTheme } from '../../style/Theme';

const HomeVolumePresenter = observer( props => {
  // console.log(Mqtt.volumeAppList.indexOf(Number(Mqtt.likuVolume)));
  const renderSlider = optionsArray => (
    <VolumeSlider 
      type="range" 
      step="1"
      min="0" 
      max={optionsArray.length - 1}
      // defaultValue={
      //  optionsArray.indexOf(Number(Mqtt.likuVolume))
      // }
      value={optionsArray.indexOf(Number(Mqtt.likuVolume))}
      onChange={(e) => {
        Mqtt.setLikuVolume(optionsArray[e.target.value]);
        Mqtt.setVolumeTopic(optionsArray[e.target.value]);
      }}
    />
  );

  return (
    <VolumeBox>
      <VolumeTitleWrap>
        <VolumeUp />
        {/* <VolumeTitle>볼륨 설정</VolumeTitle> */}
      </VolumeTitleWrap>
      <SliderWrap>
        <SliderText>{Mqtt.likuVolume}</SliderText>
        {/* <SliderText>크기</SliderText> */}
        {renderSlider(Mqtt.volumeAppList)}
      </SliderWrap>
    </VolumeBox>
  )
});

export default HomeVolumePresenter
