import React from "react";

import * as Musical from "../../style/modal/MusicalModeModal";

import RootStore from "../../../store";

const MusicalModeModalContainer = ({isBack, history}) => {
    const {MusicalModeModalUiStore} = RootStore();

    return (
        <Musical.Modal>
            <Musical.Context>
                <Musical.ContextWrap>
                    <Musical.Title>콘텐츠 실행 중입니다.</Musical.Title>
                    <Musical.Info>콘텐츠 실행을 중지하시거나<br />리쿠 머리를 만져서 중지 해주세요.</Musical.Info>
                </Musical.ContextWrap>
                <Musical.Button
                    onClick={() => {
                        MusicalModeModalUiStore.setVisible(false);
                        isBack && history.push('/home');
                    }}
                >
                    확인
                </Musical.Button>
            </Musical.Context>
        </Musical.Modal>
    )
}

export default MusicalModeModalContainer;
