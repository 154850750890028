import { observable } from "mobx";
import { ControlUiStore } from "../ControlUiStore";
import { LikuUiStore } from "../LikuUiStore";
import { ModalUiStore } from "./ModalUiStore";

const DeleteModalUiStore = observable({

  item: '',
  type: '',

  setDelete(item, type) {
    console.log('setDelete', item)
    console.log('setDelete', type)
    this.item = item;
    this.type = type;
    ModalUiStore.setOpen('deleteModal');
  },

  async setDeleteFunction() {
    switch (this.type) {
      case 'liku':
        await LikuUiStore.updateLikuStop();
        console.log('setDeleteFunction');
        await LikuUiStore.removeLiku();
        break;
      case 'control':
        await ControlUiStore.removeSpeakingList(
          this.item.item,
          this.item.index,
        );
        break;
    }
    ModalUiStore.setOpen('deleteModal');
  },
});

export {DeleteModalUiStore};
