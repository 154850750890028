import { HiVolumeUp } from "react-icons/hi";
import styled from "styled-components";
import { defaultTheme } from "../Theme";


export const RemoteVolumeBox = styled.div`
  height: 100%;
  width:18%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  `;

export const VolumeTitleWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center; 
  flex-direction: column;
`;

export const VolumeTitle = styled.div`
  font-size: ${defaultTheme.fonts.size.semini};
  font-weight: bold;
  margin: 0 2%;
`;

export const ColumnVolumeSlider = styled.input`
  overflow: hidden;
  display: block;
  /* appearance: none; */
  max-width: 90px;
  /* width: 70%; */
  margin: 0;
  height: 40px;
  cursor: pointer;
  transform: rotate(270deg);

  /* &:focus {
    outline: none;
  }
  
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.325rem;
    border-radius: 0.5rem;
    background: #939393;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: 1rem;
    width: 1rem;
    background: white;
    border-radius: 100%;
    border: 1px solid #cecece;
    top: 50%;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.15);
    transform: translateY(-50%);
    transition: background-color 150ms;
  } */

`;
  

export const VolumeBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: white;
  width: 60%;
  height: 90%;
  border-radius: 8px;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
`;

export const VolumeIcon = styled(HiVolumeUp)`
  margin-top: 2rem;
  font-size: 1rem;
`;


export const SliderText = styled.div`
  font-size: 0.625rem;
  text-align: center;
  width: 20%;
  /* margin-top: -0.25rem; */
`;