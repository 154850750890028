import styled from "styled-components";
import { defaultTheme } from "../../Theme";

export const ModalTitleWrap = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  height: 68%;
  width: 74%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  ${defaultTheme.device.tablet} {
    height: 60%;
    width: 70%;
  }
`;

export const LikuWifiConnectWrap = styled.div`
  display: flex;
  position: absolute;
  top: 30%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 74%;
  height: ${props => props.sizeUp ? '40%' : '30%'};
  flex-direction: column;
  justify-content: space-between;
  background-color:white;
  box-shadow: 24;
 /* padding: 4px; */
 ${defaultTheme.device.tablet} {
   width: 50%;
   height: 28%;
 }
`;

export const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 1rem;
`;

export const ModalWifiTitle = styled.div`
  font-size: 1rem;
  margin-left: 2%;
  font-weight: 600;
  ${defaultTheme.device.tablet} {
    font-size: 1.8rem;
  }
`;

export const ModalSubText = styled.div`
  font-size: 0.725rem;
  margin-left: 2%;

  ${defaultTheme.device.mobile} {
    font-size: .725rem;
  }

  ${defaultTheme.device.tablet} {
    font-size: 1.2rem;
    /* margin-top: .25rem; */
  }
`;

export const ConectedWrap = styled.div`
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #ffffff;

`;

export const ConectedContent = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  /* justify-content: center; */
  /* mar-left: 5%; */
  flex-direction: column;
  border-style: dashed;
  border-width: 0;
  border-bottom-width: 1px;
  overflow-y: scroll;
`;

export const NewContent = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  /* justify-content: center; */
  /* mar-left: 5%; */
  flex-direction: column;
`;

export const NewWiWrap = styled.div`
  height: 46%;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  overflow-y: auto;
`;

export const WifiTitle = styled.div`
  font-size: ${defaultTheme.fonts.size.semini}px;
  /* margin-left: 2%; */
  font-weight: 500;
  margin-top: 10%;
  margin-bottom: 4%;
`;

export const WifiList = styled.div`
  font-size: ${defaultTheme.fonts.size.mini}px;
  /* margin-left: 2%;
  margin-top: 2%; */
  width: 200px;
  height: 25px;
  margin: 0;
`;

export const LikuWifiInputWrap = styled.div`
  position: relative;
  width: 100%;
  height: 80%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ButtonWrap = styled.button`
  width: 100%;
  height: 20%;
  border: none;
  color: white;
  background-color: #4a4a4a;
  ${defaultTheme.device.tablet} {
    height: 15%;
  }
`;

export const WifiButtonText = styled.div`
  font-size: ${defaultTheme.fonts.size.semini};
  font-weight: bold;
  ${defaultTheme.device.tablet} {
    font-size: 1.125rem;
  }
`;

// export const PasswordInput = styled(TextField)`
//   width: 200px;
//   font-size: 0.5rem;
// `;

export const PasswordConfirmWrap = styled.div`
  width: 200px;
  /* background-color: skyblue; */
`;

export const PasswordConfirmText = styled.div`
  font-size: ${defaultTheme.fonts.size.mini};
`;

export const TitleIconWrap = styled.div`
  position: relative;
  width: 100%;
  height: 22%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
`;

export const WifiListWrap = styled.div`
  width: 65%;
  height: 78%;
  display: flex;
  flex-direction: column;
  /* padding: .5rem 2.5rem; */
  overflow: hidden;
  /* background-color: seagreen; */
`;

export const WifiListTitle = styled.div`
  font-size: .825rem;
  font-weight: 600;
  margin-top: .825rem;
  margin-bottom: -.25rem;
  padding: 0 0.25rem;

  ${defaultTheme.device.tablet} {
    font-size: 1.325rem;
  }
`;

export const WifiName = styled.div`
  margin-top: 0.325rem;
  margin: 0.55em 0;
  font-size: 0.75rem;
  /* background-color: red; */
  text-align: left;
  line-height: 0.8rem;
  ${defaultTheme.device.tablet} {
    font-size: 1.25rem;
    font-weight: 400;
  } 
`;

export const WifiLists = styled.button`
  display: flex;
  flex-direction: column;
  margin-top: 0.3rem;
  height: 80%;
  width: 100%;
  overflow: scroll;
  background-color: white;
  border: none;
`;

