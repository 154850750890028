import React, { useEffect, useState } from "react";
import RootStore from "../../../store";
import { observer } from "mobx-react";
import {
  ModalContext,
  ModalScreen,
  ModalTitleText,
  WaitModalText,
} from "../../style/modal/modal";
import { ModalWrapper } from "../../style/modal/connect/LikuConnectModalPresenter";
import Loader from "react-loader-spinner";

const WaitModalContainer = observer(() => {
  const [count, setCount] = useState(50);
  const { ModalUiStore } = RootStore();

  useEffect(() => {
    if (ModalUiStore.isCountDown) {
      setTimeout(() => {
        if (count > 0) {
          setCount(count - 1);
        } else {
          ModalUiStore.setIsCountDown();
          ModalUiStore.setOpen("waitModal");
          setCount(50);
        }
      }, 1000);
    }
  }, [ModalUiStore, ModalUiStore.isCountDown, count]);

  return (
    <ModalWrapper visible={ModalUiStore.open.waitModal}>
      <ModalScreen>
        <ModalContext>
          <WaitModalText>잠시만 기다려주세요...</WaitModalText>
        </ModalContext>
        {ModalUiStore.isCountDown && <ModalTitleText>{count}</ModalTitleText>}
        <Loader
          type="Oval"
          color="#00BFFF"
          height={40}
          width={40} //3 secs
        />
      </ModalScreen>
    </ModalWrapper>
  );
});

export default WaitModalContainer;
