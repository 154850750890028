import React from 'react'
import { ActionHeaderTap, ActionInputWrap, ActionMainWrap, ActionPlayWrap } from '../style/action/LikuActionControlPage';
import { ContainerWrap } from '../style/ContainerStyled';

const LikuActionControlPage = () => {
  return (
    <ContainerWrap>
      <ActionHeaderTap>
      </ActionHeaderTap>
      <ActionInputWrap>

      </ActionInputWrap>
      <ActionMainWrap>

      </ActionMainWrap>
      <ActionPlayWrap>

      </ActionPlayWrap>
    </ContainerWrap>
  )
}

export default LikuActionControlPage;