import { authenticate } from "../store/api/ServerApi";
import { ServerStore } from "../store/domainstore/ServerStore";

const ServerConfig = async () => {
  ServerStore.account = {email: 'torooc@torooc.com', password: 'robot2021'};
  ServerStore.BASE_URL = 'https://api.likuwithme.com/liku';
  await authenticate().then(r => {
    ServerStore.tokenJson = r;
    console.log('ServerStore.tokenJson', ServerStore.tokenJson);
  });
};

export default ServerConfig;
