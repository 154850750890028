import styled from "styled-components";
import { AiFillPlusCircle, AiFillPlayCircle } from "react-icons/ai";
import { defaultTheme } from "../Theme";

export const LikuSpeackingWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;
export const PlusContentWrap = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  font-size: .625rem;
  color: #97989a;
  ${defaultTheme.device.tablet} {
    font-size: 1rem;
  }
  /* box-shadow: 5px -3px 5px 1px rgba(0,0,0,0.12); */
`;

export const SpeakTextInputWrap = styled.input`
  width: 80%;
  height: 100%;
  border: none;
  background-color: inherit;
  text-decoration: none;
  outline: none;
  padding: 0 4%;
  font-size: 0.725rem;
  ::placeholder {
    font-size: 0.725rem;
  }
  ${defaultTheme.device.tablet} {
    font-size: 1rem;
    ::placeholder {
    font-size: 1rem;
    }
  }

`;

export const PlusCircle = styled(AiFillPlusCircle)`
  font-size: 5rem;
  margin-top: .25rem;
`;

export const PlayCircle = styled(AiFillPlayCircle)`
  font-size: 1.8rem;
  ${defaultTheme.device.tablet} {
    font-size: 3.8rem;
  }
`;
export const PlayText = styled.div`
  font-size: .4rem;
  font-weight: bold;
  ${defaultTheme.device.tablet} {
    font-size: 1rem;
  }
`;

export const PlayWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  opacity: 0.8;
  right: .6rem;
  top: .25rem;
  color: #4a4a4a;
  ${defaultTheme.device.tablet} {
    right: 1.5rem;
    /* top: .5rem; */
  }
`;
