import {listener} from "./Listener";

export const requestPermission = (data) => {
    if (window.ReactNativeWebView) {
        // 모바일이라면 모바일의 카메라 권한을 물어보는 액션을 전달합니다.
        /** android */
        document.addEventListener("message", listener);
        /** ios */
        window.addEventListener("message", listener);
        window.ReactNativeWebView.postMessage(
            JSON.stringify(data)
        );
    } else {
        // 모바일이 아니라면 모바일 아님을 alert로 띄웁니다.
        // alert({ message: ERROR_TYPES.notMobile });
    }
}
