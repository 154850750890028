import React, { useState } from "react";
import {
  ChevronDown,
  ChevronUp,
  ControllActionDisplayView,
  LikiMotionWrap,
  LikuEmptyContentOpenTop,
  LikuIconContents,
  LikuIconImgWrap,
  LikuMitonContent,
  LikuMotionBox,
  LikuMotionBtnsWrap,
  LikuMotionBtnWrap,
  LikuMotionImg,
  LikuMotionSubText,
  LikuMotionText,
  LikuMotionVolumeContent,
  LikuSleepImg,
  LikuSleepText,
  LikuStateWrap,
} from "../../style/control/ControlLikuStateContainer";
import liku_sleep from "../../../resource/img/liku_sleep.png";
import liku_wakeup from "../../../resource/img/liku_wakeup.png";
import zzz from "../../../resource/img/zzz.png";
import RemoteVolumePresenter from "../../presenter/control/RemoteVolumePresenter";
import ControlReactionContainer from "./ControlReactionContainer";
import RootStore from "../../../store";
import { BsAlarm } from "react-icons/bs";
import { Mqtt } from "../../../store/domainstore/mqtt";
import { observer } from "mobx-react";

const ControlLikuStateContainer = observer(() => {
  const { LikuUiStore, ControlUiStore } = RootStore();
  // const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const handeOpenBar = () => setOpen(!open);
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const renderPerson = (person, idx) => {
    return (
      <LikuMotionBtnsWrap key={idx}>
        <LikuMotionText>{person.title}</LikuMotionText>
        <LikuMotionSubText>{person.title}</LikuMotionSubText>
      </LikuMotionBtnsWrap>
    );
  };

  return (
    <>
      <LikiMotionWrap height={open}>
        <LikuMitonContent bottom={open}>
          <LikuStateWrap>
            <LikuIconContents>
              <LikuIconImgWrap>
                <LikuSleepImg
                  src={
                    LikuUiStore.thisLikuState === "자는중"
                      ? liku_sleep
                      : liku_wakeup
                  }
                />
                <LikuSleepText>{LikuUiStore.thisLikuState}</LikuSleepText>
              </LikuIconImgWrap>
              <LikuMotionBox>
                <LikuMotionBtnWrap
                  onClick={() => {
                    ControlUiStore.controlFunction(
                      {
                        type: "likuState",
                        json: ControlUiStore.likuStateJson.sleep.json,
                      },
                      "자는중"
                    );
                  }}
                >
                  <LikuMotionImg src={zzz} />
                  <LikuMotionText>리쿠 재우기</LikuMotionText>
                </LikuMotionBtnWrap>
                <LikuMotionBtnWrap
                  onClick={() => {
                    Mqtt.setLikuWakeupTopic();
                    ControlUiStore.likuStateChange(
                      ControlUiStore.likuStateJson.wakeUp.json,
                      "일어남"
                    );
                  }}
                >
                  <BsAlarm />
                  <LikuMotionText>리쿠 깨우기</LikuMotionText>
                </LikuMotionBtnWrap>
              </LikuMotionBox>
            </LikuIconContents>
            <LikuEmptyContentOpenTop onClick={() => handeOpenBar()}>
              {open === false ? <ChevronUp /> : <ChevronDown />}
            </LikuEmptyContentOpenTop>
          </LikuStateWrap>
          <LikuMotionVolumeContent>
            <RemoteVolumePresenter />
            <ControllActionDisplayView>
              <ControlReactionContainer />
            </ControllActionDisplayView>
          </LikuMotionVolumeContent>
        </LikuMitonContent>
      </LikiMotionWrap>
    </>
  );
});

export default ControlLikuStateContainer;
