import styled from "styled-components";
import { defaultTheme } from "../Theme";

export const InputStateBox = styled.div`
  position: absolute;
  width: 80%;
  /* top: 1.4rem; */
  height: 70%;
  display: flex;
  overflow-y: scroll;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  /* background-color: blue; */
`;

export const StartText = styled.div`
  font-size: 0.825rem;
  ${defaultTheme.device.tablet} {
    font-size: 1.4rem;
  }

`;

export const StartBox = styled.div`
  width: 42%;
  height: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f4f2f1;
  border-radius: 0.525rem;
  margin-bottom: 0.2rem;
  margin-right: 4.5rem;
  box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.16);
`;

export const ReactionView = styled.div`
  width: 65%;
  height: 84%;
  margin: 1%;
  background-color: white;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.16);
`;

export const KeyboardBox = styled.div`
  width: 98%;
  height: 40%;
  //margin-top: -1%;
  padding-bottom: 3%;
  background: #f7f7f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export const ReactionButtonTitle = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
`;

export const ReactionButtonInfo = styled.div`
  font-size: 0.5rem;
  margin-top: -2%;
`;