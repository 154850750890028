import { observer } from 'mobx-react';
import React, {useRef, useEffect, useState} from 'react'
import { FiCheckCircle } from 'react-icons/fi';
import { IoMdRefresh } from "react-icons/io";
import { IoExitOutline } from 'react-icons/io5';
import { RiArrowGoBackFill } from 'react-icons/ri';
import RootStore from '../../../../store';
import { Mqtt } from '../../../../store/domainstore/mqtt';
import { BackWrap, ButtonContent, ButtonRetry, ButtonWrap, CheckCircle, Close, CloseBtn, CloseBtnTop, ModalWrapper, Refresh, Refreshes, SubmitButton, SubmitButtons, SubmitSubButton, TitleWrap } from '../../../style/modal/connect/LikuConnectModalPresenter';
import { LikuUserContent, ModalTitle, ModalTitleWrap, UseName, UserBox, UserDiv, UserPic, UserSub, UserTitle, UserWrap } from '../../../style/modal/HomeLikuStateModalPresenter';
import { TitleIconWrap } from '../../../style/modal/wifi/HomeLikuWifiModalPresenter';
import { defaultTheme } from '../../../style/Theme';

const HomeLikuUserCheckModalPresenter = observer(({open, name}) => {
  const { ModalUiStore, UserStore, VisualizationUiStore, ControlUiStore } = RootStore();
  const [visible, setVisible] = useState(false);
  const onChage = () => setVisible(!visible);

  return ( 
    <ModalWrapper
      visible={open}>
      <ModalTitleWrap>
        <TitleIconWrap>
          <TitleWrap> 
            <ModalTitle>네모 표시된 얼굴을 확인해주세요!</ModalTitle>
          </TitleWrap>
          <CloseBtnTop onClick={ async () => {
              await ModalUiStore.setOpen('userCheckModal');
              await Mqtt.setImageTopic();
              await ControlUiStore.controlFunction({type: 'stop'});
              await UserStore.setResetUser();
            }} >
            <Close />
          </CloseBtnTop>
        </TitleIconWrap>
        <LikuUserContent>
				<UserWrap>
          <UserTitle>이름</UserTitle>
					<UserSub>:</UserSub>
					<UseName value={UserStore.user} />
				</UserWrap>
        <LikuCamera />
			</LikuUserContent>
      <ButtonWrap>
        <ButtonContent click={true} onClick={async() => {
					await ModalUiStore.setOpen('userCheckModal');
          await Mqtt.setUserSuccessTopic();
					await ModalUiStore.setOpen('userRecognizeModal');
          await Mqtt.setImageTopic();
        }} >
          <CheckCircle />
  			  <SubmitSubButton>등록</SubmitSubButton>
        </ButtonContent>
        <ButtonRetry onClick={() => {
          Mqtt.setUserFailTopic();
        }} title="재인식">
          <Refreshes />
  			  <SubmitSubButton>재인식</SubmitSubButton>
        </ButtonRetry>
      </ButtonWrap>
      </ModalTitleWrap>
      <BackWrap onClick={async() =>{
        await ModalUiStore.setOpen('userCheckModal');
        await ModalUiStore.setOpen('userEnrollmentModal');
      }}>
        <RiArrowGoBackFill style={{marginRight: '.5rem'}} />
        이전으로
      </BackWrap>
  </ModalWrapper>
  )
});

const LikuCamera = observer(() => {
  const { VisualizationUiStore } = RootStore();
  let ctx;
  const UserPicRef = useRef();
  const UserBoxRef = useRef();

  useEffect(() => {
    VisualizationUiStore.setDrawingBoxFunction(drawingBox.bind(this));
    ctx = UserBoxRef.current.getContext("2d");
    console.log('UserBoxRef.current.width', UserBoxRef.current.width);
    console.log('UserBoxRef.current.height', UserBoxRef.current.height);
    // UserBoxRef.current.width = UserPicRef.current.width;
    // UserBoxRef.current.height = UserPicRef.current.height;
    // console.log('UserBoxRef.current.width', UserBoxRef.current.width);
    // console.log('UserBoxRef.current.height', UserBoxRef.current.height);
    // this.ctx.strokeStyle = 'rgb(250,255,0)';
    ctx.font = 'bold 12px italic';
    // ctx.fillStyle = 'rgb(255,255,255)';
    ctx.lineWidth = 3;

    // ctx.fillStyle = "rgb(200, 0, 0)";
    // ctx.fillRect(10, 10, 50, 50);

    // ctx.strokeStyle = 'rgb(200, 0, 0)';
    // ctx.strokeRect(10, 10, 50, 50);
  }, []);

  const drawingBox = (data) => {
    console.log('drawingBox');
    ctx.clearRect(0, 0, UserBoxRef.current.width, UserBoxRef.current.height);
    try {
        for (let person of data) {
          console.log('person', person);
            // this.ctx.strokeRect(270 * person.bbox.x1, 100 * person.bbox.y1, 90, 90);
            let emotion = person.emotion.split('.');

            try {
                if(UserBoxRef.current.width * person.bbox.x1 != null && UserBoxRef.current.width * person.bbox.x2 != null) {
                    if(person.PO === true) {
                      console.log('person PO');
                      // ctx.strokeStyle = 'rgb(255,0,0)';
                      ctx.strokeStyle = 'rgb(200, 0, 0)';
                      ctx.strokeRect(
                          UserBoxRef.current.width * person.bbox.x1,
                          UserBoxRef.current.height * person.bbox.y1,
                          Math.abs(person.bbox.x2 - person.bbox.x1) * UserBoxRef.current.width,
                          Math.abs(person.bbox.y2 - person.bbox.y1) * UserBoxRef.current.height
                      );
                    }
                }
            } catch (error) {
                console.log('this.personb.bbox.x1 x2 Null!!!');
            }
        }
    } catch (error) {
        console.log('drawingBox error');
        ctx.clearRect(0, 0, UserBoxRef.current.width, UserBoxRef.current.height);
    }
  }

  return (
    <UserDiv>
      <UserPic src={VisualizationUiStore.rgbData} ref={UserPicRef} />
      <UserBox ref={UserBoxRef} />
    </UserDiv>
  );
});

export default HomeLikuUserCheckModalPresenter;
