import styled from "styled-components";
import { GoGear } from "react-icons/go";
import { defaultTheme } from "../Theme";

export const LikuSettingBoxWrap = styled.div`
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
	height: 80%;
	width: 30%;
	display: flex;
	padding: 0 1%;
	justify-content: center;
	align-items: center;
  border-radius: 0.125rem;
	flex-direction: column;
	z-index: 99;
	background-color: ${props => 
		props.click ? '#e87282' : '#939393'};
	/* margin-top: aut; */
	${defaultTheme.device.tablet} {
		height: 60%;
		width: 20%;
	}
`;

export const LkiuSettingIcon = styled.img`
  width: 1.3rem;
  margin: 0.25rem 0 0;
	color: red;
`;

export const LkiuSettingIconMemo = styled.img`
  width: 28%;
  margin: 0.25rem 0 0;
  opacity: ${props =>
		props.opacity ? '1' : '0.7' }
	/* ${defaultTheme.device.tablet} {
		width: 1.625rem;
  	margin: 0.3rem 0;
	} */
`;

export const LkiuSettingIconDel = styled.img`
  width: 26%;
  margin: 0.25rem 0 0;
  /* opacity: 0.7; */
`;

export const LkiuSettingIconGear = styled(GoGear)`
	font-size: 1.4rem;
	color: #939393;
	${defaultTheme.device.tablet} {
		font-size: 2rem;
	}
`;

export const SettingIConWrap = styled.div`
	width: 32px;
	height: 32px;
	border-radius: 50px;
	background-color: white;
	position: absolute;
  top: 0;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
	display: flex;
	align-items: center;
	justify-content: center;
	${defaultTheme.device.tablet} {
		width: 3rem;
		height: 3rem;
	}
`;

export const LkiuSettingText = styled.div`
	font-size: ${props => props.$type == 'del' ? '.5rem' : '0.625rem'};
	text-align: center;
	color: white;
	opacity: 0.8;
	margin-top: .3rem;
	:hover {
		color: white;
		opacity: 1;
	}
	${defaultTheme.device.tablet} {
		font-size: 1rem;
	}
`;
