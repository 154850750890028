import RootStore from "../store";
import { LikuConnectDataStore } from "../store/uistore/modal/LikuConnectDataStore";

export const listener = async event => {
    const { LikuWebPostStore, BluetoothStore, ControlUiStore, LikuUiStore } = RootStore();
    let data = JSON.parse(event.data);
    console.log(data);
    switch (data.type) {
        case 'Liku_getList':
            LikuWebPostStore.setBluetoothList(data.data);
            break;
        case 'Liku_connect': 
            await LikuWebPostStore.setLikuData(data.data);
            await LikuConnectDataStore.likuWifiConnectFunction();
        break;
        case 'Liku_connect_normal':
            await LikuWebPostStore.setLikuData(data.data);
            await LikuUiStore.updataData();
        break;
        case 'Liku_disconnect':
            console.log('Liku_disconnect');
            await LikuWebPostStore.setBluetoothConnect(false);
        break;
        case 'Record_Start':
        break;
        case 'Record_Stop':
            ControlUiStore.setSpeakingTTs(data.data);
        break;
        case 'Liku_getDeviceInfo':
            await LikuWebPostStore.setBluetoothDeviceInfo(data.data);
            await LikuUiStore.updataDataData();
        break;
        case 'Liku_control_Info':
            await LikuWebPostStore.setBluetoothDeviceInfo(data.data);
            await LikuUiStore.updataControlData();
        break;
        case 'WiFi_getList':
            LikuWebPostStore.setWifiList(data.data);
            break;
        case 'WiFi_setWifi':
            LikuWebPostStore.setWifiInfo(data.data);
            BluetoothStore.setWiFi(data.data);
            break;
        case 'Liku_Restart':
            LikuUiStore.restartLiku();
            break;
        case 'PermissionConfig':
            console.log('PermissionConfig');
            break;
        case 'InternetConrim':
            console.log('InternetConrim');
            await LikuWebPostStore.setInternet(data.data);
            break;
        default:
            break;
    }
};