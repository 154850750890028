import { observable } from "mobx";

const DrawerStore = observable({
  drawerVisible: true,
  likuDeleteVisible: false,

  setAni(a) {
    this.drawerVisible = a;
  },

  setLikuDeleteVisble() {
    this.likuDeleteVisible = !this.likuDeleteVisible;
    console.log('likuDeleteVisible', this.likuDeleteVisible);
  }


});

export {DrawerStore};