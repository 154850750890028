import React from 'react'
import { ModalWrapper, SubmitButtons } from '../../style/modal/connect/LikuConnectModalPresenter'
import { ButtoContent, ModalDelWrap, ModalTitle2, ModalTitleBoldText, ModalTitleText } from '../../style/modal/modal'

const DeleteModalPresenter = ({
  open,
  setOpen,
  item,
  setDeleteFunction,}) => {
    // console.log('item', JSON.stringify(item))
    // console.log('item', item)
    // console.log('item', item.person.text)
    // let text = JSON.stringify(item).;
  return (
		<ModalWrapper
		visible={open}
	>
    <ModalDelWrap>
      <ModalTitle2>
          <ModalTitleBoldText>
            {/* {item == null ? '' : item.person} */}
            {/* '{typeof item === 'object' ? item.item.text : item}' */}
          </ModalTitleBoldText>
        <ModalTitleText>삭제하시겠습니까?</ModalTitleText>
      </ModalTitle2>
      <ButtoContent>
        <SubmitButtons title='취소' onClick={() => setOpen('deleteModal')}>취소</SubmitButtons>
        <SubmitButtons title='삭제' onClick={() => setDeleteFunction()}>삭제</SubmitButtons>
      </ButtoContent>
    </ModalDelWrap>
  </ModalWrapper>
  )
}

export default DeleteModalPresenter
