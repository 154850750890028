class Liku {
  constructor(id, name, mode, wifi, battery) {
    this.id = id;
    this.name = name;
    this.mode = mode;
    this.wifi = wifi;
    this.battery = battery;
    this.reasoningOnOff = true;
  }

  setWifi(wifi) {
    // console.log('wifi : ', wifi);
    this.wifi = wifi;
  }

  setMode(mode) {
    // console.log('mode : ');
    this.mode = mode;
  }

  setReasoning() {
    // console.log('reasoningOnOff: ', this.reasoningOnOff);
    this.reasoningOnOff = !this.reasoningOnOff;
  }

  // toJson() {
  //   return {
  //     id: this.id,
  //     name: this.name,
  //     liku: this.liku,
  //     wifiSSID: this.wifi.name,
  //     wifiPasswd: this.wifi.passwd,
  //   };
  // }
}

export default Liku;
