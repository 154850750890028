import styled from "styled-components";
import { HiOutlineStatusOnline } from 'react-icons/hi'
import { IoWalk } from 'react-icons/io5'
import { RiEmotionLine } from 'react-icons/ri'
import { IoChevronUpOutline } from 'react-icons/io5'

export const MotionBoxWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 31%;
`;

export const MotionBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 2rem;
  justify-content: space-around;
  background-color: #ebebeb;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
  z-index: 10;
  border-radius: 3px;
`;

export const MotionTitleWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  /* background-color: darkgoldenrod; */
`;

export const IconWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  /* width: 46%; */
  justify-content: space-between;
  /* background-color: darkseagreen; */
`;

export const MotionValueBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100px;
  height: 32px;
  border-radius: 3px;
   margin-top: 5%;
   justify-content: center;
   align-items: center;
  background-color: #5d9df4;
  box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const MotionTitle = styled.div`
  font-size: 0.725rem;
  font-weight: 600;
  color: #4a4a4a;
  margin-left: 0.35rem;
`;

export const MotionValue = styled.div`
  font-size: 0.825rem;
  font-weight: 500;
  color: white;
`;

export const MotionListWrap = styled.ul`
  width: 100%;
  margin: 0;
  height: 74%;
  overflow: scroll;
  /* padding-top: 0.5rem; */ 
  padding: 0;
  list-style: none;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.16);
  z-index: 99999999;
`;

export const MotionListItem = styled.li`
  height: 25%;
  font-size: 0.7rem;
  padding: 0.3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: dimgray; */
  text-align: center; 
  background-color: ${props =>
		props.click ? '#5d9df4' : ''};
  color: ${props =>
		props.click ? 'white' : ''};
  border-radius: ${props =>
		props.click ? '0.25rem' : ''};
  font-weight: ${props =>
    props.click ? 'bold': ''};
    z-index: 99999;
`;

export const ListText = styled.div`
  font-size: 0.825rem;
  background-color: rebeccapurple;
`;

export const WalkIcon = styled(IoWalk)`
  font-size: 0.825rem;
  color: #4a4a4a;
  font-size: 1.25rem;
`;

export const EmotionIcon = styled(RiEmotionLine)`
  font-size: 0.825rem;
  color: #4a4a4a;
  font-size: 1.25rem;
`;

export const WaveIcon = styled(HiOutlineStatusOnline)`
  font-size: 0.825rem;
  color: #4a4a4a;
  font-size: 1.25rem;
`;

export const ChevronICon = styled(IoChevronUpOutline)`
  font-size: 0.825rem;
  color: #4a4a4a;
  font-size: 1.25rem;
`;

export const MotionListIcon = styled.img`
  /* width: 1.2rem; */
  margin-right: 0.25rem;
  height: 1rem;
  /* height: 10px; */
`;


