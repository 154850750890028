import React, {useCallback, useRef, useState} from 'react';

const useLongPress = (onLongPress1, onClick1, { delay = 500 } = {}) => {
// const useLongPress = ({ delay = 500 } = {}) => {
    const [longPressTriggered, setLongPressTriggered] = useState(false);
    const timeout = useRef();
    const target = useRef();

    const start = useCallback((event, onLongPress2) => {
        if(event.target) {
            event.target.addEventListener('touchend', preventDefault, {
                passive: false,
            });
            target.current = event.target;
        }
        timeout.current = setTimeout(() => {
            if (onLongPress1 !== undefined) onLongPress1();
            else onLongPress2();
            // onLongPress(event);
            setLongPressTriggered(true);
            console.log('longPressTriggered',longPressTriggered);
        }, delay);
    }, [delay]);

    const clear = useCallback((onClick2) => {
        console.log('clear', longPressTriggered);
        if (timeout.current) clearTimeout(timeout.current);
        if(!longPressTriggered) {
            console.log('longPressTriggered')
            if (onClick1 !== undefined) onClick1();
            else onClick2();
        }
        setLongPressTriggered(false);
        if (target.current) {
            target.current.removeEventListener('touchend', preventDefault);
        }
    }, [longPressTriggered]);

    const isTouchEvent = (event: TouchEvent) => 'touches' in event;

    const preventDefault = (event: TouchEvent) => {
        if (!isTouchEvent(event)) return;

        if (event.touches.length < 2 && event.preventDefault) {
            event.preventDefault();
        }
    };

    return {
        onTouchStart: (e: React.TouchEvent, onLongPress) => start(e, onLongPress),
        onTouchEnd: (onClick) => clear(onClick),
    };
}

export default useLongPress;
