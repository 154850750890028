import React, { useEffect } from 'react'
import ControlLikuSpeakContainer from '../container/control/ControlLikuSpeakContainer'
import HomeHeaderPresenter from '../presenter/HomeHeaderPresenter'
import { ContainerWrap, ContentWraps, LikuActionWrap, LikuMotionWrap, LikuSpeackWrap, MicWrap, MotionWrap, StateWrap } from '../style/ContainerStyled'
import { ControlHeaderWrap, HeaderWrap } from '../style/header/HeaderStyled'
import { EmotionIcon, WalkIcon, WaveIcon } from '../style/control/ControlLikuSpeakContainer'
import RootStore from '../../store'
import SpeakControlControl from '../presenter/control/SpeakControlControl'
import ActionScreenContainer from '../presenter/control/ActionScreenContainer'
import { observer } from 'mobx-react'
import WaitModalContainer from '../container/modal/WaitModalContainer'
import SuccessFailureModalPresenter from '../presenter/modal/SuccessFailureModalPresenter'
import ControlLikuStateContainer from '../container/control/ControlLikuStateContainer'
import DeleteModalPresenter from '../presenter/modal/DeleteModalPresenter';
import LikuControlContainer from '../container/control/LikuControlContainer'
import ControlActionContainer from '../container/control/ControlActionContainer'
import LikuSpeackContainer from '../container/control/LikuSpeackContainer'
import LikuControlHeaderPresenter from '../presenter/control/LikuControlHeaderPresenter'
import {Mqtt} from "../../store/domainstore/mqtt";
import MusicalModeModalContainer from "../container/modal/MusicalModeModalContainer";
import {LikuUiStore} from "../../store/uistore/LikuUiStore";

const LikuControlPage = observer((props) => {
  const { ControlUiStore, LikuUiStore, ModalUiStore, MusicalModeModalUiStore, SuccessFailureModalUiStore, DeleteModalUiStore } = RootStore();

  useEffect(async () => {
    await ControlUiStore.initRos();
    await LikuUiStore.controlUpdateLikuData();
    await MusicalModeModalUiStore.checkMode();
  //   if (LikuUiStore.thisLiku?.wifi?.state == 'SCANNING') {
  //     setTimeout( async () => {
  //       await props.history.push("/home");
  //     }, 5000);
  //   } else {
  //   }
  }, []);

  useEffect(() => {
    MusicalModeModalUiStore.checkMode();
  }, [LikuUiStore.thisLikuState]);


  useEffect(() => {
    console.log('useEffect thisLiku');
    if (LikuUiStore.thisLiku === null) Mqtt.mqttClose();
  }, [LikuUiStore.thisLiku]);

  useEffect( async () => {
    if (LikuUiStore.thisLiku?.wifi?.state == 'SCANNING' || LikuUiStore.thisLiku?.wifi?.state == undefined) {
      setTimeout( async () => {
        await props.history.push("/home");
      }, 5000);
    } else {
    }
  }, [LikuUiStore.thisLiku?.wifi?.state]);

  return (
    <>
      <ContainerWrap>
        <ControlHeaderWrap>
          <LikuControlHeaderPresenter history={props.history}  />
        </ControlHeaderWrap>
        <ContentWraps>
          <StateWrap>
            <LikuControlContainer />
          </StateWrap>
          <LikuSpeackWrap>
            <LikuSpeackContainer />
          </LikuSpeackWrap>
          <LikuActionWrap>
            <ControlActionContainer />
          </LikuActionWrap>
        </ContentWraps>
      </ContainerWrap>
      <SuccessFailureModalPresenter
          open={ModalUiStore.open.successFailureModal}
          setOpen={ModalUiStore.setOpen.bind(ModalUiStore)}
          result={SuccessFailureModalUiStore.result}
          modal={SuccessFailureModalUiStore.modal}
        />
      <WaitModalContainer />
      <DeleteModalPresenter
          open={ModalUiStore.open.deleteModal}
          setOpen={ModalUiStore.setOpen.bind(ModalUiStore)}
          item={DeleteModalUiStore.item}
          setDeleteFunction={DeleteModalUiStore.setDeleteFunction.bind(
            DeleteModalUiStore,
          )}
        />
      {MusicalModeModalUiStore.visible && <MusicalModeModalContainer isBack={true} history={props.history}/>}
    </>
  )
});

export default LikuControlPage
