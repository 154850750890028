import {observable} from "mobx"
import {LikuUiStore} from "../LikuUiStore";

const MusicalModeModalUiStore = observable({
    visible: false,

    setVisible(value) {
        this.visible = value;
    },

    checkMode() {
        console.log('(LikuUiStore.state === \'musical\'', LikuUiStore.state === 'musical');
        if (LikuUiStore.thisLikuState === 'musical') {
            this.setVisible(true);
        }
    }
});

export {MusicalModeModalUiStore};
