import styled from "styled-components";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import { defaultTheme } from "../Theme";

export const ModalWrap = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 260px;
  height: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;

  ${defaultTheme.device.tablet} {
      width: 64%;
      height: 13%;
    }
`;

export const ModalDelWrap = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 55%;
  height: 20%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* justify-content: center; */
  background-color: white;
`;

export const ModalContexts = styled.div`
  background: #ffffff;
  align-items: center;
  justify-content: space-between;
  width: 53%;
  border-radius: 5px;
  height: ${props => props.height}%;
  ${({paddingNum}) => {
    return paddingNum
      ? `padding-left: ${paddingNum}%; padding-right: ${paddingNum}%;`
      : null;
  }}
`;

export const ModalButton = styled.div`
  background: ${defaultTheme.colors.modalButton};
  border-radius: 5px;
  width: 100%;
  height: 20%;
  margin-top: -10%;
  align-items: center;
  justify-content: center;
`;

export const ModalButtonText = styled.div`
  font-size: ${defaultTheme.fonts.size.base}px;
  color: #ffffff;
`;


export const ModalTitleText = styled.div`
  font-size: 1rem;
  margin-top: 2%;

  ${defaultTheme.device.tablet} {
    font-size: 1.5rem;
  }
`;

export const ModalInfoText = styled.div`
  font-size: .7rem;

  ${defaultTheme.device.tablet} {
    font-size: 1rem;
  }
`;

export const ModalSuccessIcon = styled(FiCheckCircle)`
  color: ${defaultTheme.colors.activation};
  /* font-size: ${defaultTheme.fonts.size.sb}px; */
  margin-right: 2%;
  font-size: 2rem;

  ${defaultTheme.device.tablet} {
    font-size: 2.8rem;
  }
`;


export const ModalFailureIcon = styled(FiXCircle)`
  color: ${props =>
    props.result ? defaultTheme.colors.activation : defaultTheme.colors.error};
  font-size: 2rem;
  margin-right: 2%;
  
  ${defaultTheme.device.tablet} {
    font-size: 2.8rem;
  }
`;

export const ModalScreen = styled.div`
    position: absolute;
    width: 80%;
    height: 18%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
    display: flex;
		flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;

    ${defaultTheme.device.tablet} {
      width: 64%;
      height: 13%;
    }
`;

export const ModalContext = styled.div`
     /* position: absolute; */

		font-size: 1rem;
`;
export const WaitModalText = styled.h2`
  font-size: 1rem;

  ${defaultTheme.device.tablet} {
    font-size: 1.5rem;
  }
`;

export const AlertModalWrap = styled.div`
  display: flex;
  position: absolute;
  top: 82%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 220px;
  height: 50px;
  border-radius: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
		font-size: 1rem;
`;

export const ModalTitle2 = styled.div`
  /* justify-content: space-evenly; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70%;
  padding: 5% 5% 0 5%;
`;

export const ModalTitleBoldText = styled.div`
  font-weight: bold;
  text-decoration: underline;
`;

export const ButtoContent = styled.div`
  background-color: tomato;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 25%;
  width: 100%;
`;

