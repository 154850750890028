class Macro {
  constructor(idx, userIdx, macroName, text,) {
    this.idx = idx;
    this.userIdx = userIdx;
    this.macroName = macroName;
    // this.action = action;
    // this.display = display;
    this.text = text;
    // this.tone = tone;
  }

  setIdx(idx) {
    this.idx = idx;
  }

  setUserIdx(idx) {
    this.userIdx(idx);
  }

  setMacroName(macroName) {
    this.macroName = macroName;
  }

  setAction(action) {
    this.action = action;
  }

  setDisplay(display) {
    this.display = display;
  }

  setText(text) {
    this.text = text;
  }

  setTone(tone) {
    this.tone = tone;
  }
}

export default Macro;
