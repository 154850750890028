import styled from "styled-components";

export const MotionBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  width: 26%;
  height: 50%;
  margin-right: 0.5rem;
  background-color: #f4f2f1;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
`;

export const MotionBtnText = styled.div`
  font-size: 0.625rem;
`;