import { Input } from "antd";
import styled from "styled-components";
import { defaultTheme } from "../../Theme";
import { HiRefresh } from "react-icons/hi";
import { VscChromeClose } from "react-icons/vsc";
import { CgSearch } from "react-icons/cg";
import { FiCheckCircle } from 'react-icons/fi';
import { IoMdRefresh } from "react-icons/io";

export const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 999;
`;

export const SpinnerModalWrap = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* background-color: rgba(0, 0, 0, 0.35); */
  z-index: 999;
`;

export const LikuFindModalWrap = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 260px;
  height: 300px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 24;
  z-index: 999;

  ${defaultTheme.device.tablet} {
    width: 60%;
    height: 40%;
  }

`;

export const LikuFindSuccessWrap = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 260px;
  height: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* justify-content: space-between; */
  background-color: white;
`;


export const FindModalTitleWrap = styled.div`
  position: relative;
  height: 28%;
  width: 100%;
  display: flex;
  align-items:  center;
  flex-direction: column;
  justify-content: center;
  /* background-color: red; */
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.15);

  ${defaultTheme.device.tablet} {
    height: 25%;
  }

`;

export const FindLikuList = styled.div`
  height: 45%;
  /* padding: 2rem; */
  width: 100%;
  display: flex;
  align-items:  center;
  flex-direction: column;
  /* justify-content: center; */
  overflow-y:scroll;
`;

export const FindModalSubText = styled.div`
  font-size: 0.7rem;
  /* font-size: ${defaultTheme.fonts.size.minis}; */
  margin-left: 2%;

  ${defaultTheme.device.tablet} {
    font-size: 1rem;
  }
`;


export const FindModalTitle = styled.div`
  font-size: 1rem;
  margin-left: 2%;
  font-weight: 600;

  ${defaultTheme.device.tablet} {
    font-size: 1.5rem;
  }

`;


export const SubmitButton = styled.button`
  /* background-color: #4a4a4a; */
  color: white;
  border-width: 0;
  height: 12%;
  width: 100%;
  font-size: 0.8rem;
  font-weight: bold;
  
  ${defaultTheme.device.tablet} {
   font-size: 1.125rem;
  }
  background-color: ${props => 
    props.click ? '#5d9df4' : '#4a4a4a'
  }
`;

export const SubmitButtons = styled.button`
  /* background-color: #4a4a4a; */
  color: white;
  border-width: 0;
  height:100%;
  width: 100%;
  background-color: ${props=>
    props.title === '취소' ? 'gray' : '#4a4a4a'};
  /* background: #4a4a4a; */
  font-size: 0.8rem;
  font-weight: bold;
  ${defaultTheme.device.tablet} {
    font-size: 1.2rem;
  }
`;

export const SubmitSubButton = styled.div`
  font-size: 0.725rem;
  font-weight: bold;
  ${defaultTheme.device.tablet} {
    font-size: 1rem;
  }
`;

export const ButtonContent = styled.button`
  display: flex;
  justify-content: center;
  flex-direction: row;
  color: white;
  border-width: 0;
  height:100%;
  width: 50%;
  align-items: center;
  background-color: ${props => 
    props.click ? '#5d9df4' : '#4a4a4a'
  }
`;

export const ButtonRetry = styled.button`
  display: flex;
  justify-content: center;
  flex-direction: row;
  color: white;
  border-width: 0;
  height:100%;
  width: 50%;
  align-items: center;
  background-color: ${props =>
    props.title === '등록' ? "#5d9df4" : "#bcbbbb"}
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 12%;
`;

export const CheckText = styled.div`
  font-size: 1rem;
  margin-top: 2%;
`;

export const CheckSubText = styled.div`
  font-size: 0.5rem;
  margin-top: 1%;
`;

export const TitleWrap = styled.div`
  /* height: 100%; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* background-color: darkblue; */
  /* margin-top: 1rem; */
`;

export const LikuBluetoothList = styled.div`
  width: 100%;
  height: 50%;
`;

export const Close = styled(VscChromeClose)`
  margin-bottom: .125rem;
  font-size: .825rem;
  color: white;

  ${defaultTheme.device.tablet} {
   font-size: 1.425rem;
  }
`;

export const LikuItem = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  /* flex: 8; */
  margin: .5rem 0;
`;

export const LikuItemText = styled.div`
  font-size: 0.8rem;
  width: 60%;
  margin-left: 14%;
  text-align: left;
  
  ${defaultTheme.device.tablet} {
   font-size: 1.1rem;
  }
`;

export const LikuItmeCheck = styled(Input)`
  /* background-color: blue; */
  width: 30%;
`;

export const SpinModalContent = styled.div`
  position: absolute;
  top: 55%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
`;


export const CloseBtn = styled.div`
  position: absolute;
  /* top: .825rem; */
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1.3rem;
  height: 1.3rem;
  right: 1rem;
  /* transform: translate(-50%, -50%); */
  background: #4a4a4a;
  border-radius: 2rem;
  font-size: .5rem;
  color: white;
  z-index: 999;
 
  ${defaultTheme.device.tablet} {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
export const CloseBtnTop = styled.div`
  position: absolute;
  top: .625rem;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1.3rem;
  height: 1.3rem;
  right: 0.625rem;
  /* transform: translate(-50%, -50%); */
  background: #4a4a4a;
  border-radius: 2rem;
  font-size: .5rem;
  color: white;
  z-index: 999;
 
  ${defaultTheme.device.tablet} {
    width: 2.325rem;
    height: 2.325rem;
  }
`;

  export const RefreshButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34%;
    padding: .2rem 0;
    /* height: 50%; */
    font-size: .7rem;
    font-weight: bold;
    border-radius: 50px;
    margin-top: .7rem;
    /* background-color: #d1d1d1; */
    background-color: ${props =>
     props.on ? 'white': '#d1d1d1'};
    color: ${props =>
      props.on ? '#5e9bf5' : 'white'};
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.16);

    ${defaultTheme.device.tablet} {
    font-size: 1.125rem;
  }
  `;

  export const RefreshIcon = styled(HiRefresh)`
    font-size: .825rem;
    margin-left: .2rem;
    color: ${props =>
      props.on ? '#5e9bf5' : 'white'};

    ${defaultTheme.device.tablet} {
    font-size: 1.2rem;
  }

  `;

  export const SearchIcon = styled(CgSearch)`
    font-size: 1.2rem;
    
    ${defaultTheme.device.tablet} {
    font-size: 2rem;
  }
  `;

  export const CheckCircle = styled(FiCheckCircle)`
    margin-right: 0.2rem;
    
    ${defaultTheme.device.tablet} {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
  `;

  export const Refreshes = styled(IoMdRefresh)`
    margin-right: 0.2rem;
    
    ${defaultTheme.device.tablet} {
    font-size: 1.4rem;
    margin-right: 0.5rem;
  }
  `;

  export const BackWrap = styled.div`
    position: fixed;
    bottom: 0;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 7%;
    right: 0;
    /* right: 1rem; */
    /* transform: translate(-50%, -50%); */
    background: #929292;
    font-size: .825rem;
    /* font-weight: bold; */
    color: #d0d0d0;
    z-index: 999;
    ${defaultTheme.device.tablet} {
      font-size: 1.325rem;
    }
  `;
