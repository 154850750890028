import styled from "styled-components";
import { defaultTheme } from "../Theme";

export const LikuRestartModalWrap = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 65%;
  height: 24%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* justify-content: center; */
  background-color: white;

  ${defaultTheme.device.tablet} {
      width: 64%;
      height: 13%;
    }
`;


export const ButtonContent = styled.div`
  /* background-color: tomato; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 25%;
  width: 100%;
`;

export const SubmitButtons = styled.button`
  /* background-color: #4a4a4a; */
  color: white;
  border-width: 0;
  height:100%;
  width: 100%;
  background-color: ${props=>
    props.title === '취소' ? 'gray' : '#4a4a4a'};
  /* background: #4a4a4a; */
  font-size: 0.8rem;
  font-weight: bold;
  ${defaultTheme.device.tablet} {
    font-size: 1.2rem;
  }
`;