import React from 'react'
import { HeaderDrawer, HeaderDrawerButton, HeaderDrawerButtonHomeImage, HeaderDrawerTitleBox, HomeBtnContent, LikuControlWrap, LikuStateContainer, LikuStateControlWrap, LikuStateIconWrap, RowWrap } from '../../style/header/HeaderStyled'
import home from '../../../resource/img/home.png';
import liku_sleep from '../../../resource/img/liku_sleep.png';
import liku_wakeup from '../../../resource/img/liku_wakeup.png';
import zzz from '../../../resource/img/zzz.png';
import { AlarmIcon, LikuStateBtn, LikuVolumeWrap } from '../../style/control/LikuControlContaier';
import { LikuMotionImg, LikuSleepImg } from '../../style/control/ControlLikuStateContainer';
import RootStore from '../../../store';
import { observer } from 'mobx-react';

import { Mqtt } from '../../../store/domainstore/mqtt'
import HomeVolumePresenter from '../home/HomeVolumePresenter';

const LikuControlHeaderPresenter = observer(({history}) => {
  const { LikuUiStore, ControlUiStore } = RootStore();

  return (
    <>
      <HeaderDrawer>
        <HeaderDrawerButton>
          <HomeBtnContent onClick={async () => {
            await history.push('/home');
            await LikuUiStore.updateLikuData();
            }}>
            <HeaderDrawerButtonHomeImage src={home} />
            홈으로
          </HomeBtnContent>
        </HeaderDrawerButton>
        <HeaderDrawerTitleBox>
          <LikuStateContainer>
            <LikuStateIconWrap>
              <LikuSleepImg
                style={{
                  marginBottom: LikuUiStore.thisLikuState === '자는중' ? '-.2rem' : '-.2rem',
                  marginTop: LikuUiStore.thisLikuState === '자는중' ? '0' : '.8rem',
                }}
                src={LikuUiStore.thisLikuState === '자는중' ? liku_sleep : liku_wakeup} />
              <LikuStateBtn
                style={{
                  backgroundColor: LikuUiStore.thisLikuState === '일어남' ? '#ffdc4a' : 'white'
                }} title='state'>{LikuUiStore.thisLikuState}</LikuStateBtn>
            </LikuStateIconWrap>
            <LikuControlWrap>
              <LikuStateControlWrap>
                <LikuStateBtn
                  onClick={() => {
                    ControlUiStore.controlFunction(
                      {
                        type: 'likuState',
                        json: ControlUiStore.likuStateJson.sleep.json,
                      },
                      '자는중',
                    )
                  }}>
                  <LikuMotionImg src={zzz} />
                  <div style={{marginLeft: '.3rem'}}>리쿠 재우기</div>
                </LikuStateBtn>
                <LikuStateBtn
                  onClick={() => {
                    if (LikuUiStore.thisLikuState !== '일어남') {
                      Mqtt.setLikuWakeupTopic();
                      ControlUiStore.likuStateChange(
                        ControlUiStore.likuStateJson.wakeUp.json,
                        '일어남',
                      )
                    } else {
                      
                    }
                  }}>
                  <AlarmIcon />
                  <div style={{marginLeft: '.3rem'}}>리쿠 깨우기</div>
                </LikuStateBtn>
              </LikuStateControlWrap>
              <LikuVolumeWrap>
                <HomeVolumePresenter />
              </LikuVolumeWrap>
            </LikuControlWrap>
          </LikuStateContainer>
        </HeaderDrawerTitleBox>
      </HeaderDrawer> 
    </>
  )
});

export default LikuControlHeaderPresenter
