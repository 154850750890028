import React, { useState } from "react";
import {
  LikuReactionWrap,
  LikuSpeakWrap,
  ReactionContainer,
  TitleText,
} from "../../style/control/ControlActionContainer";
import { ControllActionDisplayViews } from "../../style/control/ControlLikuStateContainer";
import ControlReactionContainer from "./ControlReactionContainer";
import ControlSpeakingContainer from "./ControlSpeakingContainer";

const ControlActionContainer = () => {
  const [speakingText, setSpeakingText] = useState("");
  const [speakingIndex, setSpeakingIndex] = useState(null);

  return (
    <>
      <ReactionContainer>
        <LikuSpeakWrap>
          <TitleText>등록 된 말하기</TitleText>
          <ControlSpeakingContainer
            speakingText={speakingText}
            setSpeakingText={setSpeakingText}
            speakingIndex={speakingIndex}
            setSpeakingIndex={setSpeakingIndex}
          />
        </LikuSpeakWrap>
        <LikuReactionWrap>
          <TitleText>리쿠 사용하기</TitleText>
          <ControllActionDisplayViews>
            <ControlReactionContainer />
          </ControllActionDisplayViews>
        </LikuReactionWrap>
      </ReactionContainer>
    </>
  );
};

export default ControlActionContainer;
