import styled from "styled-components";
import { VscChevronDown, VscChevronUp } from 'react-icons/vsc'

export const ChevronUp = styled(VscChevronUp)`
  font-size: 1.25rem;
  margin-right: 20%;
`;

export const ChevronDown = styled(VscChevronDown)`
  font-size: 1.25rem;
  margin-right: 20%;
`;

export const ChangePosition = styled.div`
  bottom: -22%;
`;

export const LikiMotionWrap = styled.div`
  display: flex;
  width: 100%;
  z-index: 999;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  position: fixed;
  padding: 1rem 0;
`;

export const LikuMitonContent = styled.div`
  display: flex;
  width:100%;
  height: 38vh;
  align-items: flex-start;
  bottom: ${props=>
    props.bottom ? 0: '-160px'};
  position: absolute;
  background-color: white;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 1rem;
  /* background-color: #fefeff; */
`;

export const LikuMitonContents = styled.div`
  display: flex;
  width: 94%;
  /* height: 38vh; */
  align-items: flex-start;
  position: absolute;
  flex-direction: column;
  /* background: pink; */
`;

export const LikuIconContent = styled.div`
  display: flex;
  width: 68%;
  height: 100%;
  justify-content: space-between;
  /* margin-top: 1rem; */
  /* background-color: #fefeff; */
  border-top-right-radius: 4px;
  box-shadow: 2px 2px 5px 3px rgba(0,0,0,0.2);
`;

export const LikuIconContents = styled.div`
  display: flex;
  width: 68%;
  justify-content: space-between;
  /* margin-top: 1rem; */
  /* background-color: #fefeff; */
  border-top-right-radius: 4px;
  box-shadow: 2px 2px 5px 3px rgba(0,0,0,0.2);
`;

export const LikuSleepImg = styled.img`
  width: 70%;
  margin: 0.3rem 0;
`;

export const LikuMotionBox = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: start;
  flex-direction: row;
  width: 70%;
  height: 100%;
`;

export const LikuEmptyContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 32%;
  height: 54%;
  margin-top: 10%;
  align-items: center;
  justify-content: flex-end;
  /* background-color: #fefeff; */
  box-shadow: 10px 2px 5px 3px rgba(0,0,0,0.2);
`;

export const LikuEmptyContentOpen = styled.div`
  display: flex;
  flex-direction: row;
  width: 32%;
  height: 80%;
  margin-top: 10%;;
  align-items: center;
  justify-content: flex-end;
  /* background-color: #fefeff; */
  box-shadow: 10px 2px 5px 3px rgba(0,0,0,0.2);
`;

export const LikuEmptyContentOpenTop = styled.div`
  display: flex;
  flex-direction: row;
  width: 28%;
  height: 55%;
  margin-top: 10%;;
  align-items: center;
  justify-content: flex-end;
  background-color: #fefeff;
  box-shadow: 10px 2px 5px 3px rgba(0,0,0,0.2);
`;

export const LikuMitionIconBtnWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 66%;
  align-items: center;
  justify-content: flex-start;
  background-color: #fefeff;
  overflow: hidden;
`;

export const LikuIconImgWrap = styled.div`
  display: flex;
  width: 30%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LikuSleepText = styled.div`
  font-size: 0.6rem;
  margin-top: 1%;
  font-weight: 600;
`;

export const LikuMotionVolume = styled.div`
  display: flex;
  width: 21%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: #fefeff; */
`;

export const LikuMotionIconBtnContent = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  overflow-x: scroll;
`;

export const LikuMotionBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44%;
  margin-bottom: 5%;
  height: 66%;
  border-radius: 3px;
  flex-direction: column;
  /* margin-left: 1%; */
  margin-right: 6%;
  background-color: #f4f2f1;
  box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.16);
`;

export const LikuMotionImg = styled.img`
  width: 18%;
  /* height: 32%; */
`;

export const LikuMotionText = styled.div`
  font-size: 0.625rem;
  margin-top: 4%;
  font-weight: 600;
  color: #4a4a4a;
`;

export const LikuMotionBtnsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26.5%;
	margin-right: 2.5%;
  height: 40%;
  border-radius: 3px;
  flex-direction: column;
  background-color: #f4f2f1;
`;


export const LikuMotionSubText = styled.div`
  font-size: 0.625rem;
  margin-top: 4%;
  font-weight: 600;
  color: #4a4a4a;
`;

export const LikuMotionVolumeContent = styled.div`
  width: 100%;
  height: 68%;
  background-color: #fefeff;
  display: flex;
  flex-direction: row;
`;

export const LikuStateWrap = styled.div`
  display: flex;
  flex-direction:row;
  width: 100%;  
  height: 40%;
`;

export const LikuVolumeColumn = styled.div`
  width: 20%;
  height: 100%;
  /* background-color: darksalmon; */
`;

export const ControllActionDisplayView = styled.div`
  width: 76%;
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-around;
  align-items: */
`;

export const ControllActionDisplayViews = styled.div`
  width: 100%;
  height: 85%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ::-webkit-scrollbar {
    width: .4rem;
    background-color: rgba(244, 242, 241, 0.4);
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ededed;
    border-radius: 0.8rem;
  }
`;

