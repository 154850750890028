import {observable} from 'mobx';
import Liku from "../../data/model/Liku";
import WiFi from "../../data/model/WiFi";
import { requestPermission } from '../../service/RequestPermission';
import { removeCookie, setCookie } from '../../utils/cookie';
import { DrawerStore } from '../uistore/DrawerStore';
import { LikuUiStore } from '../uistore/LikuUiStore';
import { ModalUiStore } from '../uistore/modal/ModalUiStore';
import { SuccessFailureModalUiStore } from '../uistore/modal/SuccessFailureModalUiStore';
import { Mqtt } from './mqtt';
import LikuRepository from "../../data/repository/LikuRepository";

const LikuStore = observable({
  likus: [],

  setLikus(getLikus) {
    if (getLikus !== undefined && this.likus.length === 0) {
      getLikus.map(liku => {
        if (liku.wifi.id !== undefined) {
          console.log(liku.wifi.id);
        }
        this.addLiku(
          new Liku(
            liku.id,
            liku.name,
            liku.mode,
            new WiFi(
              liku.wifi.id,
              liku.wifi.signal,
              liku.wifi.ip,
              liku.wifi.key,
              liku.wifi.auth,
              liku.wifi.state,
            ),
            liku.reasoningOnOff,
          ),
        );
      });
    }
  },

  async removeLiku(liku) {
    console.log('LikuStore removeLiku(liku)', liku);
    let index = this.findLiku(liku);
    this.likus.splice(index, 1);
    removeCookie('likuData');
    this.saveLiku();
    await DrawerStore.setAni(false);
    setTimeout(() => {
       ModalUiStore.setOpen('waitModal');
    }, 2000);
    await DrawerStore.setLikuDeleteVisble();
  },

  addLiku(liku) {
    this.likus.push(liku);
    this.likus = this.likus.slice().reverse();
    console.log('liku', liku);
    console.log('liku.name', liku.name);
    console.log('liku.name', this.likus);
  },

  saveLiku() {
    //  if (LikuUiStore.thisLiku?.wifi?.state == 'SCANNING') {
    //   console.log('&&&&&&&&&&&');
    //   setTimeout(async() => {
    //     await SuccessFailureModalUiStore.setResult(false);
    //     await SuccessFailureModalUiStore.setModalType('wifiConnect');
    //     await ModalUiStore.setOpen('successFailureModal');
    //   }, 2000);
    // }
    console.log('saveLiku',this.likus);
    console.log('saveLiku.length',this.likus.length);
    if (this.likus.length !== 0) {
      let likuData = JSON.stringify(this.likus);
      console.log('likuData', likuData);
      setCookie('likuData', likuData, {path: '/', maxAge: 72000000});
    } else {
      setTimeout(() => {
        DrawerStore.setAni(false);
      }, 1000)
    }
  },

  findLiku(find) {
    console.log('find', find);
    if (find !== null) {
      return this.likus.findIndex(e => {
        if (e.id !== null && e.id === find.id) {
          console.log('find true');
          return true;
        }
      });
    } else {
      console.log('findLiku null');
    }
  },

  async updateLiku(liku, data, wifiData) {
    let index = this.findLiku(liku);
    console.log('updateLiku index', index);
    if (index !== -1) {
      await this.setLikuWiFi(index, data.wifi);
      await this.setLikuBattery(index, data.battery);
      // console.log('password', wifiData);
      wifiData !== null &&
        wifiData !== undefined &&
        this.setWifiData(index, wifiData);
      return this.likus[index];
    }
  },

  setLikuWiFi(index, wifi) {
    this.likus[index].setWifi(wifi);
  },

  setLikuBattery(index, battery) {
    this.likus[index].battery = battery;
    // return this.likus[index];
  },

  setWifiData(index, wifiData) {
    this.likus[index].wifi.setPassword(wifiData.password);
    this.likus[index].wifi.setID(wifiData.ssid);
    this.likus[index].wifi.setKey(wifiData.key);
    this.likus[index].wifi.setAuth(wifiData.auth);
  },


  async updateMqttData() {
    // await Mqtt.getUserListTopic();
    await Mqtt.getVolumeTopic();
  },

  async checkVersion() {
    const serial = LikuUiStore.thisLiku?.name.replace('LIKU: ', '');
    const data = await LikuRepository.getLikuInfo({serial: serial});

    const newData = data.version;
    newData.serialNum = serial;

    const formData = new FormData();

    for (const key of Object.keys(newData)) {
      formData.append(`${key}`, newData[key]);
    }

    const version = await LikuRepository.getLikuVersion(formData);
    return version;
  }


});

export {LikuStore};
