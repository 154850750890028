import { observable, runInAction } from "mobx";
import { UserStore } from "../domainstore/UserStore";

const UserUiStore = observable({
  thisUser: null,

  async initUser() {
    await UserStore.initUsers();
    let defaultUser = await UserStore.findDefaultUser();
    runInAction(() => {
      this.thisUser = defaultUser;
    });
  },

  async setThisUser(user) {
    if (this.thisUser !== null && this.thisUser === user) {
      let defaultUser = await UserStore.findDefaultUser();
      runInAction(() => {
        this.thisUser = defaultUser;
      });
    } else {
      this.thisUser = user;
    }
    // console.log('tisUser', this.thisUser);
  },
});

export {UserUiStore};
