import styled from "styled-components";
import { defaultTheme } from "../Theme";
// import mic from "../../../resource/img/mic.png"
// import mic_on from "../../../resource/img/mic-on.png"
import { IoPencilOutline } from "react-icons/io5";
import EditBtn from '../../../resource/img/userBtnLong.png'
import speakBtn from '../../../resource/img/speakBtn.png';
import { FaTrash } from "react-icons/fa";
import { AiOutlineMenu, AiOutlineSave } from 'react-icons/ai';

export const MicContent = styled.div`
  position: relative;
  display: flex;
  width:100%;
  height: 20%;
  padding-left: 4%;
  align-items: center;
  /* background-color: darkgreen; */
  background-color: #ebebeb;
`;

export const SpeakControlWrap = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  height: 84%;
  border-radius: 3px;
  background-color: white;
  /* padding-bottom: 0.5rem; */
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
`;

export const MicText = styled.div`
  font-size: ${defaultTheme.fonts.size.semini};
  color: #97989a;
  margin-left: 5%;
`;

export const SpeakText = styled.div`
  font-size: ${defaultTheme.fonts.size.mini};
  color: #939393;
  margin: 2% 4%;
  font-weight: 700;
`;

export const SpeakContent = styled.div`
  width: 84%;
  height: 88%;
  margin-bottom: 0.5rem;
  margin-left: 8%;
  overflow-y: scroll;
  overflow-x: hidden;
  /* background-color: turquoise; */
`;

export const PlusWrap = styled.div`
  position: absolute;
  /* top: 0; */
  border-radius: 5px;
  right: 0;
  width: 90px;
  height: 80px;
  background-color: white;
  display: flex;
  justify-content: center;
  /* align-items: center; */
`;

export const SpeakBox = styled.div`
  position: relative;
  display: flex;
  width: 99%;
  height: 30%;
  align-items: center;
  box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  justify-content: space-between;
  background-color: #ededed;
  margin-bottom: 2.2%;
  margin-top: 1%;
`;

export const SpeakControlBox = styled.div`
  display: flex;
  width: 90%;
  height: 100%;
  /* box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1); */
  background-color: white;
  border-radius: 3px;
  flex-direction: column;
`;

export const SpeakingTTSScrollView = styled.div`
  /* background-color: yellowgreen; */
  width: 85%;
  /* display: flex; */
  /* align-items: center; */
  height: 2.25rem;
  padding-top: .325rem;
  line-height: .725rem;
  overflow: scroll;
`;

export const SpeakingTTSTextInput = styled.input`
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  font-size: 0.625rem;
  border: 0.5px solid #4a4a4a;
  padding: 0 3%;
  overflow-y: scroll;
  outline: none;
  background-color: white;
`;

export const SpeaksubText = styled.div`
  font-size: 0.625rem;
  margin-left: 4%;
  overflow: scroll;
  ${defaultTheme.device.tablet} {
    font-size: .825rem;
  }
`;

export const MotionWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 55%;
  align-items: center;
  margin-left: 2%;
`;

export const SpeakBtnImg = styled.img`
  width: 18%;
  height: 68px;
  top: -6px;
  right: -10px;
  position: absolute;
  
  /* z-index: 999;/ */
`;

export const MicInput = styled.input`
  border: none;
  background-color: inherit;
  text-decoration: none;
  outline: none;
  padding: 0 2%;
  font-size: 0.725rem;
  ::placeholder {
    font-size: 0.8rem;
  }
`;

export const MicButton = styled.img`
  width: 1.425rem;
  margin-right: .25rem;
`;

export const PencilOutline = styled(IoPencilOutline)`
  /* position: absolute; */
  right: 0.5rem;
  z-index: 99;
  color: white;
  top: 20%;
  margin: 0.25rem 0;
  font-size:1.25rem;
`;
export const PencilOutlines = styled(IoPencilOutline)`
  position: absolute;
  right: 2%;
  z-index: 99;
  color: white;
  top: 18%;
  margin: 0.25rem 0;
  font-size:1.25rem;
  ${defaultTheme.device.tablet} {
    font-size: 1.825rem;
    right: 4%;
    top: 20%;
  }
`;

export const TrashIcon = styled(FaTrash)`
  z-index: 99;
  color: white;
  font-size:1.2rem;
  margin: 0.25rem 0;
`;

export const MenuIcon = styled(AiOutlineMenu)`
  font-size: 0.825rem;
  margin-left: .5rem;

`;

export const EidtWrap = styled.div`
  background: url(${speakBtn});
  background-size: cover;
  position: absolute;
  width: 16%;
  right: -0.6rem;
  top: -0.4rem;
  height: 112%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const EidtWrapSave = styled.div`
  background: url(${speakBtn});
  background-size: cover;
  position: absolute;
  width: 16%;
  right: -0.6rem;
  top: -0.4rem;
  height: 112%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const EditingWrap = styled.div`
  position: absolute;
  width: 54%;
  height: 135%;
  right: -0.1rem;
  margin-top: 0.2rem;
  background: url(${EditBtn});
  background-size: cover;
  display: flex;
  flex-direction: row;
  z-index: 9999;
  justify-content: flex-end;
`;
export const EditContent = styled.div`
  display: flex;
  flex-direction: column;
  /* position: absolute; */
  align-items: center;
  color: white;
  width: 40%;
  margin-top: 0.655rem;
  font-size: 0.625rem;
`;
export const EditBg = styled.img`
  width: 130%;
  height: 155%;
  top: -0.7rem;
  right: -0.42rem;
  position: absolute;
`;
export const OutlineSave = styled(AiOutlineSave)`
  color: white;
  margin-top: 0.25rem;
  font-size: 1.2rem;
  ${defaultTheme.device.tablet} {
    font-size: 1.8rem;
  }
`;
export const OutlinePencil = styled(IoPencilOutline)`
  color: white;
  margin-top: 0.25rem;
  font-size: 1.2rem;
`;