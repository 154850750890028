import styled from "styled-components";
import { defaultTheme } from "../Theme";

export const DrawerWrap = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: ${props =>
		props.this ? '-100%' : 0};
	transition:  all 0.5s;
	z-index: 100;
`;

export const DrawerContent = styled.div`
	width: 80%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 100;
	box-shadow: 1px 1px 40px 10px rgba(0, 0, 0, 0.5);
`;

export const DrawerBox = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
	overflow-y: hidden;
`;

export const LikuNumBox = styled.div`
	display: flex;
	width: 98%;
	margin-left: 1%;
	height: 5.7rem;
	margin-top: 5.5%;
	margin-bottom: 5.5%;
	justify-content: center;
  background: ${props =>
		props.this ?defaultTheme.colors.activation : '#ffffff'};
	box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.2);
	flex-direction: column;
	
	${defaultTheme.device.tablet} {
    height: 11rem;
  }
`;

export const LikuNumWrap = styled.div`
	display: flex;
	height: 68%;
	width: 100%;
  background-color: #feffff;
	flex-direction: column;
	align-items: center;
`;

export const DrawerHeader = styled.div`
	width: 100%;
	height: 16%;
	display: flex;
	position: relative;
	align-items: center;
	padding: 0 7%;
	background-color: #929292;
	/* background-color: red; */
	z-index: 9999;
`;

export const DrawerHeaderTitle = styled.div`
	/* font-size: ${defaultTheme.fonts.size.base}; */
	font-size: 1.25rem;
	font-weight: 500;
	margin-left: 4%;
	color: white;

  ${defaultTheme.device.tablet} {
   	font-size: 2rem;
  }
`;

export const DrawerCloseBtn = styled.div`
	position: absolute;
	display: flex;
	width: 18%;
	height: 42%;
	align-items: center;
	flex-direction: column;
	/* background-color: red; */
	justify-content: center;
	border-radius: 2rem;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
	background-color: #929292;
	color: #feffff;
	opacity: 0.9;
	right: 1rem;
	/* margin-left: 2rem; */
	font-size: .625rem;

	${defaultTheme.device.tablet} {
		width: 15%;
		height: 40%;
		right: 1.5rem;
		font-size: 1.125rem;
  }
`;

export const LikuNumTitle = styled.div`
	font-size: ${defaultTheme.fonts.size.semini}px;
	margin-left: 4%;
	display: flex;
	margin-bottom: 2%;
	font-weight: 500;
	color: ${props =>
		props.this ? '#ffffff' : '#4a4a4a'};
	/* color: #4a4a4a; */
	display: flex;

	${defaultTheme.device.tablet} {
    font-size: 1.6rem;
		margin-bottom: 0;
  }
`;

export const LikuNumText = styled.div`
	font-size: 0.7rem;
	margin-left: 4%;
	display: flex;
	color: ${props =>
		props.this ? '#ffffff' : '#4a4a4a'};
	display: flex;

	${defaultTheme.device.tablet} {
    font-size: 1.2rem;
  }
`;

export const DrawerFooterText = styled.div`
	font-size: 0.6rem;
	margin-left: 4%;
	display: flex;
	color: white;
	opacity: 0.5;
	display: flex;

	${defaultTheme.device.tablet} {
    font-size: 1rem;
  }
`;


export const HeaderDrawerImage = styled.img`
  width: 22%;
  /* height: 40%; */
`;

export const DrawerFooter = styled.div`
	width: 100%;
	height: 16%;
	display: flex;
	/* align-items: center; */
	text-align: left;
	justify-content: center;
	flex-direction:column;
	background-color: #929292;
`;

export const LikuAddBox = styled.div`
	display: flex;
	width: 90%;
	/* height: 90px; */
	height: 20%;
	margin-bottom: 4%;
	align-items: center;
	justify-content: center;
	background-color: #ebebeb;
	box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.2);
	flex-direction: column;
	/* cursor: pointer;	 */
	:hover {opacity:0.8}
`;

export const FlatListWrap = styled.div`
	width: 90%;
	height: 80%;
	/* margin-bottom: 4%; */
	/* display: flex; */
	/* background-color: goldenrod; */
	justify-content: center;
	flex-direction: column;
	align-items: center;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	::-webkit-scrollbar {
		width: 25px;
	}
	::-webkit-scrollbar-track {
		background-color: darkgrey;
	}
	::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}
`;
