import { observer } from 'mobx-react'
import React from 'react'
import RootStore from '../../../store';
import { InputStateBox, ReactionButtonInfo, ReactionButtonTitle, ReactionView, StartBox, StartText } from '../../style/control/ActionScreenContainer'
import { StopButton, StopButtonText } from '../../style/header/HeaderStyled';
import { IoIosSquare } from "react-icons/io";
import { defaultTheme } from '../../style/Theme';

const ActionScreenContainer = observer(() => {
  const {ControlUiStore} = RootStore();
  return (
    <>
      <InputStateBox>
      {/* {   <StopButton onClick={() => ControlUiStore.controlFunction({type: 'stop'})}>
                <IoIosSquare style={{color: 'white'}} size={defaultTheme.fonts.size.sm} />
                <StopButtonText>STOP</StopButtonText>
          </StopButton>
          } */}
        {ControlUiStore.speakingStart.data !== null && 
          (ControlUiStore.speakingStart.type !== 'reaction' ? (
              <StartText>{ControlUiStore.speakingStart.data}</StartText>
            ) : (
              <StartBox>
                <StartText>실행</StartText>
                <ReactionView>
                  <ReactionButtonTitle>
                    {
                      ControlUiStore.reactionList[ 
                        ControlUiStore.speakingStart.data
                      ].name
                    }
                  </ReactionButtonTitle>
                  <ReactionButtonInfo>
                    {
                      ControlUiStore.reactionList[
                        ControlUiStore.speakingStart.data
                      ].info
                    }
                  </ReactionButtonInfo>
                </ReactionView>
              </StartBox>
            ))}
      </InputStateBox>
    </>
  )
});

export default ActionScreenContainer
