import React, { useEffect } from 'react'
import ControlLikuSpeakContainer from '../container/control/ControlLikuSpeakContainer'
import HomeHeaderPresenter from '../presenter/HomeHeaderPresenter'
import { ContainerWrap, ContentWraps, LikuMotionWrap, MicWrap, MotionWrap } from '../style/ContainerStyled'
import { HeaderWrap } from '../style/header/HeaderStyled'
import { EmotionIcon, WalkIcon, WaveIcon } from '../style/control/ControlLikuSpeakContainer'
import RootStore from '../../store'
import SpeakControlControl from '../presenter/control/SpeakControlControl'
import ActionScreenContainer from '../presenter/control/ActionScreenContainer'
import { observer } from 'mobx-react'
import WaitModalContainer from '../container/modal/WaitModalContainer'
import SuccessFailureModalPresenter from '../presenter/modal/SuccessFailureModalPresenter'
import ControlLikuStateContainer from '../container/control/ControlLikuStateContainer'
import DeleteModalPresenter from '../presenter/modal/DeleteModalPresenter';


const RemotePage = observer(({history, props}) => {
  const { ControlUiStore, SpeakingUiStore, ModalUiStore, SuccessFailureModalUiStore, DeleteModalUiStore } = RootStore();
  
  useEffect(async() => {
    ControlUiStore.initRos();
  }, [])

  return (
    <>
      <ContainerWrap>
      <HeaderWrap>
          <HomeHeaderPresenter history={history} title='원격 시스템' />
        </HeaderWrap>
        <ContentWraps>
          <MotionWrap>
            <ControlLikuSpeakContainer 
              type='액션' 
              icon={<WalkIcon />} 
              data={ControlUiStore.actionList}
              value={SpeakingUiStore.thisAction}
              choseFunction={SpeakingUiStore.setThisAction.bind(SpeakingUiStore)} />
            <ControlLikuSpeakContainer 
              type='표정' 
              icon={<EmotionIcon />} 
              data={ControlUiStore.displayList}
              value={SpeakingUiStore.thisDisplay}
              choseFunction={SpeakingUiStore.setThisDisplay.bind(SpeakingUiStore)} />
            <ControlLikuSpeakContainer 
              type='말투' 
              icon={<WaveIcon />} 
              data={ControlUiStore.toneList} 
              value={SpeakingUiStore.thisTone}
              choseFunction={SpeakingUiStore.setThisTone.bind(SpeakingUiStore)} />
            <ActionScreenContainer />
          </MotionWrap>
          <MicWrap>
            <SpeakControlControl />
          </MicWrap>
          <LikuMotionWrap>
            <ControlLikuStateContainer />
          </LikuMotionWrap>
        </ContentWraps>
      </ContainerWrap>
      <SuccessFailureModalPresenter
          open={ModalUiStore.open.successFailureModal}
          setOpen={ModalUiStore.setOpen.bind(ModalUiStore)}
          result={SuccessFailureModalUiStore.result}
          modal={SuccessFailureModalUiStore.modal}
        />
      <WaitModalContainer />
      <DeleteModalPresenter
          open={ModalUiStore.open.deleteModal}
          setOpen={ModalUiStore.setOpen.bind(ModalUiStore)}
          item={DeleteModalUiStore.item}
          setDeleteFunction={DeleteModalUiStore.setDeleteFunction.bind(
            DeleteModalUiStore,
          )}
        />
    </>
  )
});

export default RemotePage
