import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react'
import { BsFillPlusCircleFill } from "react-icons/bs";
import RootStore from '../../../store';
import mic from "../../../resource/img/mic.png"
import mic_on from "../../../resource/img/mic-on.png"
import { MicButton, MicContent, MicInput, PlusWrap, SpeakControlWrap, SpeakText } from '../../style/control/SpeakControlPresenter'
import { defaultTheme } from '../../style/Theme';
import ControlRightSpeakingListContainer from '../../container/control/ControlRightSpeakingListContainer';

const SpeakControlControl = observer(() => {
  const { SpeakingUiStore, ControlUiStore } = RootStore();
  const [speakingText, setSpeakingText] = useState('');
  const [speakingIndex, setSpeakingIndex] = useState(null);
  const TextInputRef = useRef();
  const onChange = (e) => {
    SpeakingUiStore.setThisTextInput(e.target.value);
  }; 

  return (
    <> 
      <MicContent>
        <MicButton src={SpeakingUiStore.mic === false ?  mic : mic_on} onClick={() => {ControlUiStore.controlFunction({type: 'stt'})}} />
        <form onSubmit={(e) => {
          e.preventDefault();
          ControlUiStore.controlFunction({type: 'tts'}).then(r =>
            TextInputRef.current.focus());
        }}>
          <MicInput 
            placeholder='내용을 입력해주세요.' 
            ref={TextInputRef}
            value={SpeakingUiStore.thisTextInput}
            maxLength={90}
            onChange={onChange}
          />
        </form>
      </MicContent>
      <SpeakControlWrap>
        <SpeakText>등록 말하기</SpeakText>
        <ControlRightSpeakingListContainer 
          speakingText={speakingText}
          setSpeakingText={setSpeakingText}
          speakingIndex={speakingIndex}
          setSpeakingIndex={setSpeakingIndex}
        />
      </SpeakControlWrap>
      <PlusWrap onClick={async() => {
        await ControlUiStore.addSpeakingList();
        }}>
        <BsFillPlusCircleFill color='#4a4a4a' style={{marginTop: '8%'}} size={defaultTheme.fonts.size.sb} />
      </PlusWrap>
    </>
  )
});

export default SpeakControlControl
