import { observer } from "mobx-react";
import React, { useState } from "react";
import RootStore from "../../../store";
import {
  EditContent,
  EditingWrap,
  EidtWrap,
  MenuIcon,
  MotionWrap,
  OutlinePencil,
  OutlineSave,
  PencilOutline,
  SpeakBox,
  SpeakBtnImg,
  SpeakContent,
  SpeakControlBox,
  SpeakingTTSScrollView,
  SpeakingTTSTextInput,
  SpeaksubText,
  TrashIcon,
} from "../../style/control/SpeakControlPresenter";
import MotionBtnPrensenter from "../../presenter/control/MotionBtnPrensenter";

const ControlRightSpeakingListContainer = observer((props) => {
  const { ControlUiStore, DeleteModalUiStore, SpeakingUiStore } = RootStore();
  const [visible, setVisible] = useState(false);
  const [save, setSave] = useState(false);
  const visibleTrue = () => setVisible(true);
  const visibleFalse = () => setVisible(false);
  const saveTrue = () => setSave(true);
  const saveFalse = () => setSave(false);

  const onChange = (e) => {
    props.setSpeakingText(e.target.value);
  };

  return (
    <SpeakContent>
      {SpeakingUiStore.speakingListUniversal.map((person, index) => (
        <SpeakBox key={index}>
          <MenuIcon />
          <SpeakControlBox
            onClick={() => {
              save === false &&
                ControlUiStore.controlFunction({
                  type: "speaking",
                  json: person,
                });
            }}
          >
            <SpeakingTTSScrollView>
              {(save === false || props.speakingIndex !== index) && (
                <SpeaksubText>{person.text}</SpeaksubText>
              )}
              {save === true && props.speakingIndex === index && (
                <SpeakingTTSTextInput
                  value={props.speakingText}
                  onChange={onChange}
                  maxLength={90}
                />
              )}
            </SpeakingTTSScrollView>
            <MotionWrap>
              {console.log(
                "ControlUiStore.actionList[person.action]",
                ControlUiStore.actionList[person.action].name
              )}
              <MotionBtnPrensenter
                title={ControlUiStore.actionList[person.action].name}
              />
              <MotionBtnPrensenter
                title={ControlUiStore.displayList[person.display].name}
              />
              <MotionBtnPrensenter
                title={ControlUiStore.toneList[person.tone].name}
              />
            </MotionWrap>
          </SpeakControlBox>
          {visible === false || props.speakingIndex !== index ? (
            <EidtWrap
              onClick={() => {
                props.setSpeakingText(person.text);
                props.setSpeakingIndex(index);
                visibleTrue();
              }}
            >
              <OutlinePencil />
              <SpeakBtnImg />
            </EidtWrap>
          ) : (
            props.speakingIndex === index && (
              <EditingWrap>
                <EditContent
                  onClick={() => {
                    visibleFalse();
                    saveTrue();
                  }}
                >
                  <PencilOutline />
                  수정
                </EditContent>
                <EditContent
                  onClick={() => {
                    DeleteModalUiStore.setDelete({ person, index }, "control");
                    visibleFalse();
                  }}
                >
                  <TrashIcon />
                  삭제
                </EditContent>
              </EditingWrap>
            )
          )}
          {save === true && props.speakingIndex === index && (
            <EidtWrap
              onClick={() => {
                saveFalse();
                visibleFalse();
                ControlUiStore.updateSpeakingList(
                  props.speakingIndex,
                  props.speakingText
                ).then((r) => {
                  props.setSpeakingText("");
                  props.setSpeakingIndex(null);
                });
              }}
            >
              <OutlineSave />
            </EidtWrap>
          )}
        </SpeakBox>
      ))}
    </SpeakContent>
  );
});

export default ControlRightSpeakingListContainer;
