import { VscChromeClose, VscCircleFilled } from "react-icons/vsc";
import styled from "styled-components";
import { ButtonBox, ViewBox } from "../Box/Box";
import { defaultTheme } from "../Theme";

export const WiFiLangBox = styled(ViewBox)`
  width: 100%;
  max-height: 90%;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  /* justify-content: space-around; */
  /* background-color: red; */

`;

export const CloseBtnWrap = styled.div`
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
`;

export const CloseBtnContent = styled.div`
  width: 65%;
  height: 75%;
  display: flex;
  flex-direction: column;
  border-radius: .8rem;
  background-color: #929292;
  font-family: HelveticaNeue;
  font-size: .625rem;
  color: #fefeff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Close = styled(VscChromeClose)`
  /* margin-bottom: .125rem; */
  font-size: .825rem;
  color: white;

  ${defaultTheme.device.tablet} {
   font-size: 1.425rem;
  }
`;

export const WifiContentWrap = styled.div`
  width: 90%;
  min-height: 33%;
  max-height: 40%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px dashed #afaeae;
`;

export const WifiControlContent = styled.div`
  width: 55%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  /* background-color: hotpink; */
`;

export const WiFiControlImage = styled.img`
  /* width: 15%; */
  height: 60%;
  /* margin-top: 15%; */
  /* margin-left: 4%; */
`;

export const WifiContronDataWrap = styled.div`
  /* width: 15%; */
  /* background-color: blue; */
  height: 100%;
  width: 74%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const WiFiControlData = styled.div`
  font-size: .8rem;
  font-weight: bold;
  /* margin-top: 1rem; */
	width: 100%;
  height: 40%;

  ${defaultTheme.device.tablet} {
    font-size: 1.325rem;
  }
`;

export const WiFiControlDataInfo = styled.div`
  /* position: absolute;
  right: 13%; */
  /* width: 100%; */
  /* background: red; */
  /* text-align: right;  */
  font-size: .525rem;
  /* -webkit-transform: scale(0.8);
  -webkit-text-size-adjust: none; */
  /* padding-left: 55%; */
  ${defaultTheme.device.tablet} {
    font-size: 1rem;
  }
`;

export const WifiDataInfoView = styled.div`
  /* position: relative; */
  display:flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  width:100%;
  height: 30%;
  /* margin-top: 2%; */
  /* margin-bottom: 2%; */
  /* margin-right: 1%; */
  /* background-color: rebeccapurple; */
`;



export const VscCircleIcon = styled(VscCircleFilled)`
  color: ${props =>
    props.$connect
      ? defaultTheme.colors.activation
      : defaultTheme.colors.default};
  font-size: 1rem;
`;

export const WiFiControlButton = styled(ButtonBox)`
  width: 70%;
  height: 60%;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 8%; */
  display:flex;
  background: ${defaultTheme.colors.gray};
  text-align: center;
  border-radius: 1rem;
  /* box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1); */

  ${defaultTheme.device.tablet} {
    width: 80%;
    height: 16%;
  }
`;

export const WiFiControlButtonText = styled.div`
  font-size: 0.6rem;
  color: #4a4a4a;
  padding: 4% 0;
  ${defaultTheme.device.tablet} {
    font-size: 1rem;
  }
`;

export const WifiBtnWrap = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: skyblue; */
`;

export const WifiOffText = styled.div`
  font-family: HelveticaNeue;
  font-size: .725rem;
  font-weight: bold;
  color: #939393;
  margin-top:.825rem;
`;