import styled from "styled-components";
import { defaultTheme } from "../Theme";
import { AiOutlinePlus } from 'react-icons/ai'

export const HeaderDrawer = styled.div`
  align-items: center;
  /* justify-content: flex-start;    */
  height: 100%;
  display:flex;
  background-color: #929292;
  flex-direction: row;
`;

export const HeaderWrap = styled.div`
  display: 1;
  /* box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); */
  width: 100%;
  height: 15%;
  text-align: center;
`;

export const ControlHeaderWrap = styled.div`
  display: 1;
  /* box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); */
  width: 100%;
  height: 15%;
  text-align: center;
`;

export const HeaderDrawerButton = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  background-color: ${defaultTheme.colors.darkGray};
  align-items: center;
  justify-content: center;
  z-index: 100;
  box-shadow: 5px -8px 10px 2px rgba(0, 0, 0, 0.2);
`;

export const HeaderDrawerTitleBox = styled.div`
  width: 75%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  display: flex;
  text-align: left;
  padding-left: 4%;
  background-color: #fefeff;
  z-index: 99;
  box-shadow: 2px 0px 2px 1px rgba(0, 0, 0, 0.1);
`;

export const HeaderDrawerButtonHomeImage = styled.img`
  margin-top: .4rem;
  width: 40%;
  ${defaultTheme.device.tablet} {
    margin-bottom: .5rem;
  }
`;

export const HeaderDrawerButtonImage = styled.img`
  width: ${props => props.$type === 'home' ? '40%' : '55%'};
`;

export const HomeBtnContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.625rem;
  ${defaultTheme.device.tablet} {
    font-size: 1rem;
    /* margin-top: 1rem; */
  }
`;

export const HeaderDrawerControllImage = styled.img`
  width: 24%;
  margin-right: 0.5rem;
  /* height: 45%; */
`;

export const HeaderDrawerTitle = styled.div`
  color: ${defaultTheme.colors.default};
  font-size: 1rem;
  /* font-size: ${props =>
		props.this === '원격 시스템' ? '1.15rem' : '1.1rem'}; */
  /* font-size: ${defaultTheme.fonts.size.base}px; */
  font-weight: bold;
  margin-top: ${props =>
		props.this === '원격 시스템' ? '' : '0.4rem'};
  
  ${defaultTheme.device.tablet} {
    font-size: 1.5rem;
  }
`;

export const HeaderDrawerText = styled.div`
  color: ${defaultTheme.colors.default};
  font-size: 0.8rem;
  margin-top: -1%;

  ${defaultTheme.device.tablet} {
    font-size: 1.125rem;
  }
`;

export const DrawerText = styled.div`
  color: ${defaultTheme.colors.default};
  font-size: 0.9rem;
  font-weight: bold;
  margin-top: 2%;

  ${defaultTheme.device.tablet} {
    font-size: 1.25rem;
    margin-top: 1%;
  }
`;

export const PlusIcons = styled(AiOutlinePlus)`
  font-size: 1.2rem;
  ${defaultTheme.device.tablet} {
    font-size: 2.8rem;
  }

`;

export const DrawerSubText = styled.div`
  color: ${defaultTheme.colors.default};
  font-size: 0.625rem;
  /* font-weight: bold; */
  margin-top: 1%;

  ${defaultTheme.device.tablet} {
    font-size: 1rem;
    margin-top: 0.5%;
  }
`;

export const RowWrap = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: row;
`;

export const StopButton = styled.div`
  position: absolute;
  top:-1.7rem;
  right: -.6rem;
  /* background-color: red; */
  background-color: #929292;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26%;
  height: 50%;
  margin-left: 0.5rem;
  border-radius: 1rem 0 0 1rem;
  z-index: 100;

`;


export const StopButtonText = styled.div`
  color: white;
  font-weight: bold;
`;


export const LikuStateContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LikuControlWrap = styled.div`
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: skyblue; */
`;

export const LikuStateIconWrap = styled.div`
  width: 20%;
  height: 90%;
  display: flex;
  flex-direction: column;
  /* background-color: darkseagreen; */
  align-items: center;
  justify-content: space-around;
`;

export const LikuStateControlWrap = styled.div`
  width: 96%;
  height: 60%;
  display: flex;
  flex-direction: row;
  /* background-color: pink; */
  align-items: center;
  justify-content: space-around;
`;


export const HeaderIconWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HeaderSub = styled.div`
  font-size: .525rem;
  font-weight: bold;
  color: #feffff;
  margin-top: .25rem;
`;