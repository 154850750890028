import styled from "styled-components";
import { ButtonBox, ViewBox } from "../Box/Box";
import { defaultTheme } from "../Theme";
import { RiWifiFill } from "react-icons/ri";
import { VscCircleFilled } from "react-icons/vsc";

export const WiFiControlBox = styled(ViewBox)`
  // width: 90%;
  // height: 33%;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-around;
  // border-bottom: ${props => props.$type == 'once' ? '' : '1px dashed #afaeae;'}

  width: 47%;
  height: 47%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  justify-content: space-around;

`;


export const WiFiControlImage = styled.img`
  width: 50%;
  height: 35%;
  margin-top: 15%;
  /* margin-left: 4%; */
`;
export const GameControlImage = styled.img`
  width: 70%;
  height:35%;
  margin-top: 15%;
`;

export const WiFiControlData = styled.div`
  font-size: ${defaultTheme.fonts.size.base}px;
  font-weight: bold;
	height: 20px;
  /* padding: 2% 0; */
  //background-color: teal;
  ${defaultTheme.device.tablet} {
    font-size: 1.325rem;
  }
`;

export const RiWifiFillICon = styled(RiWifiFill)`
  font-size: ${defaultTheme.fonts.size.big};
  margin-top: 8%;
  :hover {
    color: #5d9df4;
  }
`;

export const VscCircleIcon = styled(VscCircleFilled)`
  color: ${props =>
    props.$connect
      ? defaultTheme.colors.activation
      : defaultTheme.colors.default};
  font-size: 1.25rem;
  /* margin-right: -7%; */
`;

export const WiFiControlButton = styled(ButtonBox)`
  width: 70%;
  height: 18%;
  align-items: center;
  justify-content: center;
  margin-bottom: 8%;
  display:flex;
  background: ${defaultTheme.colors.gray};
  text-align: center;

  ${defaultTheme.device.tablet} {
    width: 80%;
    height: 16%;
  }
`;

export const WiFiControlButtonText = styled.div`
  font-size: 0.625rem;
  /* font-weight: 600; */
  padding: 4% 0;
  ${defaultTheme.device.tablet} {
    font-size: 1rem;
  }
`;

export const Empty = styled.div`
  width: 50px;
  height: 13%;
  /* background-color: rebeccapurple; */
`;
export const WifiDataInfoView = styled.div`
  position: relative;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 12%;
  margin-top: 2%;
  /* margin-bottom: 2%; */
  /* margin-right: 1%; */
  /* background-color: rebeccapurple; */
`;
export const WiFiControlDataInfo = styled.div`
  position: absolute;
  right: 13%;
  /* width: 100%; */
  /* background: red; */
  /* text-align: right;  */
  font-size: 0.5rem;
  -webkit-transform: scale(0.8);
  -webkit-text-size-adjust: none;
  /* padding-left: 55%; */
  ${defaultTheme.device.tablet} {
    font-size: 1rem;
  }
`;
