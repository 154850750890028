import styled from "styled-components";
import { defaultTheme } from "../Theme";

export const ModalTitleWrap = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  height: 50%;
  width: 78%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  ${defaultTheme.device.tablet} {
    height: 34%;
    width: 65%;
  }
`;

export const ModalTitle = styled.div`
  font-size: .9rem;
	color: #4a4a4a;
  font-weight: normal;
  margin-left: 0.5rem;

  ${defaultTheme.device.tablet} {
    font-size: 1.25rem;
  }
`;

export const LikuStateContent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 66%;
`;

export const LikuStateMemo = styled.textarea`
	width: 88%;
	height: 48%;
	background-attachment: local;
  background-image:
    linear-gradient(to right, white 10px, transparent 10px),
    linear-gradient(to left, white 10px, transparent 10px),
    repeating-linear-gradient(white, white 30px, #ccc 30px, #ccc 31px, white 31px);
  line-height: 1.8rem;
  padding: 8px 10px;
	border: none;
	resize: none;
	outline: none;
	::placeholder {
		font-size: 0.6rem;
	}
`;

export const UserWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 0.825rem;
`;

export const UseName = styled.input`
  width: 58%;
  border: none;
  font-size: 0.8rem;
  outline: none;
  border-bottom: 1px solid rgba(100,100,100,0.5);
  ::placeholder {
    font-size: 0.825rem;
  }
  ${defaultTheme.device.tablet} {
    font-size: 1.2rem;
  }
`;

export const UserTitle = styled.div`
  margin: 0 0.8rem;
  ${defaultTheme.device.tablet} {
    font-size: 1.25rem;
  }
`;

export const UserSub = styled.div`
  margin-right: 0.8rem;
`;

export const UserDiv = styled.div`
  width: 70%;
  height: 60%;
  margin-top: 1.5rem;
  position: relative;
`;

export const UserPic = styled.img`
  width: 100%;
  height: 100%;
`;

export const UserBox = styled.canvas`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const UserImg = styled.img`
  /* width: 90%; */
  height: 52%;
  margin-top: 1.5rem;
  ${defaultTheme.device.tablet} {
    height: 65%;
  }
`;

export const LikuUserContent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 66%;
  margin-top: 1.25rem;
  /* background: pink; */
`;