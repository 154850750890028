import { LikuUiStore } from "../uistore/LikuUiStore";

export async function stopFunction() {
  let URL = `http://${LikuUiStore.thisLiku.wifi.ip}:8888/ros/stop`;
  return await fetch(URL, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Connection: 'close',
      type: 'getUserData',
    },
    Connection: 'close',
    type: 'getUserData',
    body: JSON.stringify({action: 'stop'}),
  })
    .then(result => {
      // console.log('URL:', URL);
      // console.log('result:', result);
    })
    .catch(error => {
      // console.log('stopFunction : ', error);
    });
}

export async function expressionFunction(action) {
  console.log('expresstionFunction\n', action);
  let URL = `http://${LikuUiStore.thisLiku.wifi.ip}:8888/ros/exp`;
  return await fetch(URL, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Connection: 'close',
      type: 'getUserData',
    },
    Connection: 'close',
    type: 'getUserData',
    body: JSON.stringify(action),
  })
    .then(result => {
      // console.log('URL:', URL);
      // console.log('result:', result);
    })
    .catch(error => {
      // console.log('expresstionFunction : ', error);
    });
}

export async function actionFunction(action) {
  // console.log('actionFunction\n', action);
  let URL = `http://${LikuUiStore.thisLiku.wifi.ip}:8888/ros/todo`;
  return await fetch(URL, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Connection: 'close',
      type: 'getUserData',
    },
    Connection: 'close',
    type: 'getUserData',
    body: JSON.stringify(action),
  })
    .then(result => {
      // console.log('URL:', URL);
      // console.log('result:', result);
    })
    .catch(error => {
      // console.log('actionFunction : ', error);
    });
}

export async function modeSwitchFunction(switchOnOff) {
  let URL = `http://${LikuUiStore.thisLiku.wifi.ip}:8888/ros/intention`;
  return await fetch(URL, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Connection: 'close',
      type: 'getUserData',
    },
    Connection: 'close',
    type: 'getUserData',
    body: JSON.stringify(switchOnOff),
  })
    .then(result => {
      // console.log('URL:', URL);
      // console.log('result:', result);
    })
    .catch(error => {
      // console.log('actionFunction : ', error);
    });
}

export async function likuStateFunction() {
  let URL = `http://${LikuUiStore.thisLiku.wifi.ip}:8888/ros/motion_status/get`;
  return await fetch(URL, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Connection: 'close',
      type: 'getUserData',
    },
    Connection: 'close',
    type: 'getUserData',
    body: JSON.stringify({message: 'data'}),
  })
    .then(result => {
      // console.log('URL:', URL);
      // console.log('result:', result);
    })
    .catch(error => {
      // console.log('actionFunction : ', error);
    });
}
export async function getVolume() {
  let URL = `http://${LikuUiStore.thisLiku.wifi.ip}:8888/ros/volume_ctrl/get`;
  let volume = await fetch(URL, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Connection: 'close',
      type: 'getUserData',
    },
    Connection: 'close',
    type: 'getUserData',
    body: JSON.stringify({volume: 'get'}),
  })
    .then(response => response.text())
    .then(data => {
      // console.log(data);
      // console.log('data', Number(data));
      return Number(data);
    })
    .catch(error => {
      // console.log('getVolume : ', error);
    });

  return volume;
}

export async function setVolume() {
  let index = LikuUiStore.volumeAppList.indexOf(LikuUiStore.volume);
  console.log('setVolume:', LikuUiStore.volumeAppList[index]);
  let URL = `http://${LikuUiStore.thisLiku.wifi.ip}:8888/ros/volume_ctrl/set`;
  await fetch(URL, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Connection: 'close',
      type: 'getUserData',
    },
    Connection: 'close',
    type: 'getUserData',
    body: JSON.stringify({
      volume: LikuUiStore.volumeControlList[index].toString(),
    }),
  })
    .then(result => {
      // console.log('URL: ', URL);
      // console.log('result:', result);
    })
    .catch(error => {
      // console.log('setVolume : ', error);
    });
}
