import { observable } from "mobx";

const VisualizationUiStore = observable({
  rgbData: null,
  // cameraImg = createRef(),
  // cameraRef = createRef(),
  // ctx = null,
  drawingBoxFunction: null,

  setDrawingBoxFunction(f) {
    this.drawingBoxFunction = f;
  },

  setRgbData(data) {
    this.rgbData = data;
  },

  drawingBox2(data) {
    console.log('drawingBox2');
    this.drawingBoxFunction(data);
    console.log('drawingBox21');
  },

  // cameraConstructor() {
  //   this.ctx = this.cameraRef.current.getContext("2d");
  //   this.cameraRef.current.width = this.cameraImg.current.width;
  //   this.cameraRef.current.height = this.cameraImg.current.height;
  //   // this.ctx.strokeStyle = 'rgb(250,255,0)';
  //   this.ctx.font = 'bold 12px italic';
  //   this.ctx.fillStyle = 'rgb(255,255,255)';
  //   this.ctx.lineWidth = 3;
  // },

  // drawingBox() {
  //   this.ctx.clearRect(0, 0, this.cameraRef.current.width, this.cameraRef.current.height);
  //   try {
  //       for (let person of this.connectLiku.topicData.surroundings) {
  //           // this.ctx.strokeRect(270 * person.bbox.x1, 100 * person.bbox.y1, 90, 90);
  //           let emotion = person.emotion.split('.');

  //           try {
  //               if(this.cameraRef.current.width * person.bbox.x1 != null && this.cameraRef.current.width * person.bbox.x2 != null) {
  //                   if(person.PO === true) this.ctx.strokeStyle = 'rgb(255,0,0)';
  //                   else this.ctx.strokeStyle = 'rgb(250,255,0)';
  //                   this.ctx.strokeRect(
  //                       this.cameraRef.current.width * person.bbox.x1,
  //                       this.cameraRef.current.height * person.bbox.y1,
  //                       Math.abs(person.bbox.x2 - person.bbox.x1) * this.cameraRef.current.width,
  //                       Math.abs(person.bbox.y2 - person.bbox.y1) * this.cameraRef.current.height
  //                   );
  //               }
  //           } catch (error) {
  //               console.log('this.personb.bbox.x1 x2 Null!!!');
  //           }

  //           if(person.name !== null) {
  //               this.ctx.fillText(
  //                   `이름 : ${person.name}`,
  //                   this.cameraRef.current.width * person.bbox.x2+5,
  //                   this.cameraRef.current.height * person.bbox.y1+12
  //               );
  //               this.ctx.fillText(
  //                   `감정: ${emotion[1]}`,
  //                   this.cameraRef.current.width * person.bbox.x2+5,
  //                   this.cameraRef.current.height * person.bbox.y1+24
  //               );
  //           } else {
  //               this.ctx.fillText(
  //                   `감정: ${emotion[1]}`,
  //                   this.cameraRef.current.width * person.bbox.x2+5,
  //                   this.cameraRef.current.height * person.bbox.y1+12
  //               );
  //           }
  //       }
  //   } catch (error) {
  //       console.log('drawingBox error');
  //       this.ctx.clearRect(0, 0, this.cameraRef.current.width, this.cameraRef.current.height);
  //   }

  //   // box.rect(210 * 0.7220064401626587, 100 * 0.48045358061790466 , 100, 100);
  //   // this.connectLiku.surroundings.map
  // }
});

export {VisualizationUiStore};