import {observable} from 'mobx';
import { BluetoothStore } from '../../domainstore/BluetoothStore';
import { LikuWebPostStore } from '../LikuWebPostStore';
import { ModalUiStore } from './ModalUiStore';
import { SuccessFailureModalUiStore } from './SuccessFailureModalUiStore';

const LikuConnectDataStore = observable({
  
  async likuWifiConnectFunction(choseLiku) {
      if(LikuWebPostStore.LikuData !== false) {
        let res = await BluetoothStore.enrollmentLiku(LikuWebPostStore.choseLiku);
        // console.log('resres', res);
        // console.log('BluetoothStore.enrollmentLiku(choseLiku)', res);
        SuccessFailureModalUiStore.setResult(res);
      }
       else {
        SuccessFailureModalUiStore.setResult(LikuWebPostStore.LikuData);
      }
      ModalUiStore.setOpen('waitModal'); 
      ModalUiStore.setOpen('successFailureModal');
    // }
  },
});

export {LikuConnectDataStore};
