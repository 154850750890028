class Wifi { 
  constructor(id, signal, ip, key, auth, state) {
    this.id = id;
    this.signal = signal;
    this.password = null;
    this.ip = ip;
    this.key = key;
    this.auth = auth;
    this.state = state;
  }

  setPassword(password) {
    // console.log('setPassword', password);
    this.password = password;
  }

  setID(id) {
    this.id = id;
  }

  setKey(key) {
    this.key = key;
  }

  setAuth(auth) {
    this.auth = auth;
  }
}

export default Wifi;