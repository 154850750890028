import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import RootStore from "../../../store";
import {
  MenuIcon,
  OutlineSave,
  SpeakingTTSTextInput,
  SpeaksubText,
} from "../../style/control/SpeakControlPresenter";
import {
  EditContents,
  EditingWraps,
  EidtSaveWrap,
  EidtsWrap,
  Pencil,
  SpeakContentWrap,
  SpeakingBox,
  SpeakingControlBox,
  SpeakingTTSScrollViews,
  Trash,
} from "../../style/control/ControlSpeakingContainer";

const ControlSpeakingContainer = observer((props) => {
  const { ControlUiStore, DeleteModalUiStore, SpeakingUiStore } = RootStore();
  const [visible, setVisible] = useState(false);
  const [save, setSave] = useState(false);
  const visibleTrue = () => setVisible(true);
  const visibleFalse = () => setVisible(false);
  const saveTrue = () => setSave(true);
  const saveFalse = () => setSave(false);

  const onChange = (e) => {
    props.setSpeakingText(e.target.value);
  };

  return (
    <SpeakContentWrap>
      {SpeakingUiStore.speakingListUniversal.map((person, index) => (
        <SpeakingBox key={index}>
          <MenuIcon />
          <SpeakingControlBox
            onClick={() => {
              save === false &&
                ControlUiStore.controlFunction({
                  type: "speaking",
                  json: person,
                });
            }}
          >
            <SpeakingTTSScrollViews>
              {(save === false || props.speakingIndex !== index) && (
                <SpeaksubText>{person.text}</SpeaksubText>
              )}
              {save === true && props.speakingIndex === index && (
                <SpeakingTTSTextInput
                  // disableFullscreenUI={true}
                  value={props.speakingText}
                  onChange={onChange}
                  maxLength={90}
                />
              )}
            </SpeakingTTSScrollViews>
          </SpeakingControlBox>
          {(visible === false && save === false) ||
          props.speakingIndex !== index ? (
            <EidtsWrap
              onClick={() => {
                props.setSpeakingText(person.text);
                props.setSpeakingIndex(index);
                visibleTrue();
              }}
            ></EidtsWrap>
          ) : (
            visible === true &&
            props.speakingIndex === index && (
              <EditingWraps>
                {/* <CloseCircle /> */}
                <EditContents
                  onClick={() => {
                    visibleFalse();
                    saveTrue();
                  }}
                >
                  <Pencil />
                  수정
                </EditContents>
                <EditContents
                  onClick={() => {
                    DeleteModalUiStore.setDelete({ person, index }, "control");
                    visibleFalse();
                  }}
                >
                  <Trash />
                  삭제
                </EditContents>
              </EditingWraps>
            )
          )}
          {save === true && props.speakingIndex === index && (
            <EidtSaveWrap
              onClick={() => {
                saveFalse();
                visibleFalse();
                ControlUiStore.updateSpeakingList(
                  props.speakingIndex,
                  props.speakingText
                ).then((r) => {
                  props.setSpeakingText("");
                  props.setSpeakingIndex(null);
                });
              }}
            >
              <OutlineSave />
            </EidtSaveWrap>
          )}
        </SpeakingBox>
      ))}
    </SpeakContentWrap>
  );
});

export default ControlSpeakingContainer;
