import { observer } from 'mobx-react';
import React from 'react';
import { GrClose } from 'react-icons/gr';
import { IoExitOutline } from 'react-icons/io5';
import RootStore from '../../../../store';
import HomeLikuWiFiSettingContainer from '../../../container/modal/connect/HomeLikuWiFiSettingContainer';
import { BackWrap, Close, CloseBtn, CloseBtnTop, ModalWrapper } from '../../../style/modal/connect/LikuConnectModalPresenter';
import { ButtonWrap, LikuWifiConnectWrap, LikuWifiInputWrap, WifiButtonText } from '../../../style/modal/wifi/HomeLikuWifiModalPresenter';
import { defaultTheme } from '../../../style/Theme';
import { RiArrowGoBackFill } from "react-icons/ri";
import {WiFiSettingModalUiStore} from "../../../../store/uistore/modal/WiFiSettingModalUiStore";

const HomeLikuWiFiSettingModalPresenter = observer(({open, setOpen, resetWiFi, setWiFiFunction, IsDisabled}) => {
const { ModalUiStore, LikuStore, LikuWebPostStore, LikuUiStore } = RootStore();

  return (
    <ModalWrapper
      visible={open}
    >
     <LikuWifiConnectWrap sizeUp={WiFiSettingModalUiStore.choseWiFi.auth === '802.1x'}>
          <LikuWifiInputWrap>
            <HomeLikuWiFiSettingContainer />
            <CloseBtnTop  onClick={ async () => {
          await ModalUiStore.setOpen('wifiSettingModal')
          await LikuWebPostStore.resetWifiList();
          }} >
          <Close />
         </CloseBtnTop>
          </LikuWifiInputWrap>
          <ButtonWrap onClick={async () => {
            await setWiFiFunction();
            // await LikuStore.updateMqttData();
            await LikuWebPostStore.resetWifiList();
          }}>
            <WifiButtonText>WiFi-연결</WifiButtonText>
          </ButtonWrap>
        </LikuWifiConnectWrap>
         <BackWrap onClick={async() =>{
            await ModalUiStore.setOpen('wifiSettingModal');
            await ModalUiStore.setOpen('wifiListModal');
         }}>
           <RiArrowGoBackFill style={{marginRight: '.5rem'}} />
           이전으로
         </BackWrap>
    </ModalWrapper>
  );
});

export default HomeLikuWiFiSettingModalPresenter;
