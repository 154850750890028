import React from 'react'
import RootStore from '../../../../store';
import { Close, CloseBtn, ModalWrapper } from '../../../style/modal/connect/LikuConnectModalPresenter';
import { Bluetooth, BluetoothImg, ModalBlutootContent, ModalHeader, ModalHeaderWrap, ModalSub } from '../../../style/modal/HomeBluetoothModal';
import blueoothImg from '../../../../resource/img/bluetooth.png';
import { ModalSubText } from '../../../style/modal/wifi/HomeLikuWifiModalPresenter';
import { defaultTheme } from '../../../style/Theme';

const HomeBluetoothModal = ({open, setOpen}) => {
	const { ModalUiStore } = RootStore();

  return (
    <ModalWrapper
      visible={open}>
      <ModalHeaderWrap>
        <ModalHeader>
          <Bluetooth />
          리쿠와 블루투스 연결하셨나요?
          <CloseBtn onClick={() => {
            ModalUiStore.setOpen('bluetoothModal');
            }} >
            <Close />
          </CloseBtn>
        </ModalHeader>
        <ModalBlutootContent>
          <BluetoothImg src={blueoothImg} />
          <ModalSub>
            <ModalSubText>
            ※ 등록된 디바이스 목록에 연결하려는 리쿠가 있어야합니다.
            </ModalSubText>
            <ModalSubText>
            ※ ‘LIKU: ***’ 에서 ***은 리쿠의 시리얼 넘버입니다.
            </ModalSubText>
          </ModalSub>
        </ModalBlutootContent>
      </ModalHeaderWrap>
  </ModalWrapper>
	)
}

export default HomeBluetoothModal
