import React, {useEffect} from 'react'
import { Empty, GameControlImage, VscCircleIcon, WiFiControlBox, WiFiControlButton, WiFiControlButtonText, WiFiControlData, WiFiControlDataInfo, WiFiControlImage, WifiDataInfoView } from '../../style/home/HomeWiFiAndControllPresenter';
import wifiOn from '../../../resource/img/wifi.png';
import wifi_off from '../../../resource/img/wifi_off.png';
import controller from '../../../resource/img/controller.png';
import RootStore from '../../../store';
import { observer } from 'mobx-react';
import HomeLikuWifiModalPresenter from '../modal/connect/HomeLikuWiFiListModalPresenter';
import { requestPermission } from '../../../service/RequestPermission';
import SpinnerModal from '../modal/SpinnerModal';

const HomeWiFiAndControllPresenter = observer(({ button, data, icon, history, buttonFunction }) => {
  const { ModalUiStore } = RootStore();
  const handleWifi = async () => {
    await requestPermission({ type: "WiFi_getList" });
    await ModalUiStore.setOpen('wifiListModal');
    await ModalUiStore.setOpen('spinModal');
    setTimeout(() => {
      ModalUiStore.setOpen('spinModal');
    }, 3000);
  }

  useEffect(() => {
    // requestPermission({ type: "WiFi_getList" });
    console.log('data.state', data.state);
  }, [data]);

  return (
    <>
      <WiFiControlBox>
      {icon === 'wifi' ? (
        <WiFiControlImage
          src={button === 'WiFi 변경 >' &&
              data !== undefined &&
              (data.state === 'COMPLETED' || data.state === 'CONNECTED')
                ? wifiOn
                : wifi_off}/>
      ) : (
        <GameControlImage
          style={{resizeMode: 'contain'}}
          src={controller}
        />
      )}
        <WiFiControlData>
          {icon === 'wifi' ? (
						data !== undefined &&
            (data.state === 'COMPLETED' || data.state === 'CONNECTED') ? data.id : '연결되지 않았습니다') : data} 
        </WiFiControlData>
        {icon === 'wifi' ? 
          <WifiDataInfoView>
            <VscCircleIcon
              $connect={
                button === 'WiFi 변경 >' &&
                data !== undefined &&
                data !== 'undefined' &&
                (data.state === 'COMPLETED' || data.state === 'CONNECTED')
            }
              /> 
            <WiFiControlDataInfo>
              {data !== undefined &&
              data !== 'undefined' &&
              (data.state === 'COMPLETED' || data.state === 'CONNECTED')
                ? '연결되었습니다'
                : ''}
            </WiFiControlDataInfo>
          </WifiDataInfoView>
              : <Empty /> 
        }
        <WiFiControlButton 
          onClick={() => {
            icon === 'wifi' ? handleWifi() : history.push('/control')
          }}>
          <WiFiControlButtonText>{button}</WiFiControlButtonText>
        </WiFiControlButton>
      </WiFiControlBox>
      <HomeLikuWifiModalPresenter
          open={ModalUiStore.open.wifiListModal}
          setOpen={ModalUiStore.setOpen.bind(ModalUiStore)}
        />
      <SpinnerModal
          open={ModalUiStore.open.spinModal}
          setOpen={ModalUiStore.setOpen.bind(ModalUiStore)}
      />
    </>
  );
});

export default HomeWiFiAndControllPresenter
