import { observable } from "mobx";

const SuccessFailureModalUiStore =  observable({
  result: false,
  type: {
    enrollment: {
      success: '성공적으로 등록되었습니다.',
      failure: '등록이 실패하였습니다.',
      failureInfo: 'Bluetooth 연결 확인 후 다시 시도해주세요.',
    },
    internet: {
      success: '',
      failure: '연결이 실패하였습니다.',
      failureInfo: '핸드폰 인터넷 연결 확인 후 다시 시도해주세요.',
    },
    wifiConnect: {
      success: '성공적으로 연결되었습니다.',
      failure: '연결이 실패하였습니다.',
      failureInfo: 'Wi-Fi 상태를 확인 후 다시 시도해주세요',
    },
    BluetoothConnect: {
      success: '성공적으로 연결되었습니다.',
      failure: '연결이 실패하였습니다',
      failureInfo: 'Bluetooth 상태를 확인 후 다시 시도해주세요'
    },
    likuDisconnect: {
      failure: '연결이 끊겼습니다.',
      failureInfo: 'Bluetooth 상태를 확인 후 다시 시도해주세요.'
    },
    likuSleep: {
      failure: '리쿠가 잠들어 있어요.',
      failureInfo: '리쿠를 깨운 후 다시 시도해주세요.'
    },
    user: {
      success: '성공적으로 삭제되었습니다.',
    }
  },
  modal: {
    success: '성공적으로 등록되었습니다.',
    failure: '등록이 실패하였습니다.',
    failureInfo: 'Bluetooth 연결 확인 후 다시 시도해주세요.'
  },

  setResult(res) {
    this.result = res;
    // console.log('modal', res);
  },

  setModalType(type) {
    switch (type) {
      case 'enrollment':
        this.modal = this.type.enrollment;
        break;
      case 'internet':
        this.modal = this.type.internet ;
        break;
      case 'wifiConnect':
        this.modal = this.type.wifiConnect;
        break;
      case 'BluetoothConnect':
        this.modal = this.type.BluetoothConnect;
        break;
      case 'likuSleep':
        this.modal = this.type.likuSleep;
        break;
      case 'likuDisconnect':
        this.modal = this.type.likuDisconnect;
        break;
      case 'user':
        this.modal = this.type.user;
        break;
      default: 
        break;
    }
  },

});

export {SuccessFailureModalUiStore}