import { observable, runInAction } from "mobx"

const ModalUiStore = observable({
	open: {
		likuConnectModal: false,
		waitModal: false,
		successFailureModal: false,
    wifiListModal: false,
    alertModal:false,
    wifiSettingModal: false,
    spinModal: false,
    likuStateModal: false,
    userEnrollmentModal: false,
    userCheckModal: false,
    userRecognizeModal: false,
    deleteModal: false,
    deleteLikuModal: false,
    bluetoothModal: false,
    restartLikuModal: false,
	},
	isCountDown: false,

	setOpen(modal) {
		switch (modal) {
      case 'likuConnectModal':
        this.open.likuConnectModal = !this.open.likuConnectModal;
        break;
      case 'waitModal' :
        this.open.waitModal = !this.open.waitModal;
        break;
      case 'wifiListModal':
        this.open.wifiListModal = !this.open.wifiListModal;
        break;
      case 'wifiSettingModal':
        this.open.wifiSettingModal = !this.open.wifiSettingModal;
        break;
      case 'spinModal':
        this.open.spinModal = !this.open.spinModal;
        break;
      case 'likuStateModal':
        this.open.likuStateModal = !this.open.likuStateModal;
        break;
      case 'userEnrollmentModal':
        this.open.userEnrollmentModal = !this.open.userEnrollmentModal;
        break;
      case 'userCheckModal':
        this.open.userCheckModal = !this.open.userCheckModal;
        break;
      case 'userRecognizeModal':
        this.open.userRecognizeModal = !this.open.userRecognizeModal;
        break;
      case 'deleteModal':
        this.open.deleteModal = !this.open.deleteModal;
        break;
      case 'deleteLikuModal':
        this.open.deleteLikuModal = !this.open.deleteLikuModal;
        break;
      case 'bluetoothModal':
        this.open.bluetoothModal = !this.open.bluetoothModal;
        break;
      case 'restartLikuModal':
        this.open.restartLikuModal = !this.open.restartLikuModal;
        break;
      case 'alertModal':
        this.open.alertModal = !this.open.alertModal;
        setTimeout(() => {
					runInAction(() => {
						this.open.successFailureModal = !this.open.successFailureModal;
					})
				},1000);
        break;
			case 'successFailureModal' :
				this.open.successFailureModal = !this.open.successFailureModal;
				setTimeout(() => {
					runInAction(() => {
						this.open.successFailureModal = !this.open.successFailureModal;
					})
				}, 3000);
				break;
        default:
          break;
		}
		// console.log('open : ', this.open);
	},

  setIsCountDown() {
    this.isCountDown = !this.isCountDown;
    // console.log('this.isCountDown', this.isCountDown);
  },

  setVisibleFalse() {
    this.open = {
      likuConnectModal: false,
      wifiListModal: false,
      wifiSettingModal: false,
      waitModal: false,
      successFailureModal: false,
      controlConnectModal: false,
      deleteModal: false,
      userModal: false,
      spinModal: false,
      likuStateModal:false,
      userEnrollmentModal: false,
      userRecognizeModal: false,
      deleteLikuModal: false,
      bluetoothModal: false,
      restartLikuModal: false,
    };
  },

  async getModalVisibleTrue() {
    let kyes = Object.keys(this.open);
    for (let key of kyes) {
      if (this.open[key]) {
        return true;
      }
    }
    return false;
  },
});

export {ModalUiStore}
