import styled from "styled-components";
import { defaultTheme } from "../../Theme";
import { AiOutlineCaretDown } from "react-icons/ai";

export const SettingContext = styled.div`
  width: 100%;
  /* height: 85%; */
  padding: 0 12%;
  align-items: center;
  justify-content: center;
  margin: 0.45rem 0;
  /* background-color: darkgreen; */
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: #939393;
  margin-top: 2%;
`;

export const WifiNameButton = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 28%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  /* background-color: cadetblue; */
`;

export const WiFiNameText = styled.div`
  font-size: ${defaultTheme.fonts.size.base}px;
  margin-left: 0.625rem;
  text-align: left;
  line-height: 0.8rem;
  /* margin-top: 4%; */
`;

export const WiFiNameButtonIcon = styled(AiOutlineCaretDown)`
  font-size: ${defaultTheme.fonts.size.lg}px;
  color: ${defaultTheme.colors.default};
  margin-top: 4%;
`;

export const WiFiTextInput = styled.div`
  font-size: ${defaultTheme.fonts.size.base}px;
  width: 85%;
  height: 28%;
  border-bottom-width: 0.7px;
  border-color: #939393;
  padding: 1% 1% 0;
`;

export const PasswordSecretView = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  margin-top: 3%;
  align-items: center;
`;

export const PasswordSecretText = styled.div`
  font-size: ${defaultTheme.fonts.size.base}px;
`;

export const PasswordHelperIcon = styled.div`
  font-size: ${defaultTheme.fonts.size.mini}px;
`;

export const PasswordHelperText = styled.div`
  font-size: 0.7rem;
  color: red;
  margin-left: 0.25rem;
  ${defaultTheme.device.tablet} {
    font-size: 1rem;
  }
`;

export const PasswordHelperTextWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 2%;
  margin-bottom: -4%;
`;

export const ModalTitleWrap = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  height: 64%;
  width: 74%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

// export const ModalTitleWrap = styled.div`
//   height: 24%;
//   width: 100%;
//   display: flex;
//   align-items:  center;
//   flex-direction: column;
//   justify-content: center;
//   background-color: red;
//   box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
// `;

export const LikuWifiConnectWrap = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 260px;
  height: 200px;
  flex-direction: column;
  justify-content: space-between;
  background-color:white;
  box-shadow: 24;
 /* padding: 4px; */
`;

export const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 1rem;
`;

export const ModalTitle = styled.div`
  font-size: 1.2rem;
  margin-left: 2%;
  font-weight: 600;
`;

export const ModalSubText = styled.div`
  font-size: 0.725rem;
  margin-left: 2%;
  /* margin-top: 2%; */
`;

export const ConectedWrap = styled.div`
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #ffffff;

`;

export const ConectedContent = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  /* justify-content: center; */
  /* mar-left: 5%; */
  flex-direction: column;
  border-style: dashed;
  border-width: 0;
  border-bottom-width: 1px;
  overflow-y: scroll;
`;

export const NewContent = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  /* justify-content: center; */
  /* mar-left: 5%; */
  flex-direction: column;
`;

export const NewWiWrap = styled.div`
  height: 46%;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  overflow-y: auto;
`;

export const WifiTitle = styled.div`
  font-size: ${defaultTheme.fonts.size.semini}px;
  /* margin-left: 2%; */
  font-weight: 500;
  margin-top: 10%;
  margin-bottom: 4%;
`;

export const WifiList = styled.div`
  font-size: ${defaultTheme.fonts.size.mini}px;
  /* margin-left: 2%;
  margin-top: 2%; */
  width: 200px;
  height: 25px;
  margin: 0;
`;

export const LikuWifiInputWrap = styled.div`
  width: 100%;
  height: 80%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ButtonWrap = styled.button`
  width: 260px;
  height: 40px;
  border: none;
  color: white;
  background-color: #4a4a4a;
`;

export const WifiButtonText = styled.div`
  font-size: ${defaultTheme.fonts.size.semini};
  font-weight: bold;
`;

export const PasswordInput = styled.input`
  width: 80%;
  /* height: 1.25rem; */
  border: none;
  margin-left: 0.625rem;
  outline: none;
  font-size: ${defaultTheme.fonts.size.base}px;
`;

export const PasswordWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const PasswordIcon = styled.div`
  margin-top: 0.6rem;
  //visibility: hidden;
  visibility: ${props => !props.visible ? 'hidden' : 'visible'};
`;

export const PasswordConfirmWrap = styled.div`
  width: 200px;
  /* background-color: skyblue; */
`;

export const PasswordConfirmText = styled.div`
  font-size: ${defaultTheme.fonts.size.mini}px;
`;

export const TitleIconWrap = styled.div`
  width: 100%;
  height: 24%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
`;

export const WifiListWrap = styled.div`
  width: 100%;
  height: 76%;
  padding: 1.5rem 2.5rem;
`;

export const WifiListTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
`;

export const WifiName = styled.div`
  margin-top: 0.25rem;
  font-size: 0.8rem;
`;

export const WifiLists = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.65rem;
`;

export const WifiListDrop = styled.div`
  position: absolute;
  top: 37.5%;
  width: 76%;
  height: 110%;
  background-color: white;
  overflow-y: scroll;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;

`;

export const SelectText = styled.div`
  font-size: ${defaultTheme.fonts.size.mini}px;
  margin-left: 0.625rem;
  margin-top: 0.425rem;
`;

