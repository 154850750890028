import styled from "styled-components";
import { defaultTheme } from "./Theme";

export const ContainerWrap = styled.div`
  /* display: flex; */
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  flex-direction: column;
`;

export const ContentWrap = styled.div`
  display: flex;
  height: 88%;
  width: 100%;
  align-items: center;
  background-color: #ededed;
  flex-direction: column;
  box-shadow: 4px 4px 4px 2px rgba(0, 0, 0, 0.1) inset;
  overflow: scroll;
`;

export const ContentWraps = styled.div`
  display: flex;
  height: 85%;
  width: 100%;
  align-items: center;
  background-color: #ededed;
  flex-direction: column;
`;

export const WifiWrap = styled.div`
  //display: flex;
  //max-height: 32%;
  //width: 85%;
  //flex-wrap: wrap;
  //margin-top: .325rem;
  //margin-bottom: .5rem;
  //justify-content: space-between;


  display: flex;
  height: 57%;
  /* height:33%; */
  /* width: 88%; */
  width: 85%;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-top: .325rem;
  /* background-color:darksalmon; */
  justify-content: space-between;

`;

export const VolumeWrap = styled.div`
  display: flex;
  height: 15%;
  width: 88%;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
`;

export const UserWrap = styled.div`
  display: flex;
  width: 88%;
  max-height: 38%;
  margin-top: 3.5%;
`;

export const EctWrap = styled.div`
  display: flex;
  width: 88%;
  max-height: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* max-height: 38%; */
  margin-top: 3.5%;
`;

export const SettingBarWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: greenyellow;
`;

export const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 40px;
  height: 36px;
  bottom: 10px;
  right: 0px;
  background-color: white;
`;

export const IconBoxs = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-end;
`;

export const SettingBar = styled.div`
  background-color: white;
  width: 286px;
  height: 10px;
`;



export const SettingContentWrap = styled.div`
  position: relative;
  width: 88%;
  height: 30%;
  display: flex;
  padding: 0 .825rem;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0px -1px 8px 1px rgba(0, 0, 0, 0.16);
  background-color: white;
  margin-top: 1.5rem;
  ${defaultTheme.device.tablet} {
    margin-top: 3rem;
  }
`;
export const SettingContentWrap2 = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50%;
`;

export const LikuVersion = styled.button`
  width: 100%;
  height: 20%;
  margin-top: 5%;
  background-color: #939393;
  border-radius: 0.125rem;
  border: none;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  font-size: 0.8rem;
  text-align: center;
  color: rgb(255,255,255, 0.8);
`;

export const MotionWrap = styled.div`
  position: relative;
  display: flex;
  height: 30%;
  width: 94%;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 2%;
  border-top-left-radius: 14px;
`;

export const StateWrap = styled.div`
  display: flex;
  height: 24%;
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

export const LikuActionWrap = styled.div`
  display: flex;
  height: 67%;
  width: 100%;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  /* justify-content: space-between; */
  flex-direction: row;
`;

export const LikuSpeackWrap = styled.div`
  display: flex;
  height: 9%;
  width: 100%;
  /* background-color: pink; */
  flex-direction: column;
  align-items: center;
  justify-items: center;
  /* justify-content: space-between; */
  flex-direction: row;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.16);
`;

export const MicWrap = styled.div`
  display: flex;
  height: 52%;
  width: 100%;
  /* background-color: darkblue; */
  justify-content: space-between;
  flex-direction: column;
`;

export const LikuMotionWrap = styled.div`
  display: flex;
  height: 17%;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  justify-content:end;
`;

export const MainImg = styled.img`
  width: 106%;
  /* height: 120%; */
  margin-top: -7rem;
  margin-right: 1rem;
  ${defaultTheme.device.mobile} {
  }

  ${defaultTheme.device.tablet} {
    margin-top: -14rem;
  }
`;
