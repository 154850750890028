import React from "react";
import {
  ControlWrap,
  LikuStopBtn,
  LikuStopBtnWrap,
  StopIcon,
} from "../../style/control/LikuControlContaier";
import RootStore from "../../../store";
import { observer } from "mobx-react";

// import HomeVolumePresenter from "../../presenter/home/HomeVolumePresenter";
import ActionScreenContainer from "../../presenter/control/ActionScreenContainer";

const LikuControlContainer = observer(() => {
  const { ControlUiStore } = RootStore();
  return (
    <>
      <ControlWrap>
        <ActionScreenContainer />
        <LikuStopBtnWrap
          onClick={() => ControlUiStore.controlFunction({ type: "stop" })}
        >
          <LikuStopBtn>
            <StopIcon />
            리쿠 행동 멈추기
          </LikuStopBtn>
        </LikuStopBtnWrap>
        {/* <StateContent>
          <LikuStateBtn title='state'>{LikuUiStore.thisLikuState}</LikuStateBtn>
          <LikuStateBtn>
            <LikuMotionImg src={zzz} />
            <div style={{marginLeft: '.3rem'}}>리쿠 재우기</div>
          </LikuStateBtn>
          <LikuStateBtn>
            <BsAlarm />
            <div style={{marginLeft: '.3rem'}}>리쿠 깨우기</div>
          </LikuStateBtn>
        </StateContent>
        <LikuBoardWrap>
          <LikuIconWrap>
            <LikuIconImageWrap>
              <LikuIconImage src={LikuUiStore.thisLikuState === '자는중' ? liku_sleep : liku_wakeup} />
            </LikuIconImageWrap>
          </LikuIconWrap>
          <ActionScreenContainer />
          <LikuStopWrap>

          </LikuStopWrap>
        </LikuBoardWrap>
        <LikuVolumeWrap>
          <HomeVolumePresenter />
        </LikuVolumeWrap> */}
      </ControlWrap>
    </>
  );
});

export default LikuControlContainer;
