import React from 'react';
import {observer} from "mobx-react";

import * as Modal from "../../../style/modal/NewModal";

import RootStore from "../../../../store";
import * as MainUpdateModal from "../../../style/view/container/main/modal/MainUpdateModalStyled";

const MainUpdateModalContainer = observer(() => {
    const { LikuUiStore ,MainUpdateModalUiStore } = RootStore();

    return (
        <Modal.Modal>
            <MainUpdateModal.Context>
                <MainUpdateModal.MainContext>
                    <MainUpdateModal.Title>
                        리쿠 업데이트
                    </MainUpdateModal.Title>
                    필수 업데이트가 있습니다.<br />
                    진행해주세요.
                </MainUpdateModal.MainContext>
                <MainUpdateModal.ButtonWrap>
                    <MainUpdateModal.Button
                        onClick={() => MainUpdateModalUiStore.setVisible('info', false)}
                    >
                        다음에
                    </MainUpdateModal.Button>
                    <MainUpdateModal.Button
                        onClick={() => {
                            MainUpdateModalUiStore.setVisible('info', false);
                            LikuUiStore.preRestartLiku();
                            // MainUpdateModalUiStore.setVisible('check', true);
                        }}
                    >
                        업데이트
                    </MainUpdateModal.Button>
                </MainUpdateModal.ButtonWrap>
            </MainUpdateModal.Context>
        </Modal.Modal>
    );
});

export default MainUpdateModalContainer;
