import styled from "styled-components";
import {ModalContext, NextButton} from "../../../../modal/NewModal";

export const Context = styled(ModalContext)`
  width: 75%;
  height: 35%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const MainContext = styled.div`
  width: 85%;
  height: 55%;
  font-size: 3.8vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 5.5vw;
  font-weight: bold;
  text-align: center;
  //margin-top: 2%;
`;

export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45%;
`;

export const Button = styled(NextButton)`
  position: relative;
  height: 50%;
`;
