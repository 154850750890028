import { observer } from 'mobx-react';
import React from 'react'
import { ImSpinner6 } from "react-icons/im";
import { Close, CloseBtn, ModalWrapper, TitleWrap } from '../../../style/modal/connect/LikuConnectModalPresenter';
import { LikuUserContent, ModalTitle, ModalTitleWrap, UseName, UserImg, UserSub, UserTitle, UserWrap } from '../../../style/modal/HomeLikuStateModalPresenter';
import { TitleIconWrap } from '../../../style/modal/wifi/HomeLikuWifiModalPresenter';
import { defaultTheme } from '../../../style/Theme';
import liku_scan from '../../../../resource/img/liku_scan.png';
import { ModalUiStore } from '../../../../store/uistore/modal/ModalUiStore';
import RootStore from '../../../../store';
import { Spin } from 'antd';
import { Mqtt } from '../../../../store/domainstore/mqtt';
import { GrClose } from 'react-icons/gr';

const HomeLikuUserRecognizePresenter = observer(({open}) => {
const { ModalUiStore, UserStore, ControlUiStore } = RootStore();

	return (
    <ModalWrapper
      visible={open}>
      <ModalTitleWrap>
        <TitleIconWrap>
          <TitleWrap>
            <Spin  />
            <ModalTitle>리쿠를 바라봐주세요.</ModalTitle>
          </TitleWrap>
        </TitleIconWrap>
			<LikuUserContent>
				<UserWrap>
					<UserTitle>이름</UserTitle>
					<UserSub>:</UserSub>
					<UseName value={UserStore.user} />
				</UserWrap>
				<UserImg src={liku_scan} />
			</LikuUserContent>
      </ModalTitleWrap>
      {/* <CloseBtn onClick={ async () => {
          await ModalUiStore.setOpen('userRecognizeModal');
          await ControlUiStore.controlFunction({type: 'stop'});
          await Mqtt.setImageTopic();
          await UserStore.setResetUser();
        }} >
        <Close />
        나가기
      </CloseBtn> */}
  </ModalWrapper>
	)
});

export default HomeLikuUserRecognizePresenter;
