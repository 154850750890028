import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import { LikuFindModalWrap, FindModalTitleWrap, TitleWrap, LikuItem, LikuItemText, LikuItmeCheck, FindModalSubText, SubmitButton, FindModalTitle, FindLikuList, ModalWrapper, CloseBtn, RefreshButton, RefreshIcon, Close, SearchIcon, CloseBtnTop } from '../../../style/modal/connect/LikuConnectModalPresenter';
import { defaultTheme } from '../../../style/Theme';
import RootStore from '../../../../store';
import { requestPermission } from '../../../../service/RequestPermission';
import Loader from 'react-loader-spinner';

const LikuConnectModalPresenter = observer(({ 
  open,
  setOpen,
  likuConnectFunction,
}) => {
  const { LikuWebPostStore, ModalUiStore } = RootStore();
  const [choseLiku, setChoseLiku] = useState(null);
  const [click, setClick] = useState(false);
  const onChangeTrue = () => setClick(true);
  const onChangeFalse = () => setClick(false);

  // useEffect(() => {
  //     requestPermission({ type: "Liku_getList" });
  // },[]);


  return (
    <>
    <ModalWrapper
      visible={open}
    >
      <LikuFindModalWrap>
        <FindModalTitleWrap>
          <TitleWrap>
              <SearchIcon />
              <FindModalTitle>리쿠를 찾았습니다.</FindModalTitle>
          </TitleWrap>
          <FindModalSubText>어떤 리쿠와 연결하시겠습니까?</FindModalSubText>
          <CloseBtnTop onClick={() => ModalUiStore.setOpen('likuConnectModal')} >
            <Close />
          </CloseBtnTop>
        </FindModalTitleWrap>
        { click === false ?
          <RefreshButton onClick={ async () => {
            await LikuWebPostStore.resetBluetoothList();
            await onChangeTrue();
            setTimeout(async() => {
              await requestPermission({ type: "Liku_getList" });
              onChangeFalse();
            }, 1500);
        }}>
            새로 고침
            <RefreshIcon />
        </RefreshButton>
        :
        <RefreshButton on={click} > 
            불러오는 중
          {/* <RefreshIcon on={click} /> */}
          <Loader
							type="ThreeDots"
							color="#00BFFF"
              style={{marginLeft: '.1rem'}}
							height={7}
							width={15} //3 secs
						/>
        </RefreshButton>}
        <FindLikuList>
        {LikuWebPostStore.bluetoothList.map(liku => (
            <LikuItem key={liku.name}>
                  <LikuItemText>{liku.name}</LikuItemText>
                  <LikuItmeCheck type="radio" name="Liku" value={liku} onClick={() => setChoseLiku(liku)}/>
              </LikuItem>
          ))}
        </FindLikuList>
        <SubmitButton 
          disabled={choseLiku == null}
          onClick={() => {
            likuConnectFunction(choseLiku, setOpen);
          }}>
          리쿠 등록</SubmitButton>
      </LikuFindModalWrap>
    </ModalWrapper>
  </>
  )
});

export default LikuConnectModalPresenter
