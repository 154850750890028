import { observable, runInAction } from "mobx";
import { requestPermission } from "../../service/RequestPermission";
import { actionFunction, expressionFunction, stopFunction } from "../api/ControllApi";
import { Mqtt } from "../domainstore/mqtt";
import { LikuUiStore } from "./LikuUiStore";
import { ModalUiStore } from "./modal/ModalUiStore";
import { SuccessFailureModalUiStore } from "./modal/SuccessFailureModalUiStore";
import { SpeakingUiStore } from "./SpeakingUiStore";
import { getCookie } from "../../utils/cookie";

const ControlUiStore = observable({
  likuStateJson: null,
  reactionList: null,
  actionList: null,
  displayList: null,
  toneList: null,
  speakingStart: {type: 'null', data: null},
  speakingTTs: '',

  sendJson: {
    action: {
      action_name: '',
      action_type: 'overlap',
      yaw: 0,
      pitch: 0,
    },
    display: {
      display_name: '',
      delay: 0,
      playtime: 1,
      playspeed: 1,
    },
    speech: {
      speech_name: 'TTS_output',
      TTS: '',
      delay: 0,
      repeat: 1,
      speed: -1,
      pitch: -1,
    },
  },

  isSendJson: false,
  sendJsonList: [],

  isRefreshingPost: false,
  keyboardEnter: false,

  logRef: null,

  setSpeakingTTs(data) {
    this.speakingTTs = data;
    // console.log(data);
  },

  setIsRefreshingPost() {
    this.isRefreshingPost = !this.isRefreshingPost;
    // console.log('this.isRefreshingPost', this.isRefreshingPost);
  },

  async likuStateChange(data) {
    // await expressionFunction(json);
    // console.log('likuStateChange1', data);
    // console.log('likuStateChange2', LikuUiStore.thisLikuState);
    if (LikuUiStore.thisLikuState !== data) {
      if (data === '자는중') {
        this.setSpeakingStart('text', '리쿠가 잠들고 있습니다...');
        setTimeout(() => {
          this.setSpeakingStart('')
        }, 3000)
      } else {
          this.setSpeakingStart('text', '리쿠가 일어나고 있습니다!');
          setTimeout(() => {
            this.setSpeakingStart('')
          }, 3000)
      }
    }
  },
  // async likuStateWakeup(data) {
  //   if (LikuUiStore.thisLikuState !== data) {
  //     if (data === '자는중') {
  //       this.setSpeakingStart('text', '리쿠가 잠들고 있습니다...');
  //     } else {
  //       this.setSpeakingStart('text', '리쿠가 일어나고 있습니다!');
  //     }
  //   }
  // },

  async initRos() {
    // await likuStateFunction();

    await SpeakingUiStore.setThisAction(Object.keys(this.actionList)[0]);
    await SpeakingUiStore.setThisDisplay(Object.keys(this.displayList)[0]);
    await SpeakingUiStore.setThisTone(Object.keys(this.toneList)[0]);
    // console.log('======', SpeakingUiStore.thisDisplay);
    // await SpeakingUiStore.setThisAction(this.actionList[Object.keys(this.actionList)[0]].name);
    // await SpeakingUiStore.setThisDisplay(this.displayList[Object.keys(this.displayList)[0]].name);
    // await SpeakingUiStore.setThisTone(this.toneList[Object.keys(this.toneList)[0]].name);


    await SuccessFailureModalUiStore.setResult(false);
    await SuccessFailureModalUiStore.setModalType('likuSleep');

    ModalUiStore.setOpen('waitModal');
    setTimeout(() => {
      ModalUiStore.setOpen('waitModal');
    }, 3000);
  },


  setSpeakingStart(type, data) {
    this.speakingStart = {type: type, data: data};
  },

  async controlFunction(action, index) {
    LikuUiStore.controlUpdateLikuData();
    let text = SpeakingUiStore.thisTextInput;
    SpeakingUiStore.setThisTextInput('');
    console.log('LikuUiStore.thisLikuState', LikuUiStore.thisLikuState);
    if (LikuUiStore.thisLikuState !== '자는중') {
      if (action.type === 'stop') {
        await Mqtt.stopTopic();
        this.setSpeakingStart('text', '리쿠가 행동을 멈췄습니다.');
        setTimeout(() => {
          this.setSpeakingStart(null, null);
        }, 5000);
      } else if (action.type === 'likuState') {
        Mqtt.setLikuSleepTopic();
        // console.log('likuState');
        await this.likuStateChange(index);
      } else if (action.type.indexOf('reaction: ') !== -1) {
        await this.reactionFunction(action, index);
      } else if (action.type === 'stt') {
        await this.recordingButton();
      } else if (action.type === 'tts') {
        await this.ttsSpeaking(text);
      } else if (action.type === 'speaking') {
        await this.ttsSpeaking(action.json.text);
      }
    } else {
      await ModalUiStore.setOpen('successFailureModal');
      await SuccessFailureModalUiStore.setResult(false);
      await SuccessFailureModalUiStore.setModalType('likuSleep');
      // LikuUiStore.setThisLikuState('자는중');
    }
  },

  async reactionFunction(action, index) {
    let likuSerial = await getCookie('uuid');
    if (action.type.indexOf('expression') !== -1) {
      let topic = `liku/${likuSerial}/exp`; 
      this.setSpeakingStart('reaction', index);
      Mqtt.mqttPublish(topic, action.json);
      setTimeout(() => {
        this.setSpeakingStart(null, null);
      }, 5000);
    } else {
      let topic = `liku/${likuSerial}/json/list`;
      let actionJson;
      if (Array.isArray(action.json)) {
        if(action.type.indexOf('Dance') !== -1) {
          actionJson = {data: action.json};
        } else {
          actionJson = 
            {data: [action.json[Math.floor(Math.random() * action.json.length)]]};
        }
      } else {
        actionJson = {data : [action.json]};
      }
      console.log('actionJson', actionJson); 
      this.setSpeakingStart('reaction', index);

      Mqtt.mqttPublish(topic, actionJson);
      setTimeout(() => {
        this.setSpeakingStart(null, null);
      }, 5000);
    }
  },

  setSpeakingListItem(index, item) {
    this.setIsRefreshingPost();
    // let action = this.getKeyByValue(this.actionList, item.action);
    // let display = this.getKeyByValue(this.displayList, item.display);
    SpeakingUiStore.setThisAction(item.action);
    SpeakingUiStore.setThisDisplay(item.display);
    SpeakingUiStore.setThisTone(item.tone);
    this.setIsRefreshingPost();
    // console.log('setSpeakingListItem onPress!!!');
  },

  async updateSpeakingList(index, text) {
    this.setIsRefreshingPost();
    await SpeakingUiStore.updateSpeakingList(index, text);
    this.setIsRefreshingPost();
  },

  async recordingButton() {
    if (!SpeakingUiStore.mic) {
      runInAction(() => {
        SpeakingUiStore.mic = true;
      });
      // console.log('mic', SpeakingUiStore.mic);
      // await RecordStore.recordStart();
      requestPermission({ type: "Record_Start" });
      SpeakingUiStore.setThisTextInput('말씀이 끝난후 다시 버튼을 눌러주세요.');
    } else {
      SpeakingUiStore.setThisTextInput('잠시만 기다려 주세요...');
      requestPermission({ type: "Record_Stop" });
      runInAction(() => {
        SpeakingUiStore.mic = false;
      })
      if (this.speakingTTs !== '') {
        this.sttSpeaking(this.speakingTTs);
      }
      SpeakingUiStore.setThisTextInput('');
      // await RecordStore.recordStop().then(stt => {
      //   runInAction(() => {
      //     SpeakingUiStore.mic = false;
      //   });
      //   if (stt !== '') {
      //     this.sttSpeaking(stt);
      //   }
      //   SpeakingUiStore.setThisTextInput('');
      // });
    }
  },

  async ttsSpeaking(text) {
    let likuSerial = await getCookie('uuid');
    let topic = `liku/${likuSerial}/json/list`;
    let check = SpeakingUiStore.textCheckFunction(text);

    if (check) {
      if (
        this.actionList[SpeakingUiStore.thisAction].hasOwnProperty('action')
      ) {
        this.sendJson.action.action_name =
          this.actionList[SpeakingUiStore.thisAction].action[
            Math.floor(
              Math.random() *
                this.actionList[SpeakingUiStore.thisAction].action.length,
            )
          ];
      } else {
        this.sendJson.action.action_name = SpeakingUiStore.thisAction;
      }
      // console.log('this.sendJson.action.action_name', this.sendJson.action.action_name);

      // this.sendJson.action.action_name = SpeakingUiStore.thisAction;
      this.sendJson.display.display_name = SpeakingUiStore.thisDisplay;
      this.sendJson.speech.speed =
        this.toneList[SpeakingUiStore.thisTone].speed;
      this.sendJson.speech.pitch =
        this.toneList[SpeakingUiStore.thisTone].pitch;
      this.sendJson.speech.TTS = SpeakingUiStore.thisTextInput;
      this.sendJson.speech.TTS = text;
      this.setSpeakingStart('tts', text);

      let sendJsonObject = {data: [this.sendJson]};

      Mqtt.mqttPublish(topic, sendJsonObject);
      setTimeout(() => {
        this.setSpeakingStart(null, null);
      }, 5000);
    }
  },

  sttSpeaking(text) {
    this.sendJson.action.action_name = SpeakingUiStore.thisAction;
    this.sendJson.display.display_name = SpeakingUiStore.thisDisplay;
    this.sendJson.speech.tts = text;
    this.sendJson.speech.speed = this.toneList[SpeakingUiStore.thisTone].speed;
    this.sendJson.speech.pitch = this.toneList[SpeakingUiStore.thisTone].pitch;
    // LogUiStore.speakingSend('speaking', {tone: SpeakingUiStore.thisTone});
    this.setSpeakingStart('stt', text);
    actionFunction(this.sendJson).then(r => {
      // console.log('sttSpeaking');
      setTimeout(() => {
        this.setSpeakingStart(null, null);
      }, 5000);
    });
  },

  async speakingListSpeaking(json) {
    let likuSerial = await getCookie('uuid');

      let topic = `liku/${likuSerial}/json/list`;

      // this.setSpeakingStart('reaction', index);

      // Mqtt.mqttPublish(topic, actionJson);
      // setTimeout(() => {
      //   this.setSpeakingStart(null, null);
      // }, 5000);



    // console.log('json', json);
    // let action = this.getKeyByValue(this.actionList, json.action);
    // let display = this.getKeyByValue(this.displayList, json.display);
    // let speech = this.getKeyByValue(this.displayList, json.display);

    // if (this.actionList[json.action].hasOwnProperty('action')) {
    //   this.sendJson.action.action_name =
    //     this.actionList[json.action].action[
    //       Math.floor(Math.random() * this.actionList[json.action].action.length)
    //     ];
    // } else {
      // this.sendJson.action.action_name = json.action;
    // }

    // console.log('this.sendJson.action.action_name', this.sendJson.action.action_name);
    // this.sendJson.speech.tts = json.text;
    // this.sendJson.speech.speed = this.toneList[json.tone].speed;
    // this.sendJson.speech.pitch = this.toneList[json.tone].pitch;
    // this.sendJson.display.display_name = json.display;

    // let type = 'action';
    // let sendJ = this.sendJson;
    // if (this.sendJsonList.length > 1) {
    //   this.sendJsonList[1] = {type: type, json: sendJ};
    // } else {
    //   this.sendJsonList.push({type: type, json: sendJ});
    // }

    this.setSpeakingStart('speaking', json.text);
    actionFunction(this.sendJson).then(r => {
      setTimeout(() => {
        this.setSpeakingStart(null, null);
      }, 5000);
    });
  },

  removeSpeakingList(item, index) {
    this.setIsRefreshingPost();
    SpeakingUiStore.removeSpeakingList(item, index);
    this.setIsRefreshingPost();
  },

  async addSpeakingList() {
    this.setIsRefreshingPost(); 
    SpeakingUiStore.addSpeakingList();
    this.setIsRefreshingPost();
  },
});


export {ControlUiStore};