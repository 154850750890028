import styled from "styled-components";
import { defaultTheme } from "../Theme";

export const LikuReactionWrap = styled.div`
    width: 90%;
    height: 58%;
`;
export const ReactionContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export const TitleText = styled.div`
    color: #939393;
    font-weight: 600;
    font-size: .825rem;
    margin-top: .5rem;
    ${defaultTheme.device.tablet} {
        font-size: 1rem;
    }
`;

export const LikuSpeakWrap = styled.div`
  width: 90%;
  height: 42%;
  /* background-color: saddlebrown; */
`;