import { observable } from "mobx";
import Wifi from "../../data/model/WiFi";
import { ModalUiStore } from "./modal/ModalUiStore";
import { SuccessFailureModalUiStore } from "./modal/SuccessFailureModalUiStore";

const LikuWebPostStore = observable({
  bluetoothList: [],
  LikuData: [],
  choseLiku:{},
  bluetoothDeviceInfo:[],
  wifiList: [],
  wifiInfo: [],
  wifitype: {},

  internet: false,

  history: null,

  setHistory(history) {
    this.history = history;
  },

  bluetoothConnect: true,

  setBluetoothConnect(c) {
    this.bluetoothConnect = c;
    console.log('this.bluetoothConnect', this.bluetoothConnect);
  },

  setBluetoothList(data) {
    for(let i=0; i<data.length; i++) {
      data[i].name = data[i].name.toUpperCase();
    }
    this.bluetoothList = data;
    console.log('bluetoothList', this.bluetoothList);
  },

  resetBluetoothList() {
    this.bluetoothList = [];
    console.log('bluetoothList', this.bluetoothList);
  },

  setLikuData(data) {
    console.log("dsdfasdfasdfajsdkfjaklsdglaewjglawejl",data);
    if(data) {
      data.wifi =
        new Wifi(
          data.wifi.id,
          data.wifi.signal,
          data.wifi.ip,
          data.wifi.key,
          data.wifi.auth,
          data.wifi.state,
        );
      this.LikuData = data;
    } else {
      this.LikuData = false;
      console.log('fail');
    }
    // console.log('=======LikuData', this.LikuData);
  },

  setChoseLiku(data) {
    this.choseLiku = data;
  },

  setBluetoothDeviceInfo(data) {
    this.bluetoothDeviceInfo = data;
    console.log('this.bluetoothDeviceInfo', this.bluetoothDeviceInfo);
  },

  setWifiList(data) {
    if(!Array.isArray(data)) {
      this.wifiList = [];
    } else {
      this.wifiList = data;
      console.log('LikuWebPostStore.wifiList', this.wifiList);
    }
  },

  setWifiInfo(data) {
    this.wifiInfo = data;
  },

  setWifiType(data) {
    this.wifitype = data;
    // console.log('setWifiType', this.wifitype);
  },

  resetWifiList() {
    this.wifiList = [];
    console.log('wifiLists', this.wifiList);
  },

  async setInternet(data) {
    this.internet = data;
    if(!data) {
      await SuccessFailureModalUiStore.setResult(false);
      await SuccessFailureModalUiStore.setModalType('internet');
      await ModalUiStore.setOpen('successFailureModal');
      // await this.history.push("/");
    } else {
      console.log('인터넷 연결 성공')
    }
  },


});

export {LikuWebPostStore};
