import './App.css';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import HomePage from './view/page/HomePage';
import { defaultTheme } from './view/style/Theme';
import RootConfig from './config/RootConfig';
import RemotePage from './view/page/RemotePage';
import IntroPage from './view/page/IntroPage';
import LikuControlPage from './view/page/LikuControlPage';
import LikuActionControlPage from './view/page/LikuActionControlPage';
import LikuExpressionPage from './view/page/LikuExpressionPage';
import {useEffect} from "react";

const App: () =>  Node = () => {
  useEffect(() => {
    RootConfig();
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Router>
        <Route exact={true} path='/' component={IntroPage} />
        <Route exact={true} path='/home' component={HomePage} />
        <Route exact={true} path='/control' component={LikuControlPage} />
        <Route exact={true} path='/remote' component={RemotePage} />
        <Route exact={true} path='/action' component={LikuActionControlPage} />
        <Route exact={true} path='/expression' component={LikuExpressionPage} />
      </Router>
    </ThemeProvider>
  );
};

export default App;
