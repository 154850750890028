import { message } from 'antd';
import {observable} from 'mobx';
import { requestPermission } from '../../../service/RequestPermission';
import { LikuStore } from '../../domainstore/LikuStore';
import { LikuWebPostStore } from '../LikuWebPostStore';
import { SuccessFailureModalUiStore } from './SuccessFailureModalUiStore';


const LikuConnectModalUiStore = observable({
  async likuConnectFunction(choseLiku, setOpen) {
    let isFind = LikuStore.findLiku(choseLiku);
    // console.log('isFind', isFind);
    if (isFind !== -1) {
      message.success('이미 등록된 리쿠입니다.');
    }
    else {
      requestPermission({ type: "Liku_connect", data: choseLiku });
      LikuWebPostStore.setChoseLiku(choseLiku);
      // console.log('find if');
      SuccessFailureModalUiStore.setModalType('enrollment');
      setOpen('likuConnectModal');
      setOpen('waitModal');
    }
  },
});

export {LikuConnectModalUiStore};
