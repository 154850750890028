import styled from "styled-components";
import { ViewBox } from "../Box/Box";
import { defaultTheme } from "../Theme";
import { HiVolumeUp } from "react-icons/hi";

export const VolumeBox = styled(ViewBox)`
  height: 90%;
  width: 90%;
  /* height: 100%;
  width:100%; */
  display: flex;
  align-items:  center;
  flex-direction: row;
  border-radius: 3px;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  /* flex-direction: column; */
  justify-content: center;
`;

export const VolumeBoxMain = styled(ViewBox)`
  height: 90%;
  width: 94%;
  /* height: 100%;
  width:100%; */
  display: flex;
  align-items:  center;
  flex-direction: row;
  border-radius: 3px;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  /* flex-direction: column; */
  justify-content: center;
`;

export const VolumeTitleWrap = styled.div`
  /* width: 100%; */
  width: 13%;
  display: flex;
  justify-content: center;
  align-items: center; 
  /* flex-direction: column; */
  /* margin-top: 5%;
  margin-left: 10%; */
`;

export const VolumeTitle = styled.div`
  font-size: ${defaultTheme.fonts.size.semini};
  font-weight: bold;
  margin: 0 2%;
`;

export const VolumeUp = styled(HiVolumeUp)`
  font-size: ${defaultTheme.fonts.size.semini}px;

  ${defaultTheme.device.tablet} {
    font-size: 1.3rem;
  }
`;
