import {observable, runInAction} from 'mobx';
// import {setSpeakingStorage} from '../../data/storage/SpeakingStorage';
// import MacroRepository from '../../data/repository/MacroRepository';
import Macro from '../../data/model/Marco';
import MacroRepository from '../../data/repository/MacroRepository';
import { getCookie, removeCookie, setCookie } from '../../utils/cookie';
import { LikuUiStore } from './LikuUiStore';
import { UserUiStore } from './UserUiStore';

const SpeakingUiStore = observable({
  speakingType: 'universal',
  speakingListUniversal: [], // universal 범용
  speakingListIndividual: [], // individual 개인

  thisTextInput: '',
  thisAction: null,
  thisDisplay: null,
  thisTone: null,
  mic: false,

  async setSpeakingListUniversal() {
    // let macro = await MacroRepository.getMacroAll(UserUiStore.thisUser.idx);
    // runInAction(() => {
    //   this.speakingListIndividual = macro;
    // });
    // console.log('this.speakingListIndividual.length', this.speakingListIndividual.length);
    getCookie('MacroData').then((SpeakingData) => {
      if (SpeakingData !== undefined && this.speakingListUniversal.length === 0) {
        SpeakingData.map(macro => {
            this.addMacro(
              new Macro(
                null,
                null,
                null,
                // macro.action,
                // macro.display,
                macro.text,
                // macro.tone,
              ),
            );
          });
      }
      // console.log('setSpeakingListUniversal', this.speakingListUniversal)
    });
  },

  addMacro(macro) {
    this.speakingListUniversal.push(macro);
    // console.log('addMacro', macro);
    // this.likus = this.likus.slice().reverse();
  },

  setThisTextInput(text) {
    this.thisTextInput = text;
  },

  setSpeakingType(type) {
    this.speakingType = type;
  },

  setThisAction(action) {
    this.thisAction = action;
  },

  setThisDisplay(display) {
    this.thisDisplay = display;
  },

  setThisTone(speech) {
    this.thisTone = speech;
  },

  updateSpeakingList(index, textInput) {
    console.log('index', index);
    console.log('textInput', textInput);
    console.log('textInput', this.speakingListUniversal[index]);
    this.speakingListUniversal[index].setText(textInput);
      // this.speakingListUniversal[index].setAction(this.thisAction);
      // this.speakingListUniversal[index].setDisplay(this.thisDisplay);
      // this.speakingListUniversal[index].setTone(this.thisTone);
    console.log('textInput', this.speakingListUniversal[index]);
    this.saveSpeakingList();
  },

  async saveSpeakingList() {
    setCookie('MacroData', this.speakingListUniversal, {path: '/', maxAge: 72000000});
    let speakingList = await getCookie('MacroData');
    console.log('speakingList', speakingList);
    // setSpeakingStorage(this.speakingListUniversal);
  },

  async sendSpeakingList() {
    // MacroRepository.setMacro(macro).then(r => {
    //   this.setSpeakingListIndividual().then(r =>
    //     console.log('sendSpeakingList'),
    //   );
    // });
		let Macro = await getCookie('MacroData');
		// console.log('MacroData', Macro);
		this.setSpeakingListUniversal(Macro);
  },

  async addSpeakingList() {
    // console.log('addSpeakingList');
    let check = this.textCheckFunction(this.thisTextInput);
    // console.log('addSpeakingList check', check);
    if (check) {
      let newMacro = new Macro(
        null,
        // UserUiStore.thisUser.idx,
        null,
        null,
        // this.thisAction,
        // this.thisDisplay,
        this.thisTextInput,
        // this.thisTone,
      );
      newMacro.setMacroName(
        `${LikuUiStore.getThisLikuSerial()}*,,*${
          'liku'
        }*,,*${new Date().getTime()}`,
      );
      // console.log('newJson', newMacro);
      await this.addMacro(newMacro);
      let MacroData = JSON.stringify(this.speakingListUniversal);
      setCookie('MacroData', MacroData, {path: '/', maxAge: 72000000});
      // console.log('this.speakingListUniversal', this.speakingListUniversal);
      // console.log('this.speakingListUniversal', MacroData);
      // this.sendSpeakingList(newMacro);
    }
    this.setThisTextInput('');
  },

  removeSpeakingList(item, index) {
      this.speakingListUniversal.splice(index, 1);
      removeCookie('MacroData');
      let MacroData = JSON.stringify(this.speakingListUniversal);
      setCookie('MacroData', MacroData, {path: '/', maxAge: 72000000});
      // console.log('removeSpeakingList');
      // console.log('speakingListUniversal', this.speakingListUniversal);
      // this.saveSpeakingList();
  },

  textCheckFunction(text) {
    let textCheck = text.replace(/\s/gi, '');
    if (textCheck !== '') {
      return true;
    } else {
      return false;
    }
  },

  setSpeakingListDraggable(list) {
    if (this.speakingType !== 'individual') {
      this.speakingListUniversal = list;
      this.saveSpeakingList();
    } else {
      this.speakingListIndividual = list;
    }
  },

  cleanUpSpeaking() {
    this.speakingListIndividual = [];
    this.thisTextInput = '';
  },
});

export {SpeakingUiStore};
