import styled from "styled-components";
// import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import { defaultTheme } from "../../style/Theme";

export const ModalWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 999;
`;

export const ModalContexts = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 260px;
  height: 100px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 24;
  z-index: 999;

  ${defaultTheme.device.tablet} {
    width: 60%;
    height: 20%;
  }
`;

export const ModalInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70%;
  font-size: 1rem;
  text-align: center;
  color: black;
  //background-color: red;
`;

export const ModalButton = styled.button`
  width: 100%;
  height: 30%;
  background-color: #4a4a4a;
  //border-radius: 0.125rem;
  border: none;
  //box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  font-size: 0.8rem;
  text-align: center;
  color: rgb(255,255,255, 0.8);
  font-size: ${defaultTheme.fonts.size.semini};
  font-weight: bold;
  ${defaultTheme.device.tablet} {
    height: 15%;
    font-size: 1.125rem;
  }
`;
