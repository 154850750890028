import React, { useRef } from "react";
import { observer } from "mobx-react";
import RootStore from "../../../store";
import {
  LikuSpeackingWrap,
  PlayCircle,
  PlayText,
  PlayWrap,
  PlusCircle,
  PlusContentWrap,
  SpeakTextInputWrap,
} from "../../style/control/LikuSpeackContainer";

const LikuSpeackContainer = observer(() => {
  const { SpeakingUiStore, ControlUiStore } = RootStore();
  const TextInputRef = useRef();
  const onChange = (e) => {
    SpeakingUiStore.setThisTextInput(e.target.value);
  };
  return (
    <>
      <LikuSpeackingWrap>
        <PlusContentWrap
          onClick={async () => {
            await ControlUiStore.addSpeakingList();
          }}
        >
          <PlusCircle />
          내용 등록
        </PlusContentWrap>
        <form
          style={{ width: "100%" }}
          onSubmit={(e) => {
            e.preventDefault();
            ControlUiStore.controlFunction({ type: "tts" }).then((r) =>
              TextInputRef.current.focus()
            );
          }}
        >
          <SpeakTextInputWrap
            placeholder="내용을 입력해주세요."
            ref={TextInputRef}
            value={SpeakingUiStore.thisTextInput}
            maxLength={90}
            onChange={onChange}
          />
        </form>
        <PlayWrap
          onClick={() =>
            ControlUiStore.controlFunction({ type: "tts" }).then((r) =>
              TextInputRef.current.focus()
            )
          }
        >
          <PlayCircle />
          <PlayText>실행</PlayText>
        </PlayWrap>
      </LikuSpeackingWrap>
    </>
  );
});

export default LikuSpeackContainer;
