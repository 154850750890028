import styled from "styled-components";
import { defaultTheme } from "../Theme";

export const ViewBox = styled.div`
  border-radius: ${(props) => props.borderRadius}px;
  border-color: ${defaultTheme.colors.border};
  border-width: 1px;
  background: ${(props) => props.backgroundColor};
`;

export const ButtonBox = styled.div`
  /* border-radius: ${(props) => props.borderRadius}px; */
  border-color: ${defaultTheme.colors.border};
  border-width: 1px;
  background: ${(props) => props.backgroundColor};
`;

ViewBox.defaultProps = {
  // borderRadius: 5,
  backgroundColor: defaultTheme.colors.white,
};

ButtonBox.defaultProps = {
  borderRadius: 5,
  backgroundColor: defaultTheme.colors.white,
};
