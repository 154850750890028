import { SpeakingUiStore } from "../store/uistore/SpeakingUiStore";

const SpeakinListConfig = async () => {
  setTimeout(() => {
    SpeakingUiStore.setSpeakingListUniversal();
  }, 5000);
		// console.log('SpeakingUiStore.speakingListUniversal', SpeakingUiStore.speakingListUniversal);
};

export default SpeakinListConfig;
