import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const setCookie = (name, value, option) => {
  return cookies.set(name, value, option);
}

export const getCookie = async (name) => {  
  return await cookies.get(name);
}

export const removeCookie = (name, option) => {
  console.log('removeCookie', name);
  return cookies.remove(name, option);
}