import styled from "styled-components";
import { IoIosBluetooth } from "react-icons/io";
import { defaultTheme } from "../Theme";

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 10%;
  color: white;

  ${defaultTheme.device.mobile} {
    font-size: .725rem;
  }

  ${defaultTheme.device.tablet} {
    font-size: 1.25rem;
  }

`;

export const Bluetooth = styled(IoIosBluetooth)`
  font-size: 1.25rem;
`;

export const ModalTitleText = styled.div`
  font-size: .5rem;
`;

export const BluetoothImg = styled.img`
  width: 80%;
`;

export const ModalSubText = styled.div`
  /* font-size: 1rem; */
`;

export const ModalSub = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 0.5rem;
`;

export const ModalBlutootContent = styled.div`
  width: 96%;
  height: 90%;
  background-color: white;
  font-size: .4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: .5rem;
  margin-bottom: .5rem;
`;

export const ModalHeaderWrap = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  height: 70%;
  width: 92%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #6d9bef;
  border-radius: .5rem;
`;