import {observable} from "mobx";
import {LikuUiStore} from "./LikuUiStore";
import LikuRepository from "../../data/repository/LikuRepository";

const MainUpdateModalUiStore = observable({
    visible: {
        info: false,
        check: false,
        reload: false,
    },

    isUpdating: false,

    setVisible(type, value) {
        this.visible[type] = value;
        console.log('this.visible[type]', this.visible[type]);
    },

    updateCheck() {
        // return LikuStore.checkVersion().then((version) => {
        const name = LikuUiStore.thisLiku.name.replace('LIKU: ', '');
        const data = {serial: name};
        return LikuRepository.getLikuInfo(data).then((value) => {
            console.log('value', value);
            // version.likuVer = "https://";
            const likuVer = value?.version?.likuVer;
            // const versionType = Object.keys(version);
            // const isUpdate = versionType.find((ver) => isNaN(version[ver]) && !ver.includes('serialNum'));
            const isUpdate = value?.version?.updaterVer === "2.0";

            if (isUpdate) this.setVisible('info', true);
            return {isUpdate, likuVer};
        });
    },

    setIsUpdating(value) {
        this.isUpdating = value;
    },

    update() {
        if (!this.isUpdating) {
            this.setVisible('check', false);
        } else {
            this.setVisible('reload', false);
        }
        // WaitModalUiStore.setVisible(true);
        // WebSocketStore.connect().then(() => {
        //     console.log('connect');
        // });
    },

    updating(result) {
        console.log('updating2, ', this.isUpdating);
        console.log('updating3, ', !this.isUpdating);
        if (!this.isUpdating) {
            console.log('updating4, ', result);
            if (result) {
                // this.setIsUpdating(true);
                // WebSocketStore.webSocket.send('reasoningOn');
                // WebSocketStore.webSocket.send('reasoningOff');

            } else if (this.isUpdating) {
                // WaitModalUiStore.setVisible(false);
                this.setVisible('check', true);
            }
        }
        // else {
        //     setTimeout(() => {
        //         LikuUiStore.setChoseLiku(LikuUiStore.thisLiku);
        //     }, 2000);
        // }
        // else {
        //     this.setIsUpdating(false);
        //     WaitModalUiStore.setVisible(false);
        //     WebSocketStore.webSocket.send('reasoningOn');
        // }
    },


    setState() {
        console.log('setState', !this.isUpdating);

        if (this.isUpdating) {
            this.setVisible('reload', true);
        } else {
            // this.setIsUpdating(true);
        }
    },

    // reload() {
    //     console.log('end');
    //     if (this.isUpdating) this.setVisible('reload', true);
    // },
});

export {MainUpdateModalUiStore};
