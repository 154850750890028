import { serverFetchJson, serverFetchText } from "../../store/api/ServerApi";
import { ServerStore } from "../../store/domainstore/ServerStore";
import Macro from "../model/Marco";

class MacroRepository {
  async getMacroAll(userIdx) {
    let URL = `${ServerStore.URL}/remote/macro/read`;
    let BODY = {userIdx: userIdx};
    return await serverFetchJson(URL, BODY).then(r => {
      let macroList = [];
      r.map(l => {
        macroList.push(
          new Macro(
            l.id,
            l.userIdx,
            l.macroName,
            l.action,
            l.display,
            l.text,
            l.tone,
          ),
        );
      });
      // console.log('getMacroAll');
      return macroList;
    });
  }

  async setMacro(macro) {
    let URL = `${ServerStore.URL}/remote/macro/create-update`;
    return await serverFetchText(URL, macro).then(r => {
      // console.log('setChat');
      return r;
    });
  }

  async deleteMacro(macro) {
    let URL = `${ServerStore.URL}/remote/macro/delete`;
    return await serverFetchText(URL, macro).then(r => {
      // console.log('deleteMacro');
      return r;
    });
  }
}

export default new MacroRepository();
