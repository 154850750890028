import { getCookie, setCookie } from "./cookie";

export async function get_uuid() {
  let URL_2 = "https://api.likuwithme.com";
  let TOKEN = "eyJhbGciOiJIUzUxMiJ9.eyJleHAiOjI1MDE1NDcxOTUsImlhdCI6MTYzNzU0NzE5NSwianRpIjoidG9yb29jQHRvcm9vYy5jb20ifQ.v75Jh0B796lE6CYt42LvECLtiUhLTH0K2cVq-4WvHh5uP4KIsjGvodvqgSp_kruaVD0GwAPKdd7NiKbVVOk1AA";
  let URL = URL_2 + "/liku/liku";

  console.log({serial: getCookie('likuSerial')})
  const l = await getCookie('likuSerial');
  return await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${TOKEN}`
      },
      body: JSON.stringify({serial:l }), 
  })
    .then(text => text.text())
    .then(result => {
     let uuid = JSON.parse(result.replaceAll('"{', '{').replaceAll('}"', '}')).uuid
      // console.log('uuid : ', uuid);
      setCookie('uuid', uuid, {path: '/', maxAge: 72000000});
      return uuid;
    })
    .catch(error => {
      // console.log('error : ', error);
    });
}

