import React, { useState } from 'react'
import once_on from '../../../resource/img/once_on.svg';
import once_off from '../../../resource/img/once_off.svg';
import n_on from '../../../resource/img/n_on.svg';
import n_off from '../../../resource/img/n_off.svg';
import n_btn from '../../../resource/img/n_btn.svg';
import once from '../../../resource/img/once.svg';

import { WiFiControlBox } from '../../style/home/HomeWiFiAndControllPresenter';
import { IconImageWrap, ImageButton, LangImage, MenuTitle, OnOffImage } from '../../style/home/HomeMenuBoxPresenter';
import { Mqtt } from '../../../store/domainstore/mqtt';
import { observer } from 'mobx-react';
import RootStore from '../../../store';

const LikuFollwingPresenter = observer(() => {
  const { LikuUiStore } = RootStore();

  return (
    <>
      <WiFiControlBox $type='once'>
        <MenuTitle>연속 대화 설정</MenuTitle> 
        <IconImageWrap>
          <ImageButton>
            <LangImage
              src={LikuUiStore.thisConversation ? once_off : once_on}
            />
            <OnOffImage onClick={() => {
              if(LikuUiStore.thisConversation) {
                Mqtt.setConversationOff()
              } else {
                Mqtt.setConversation()
              }
            }}
             src={LikuUiStore.thisConversation ? n_btn : once} /> 
             <LangImage
              src={LikuUiStore.thisConversation ? n_on : n_off}
            />
          </ImageButton>
        </IconImageWrap>
       </WiFiControlBox>
    </>
  )
});

export default LikuFollwingPresenter;