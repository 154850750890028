import RootStore from "../store";

import SleepJson from '../resource/json/expression/Sleep.json';
import WakeUpJson from '../resource/json/expression/WakeUp.json';

import DanceJson from '../resource/json/expression/Dance.json';
import HighfiveJson from '../resource/json/reaction/Highfive.json';
import StupChairJson from '../resource/json/expression/StupChair.json';
import HandshakeJson from '../resource/json/reaction/Handshake.json';
import ClapJson from '../resource/json/reaction/Clap.json';
import FightingJson from '../resource/json/reaction/Fighting.json';
import LoveJson from '../resource/json/reaction/Love.json';
import PosePicture1Json from '../resource/json/reaction/PosePicture1.json';
import PosePicture2Json from '../resource/json/reaction/PosePicture2.json';
import HiJson from '../resource/json/reaction/Hi.json';
import StretchingJson from '../resource/json/reaction/stretching_arm.json';
import SitDownChairJson from '../resource/json/expression/SitDownChair.json';

import HeadTestJson from '../resource/json/reaction/HeadTest.json';

import KidOTJson from '../resource/json/reaction/KidOT.json';

import ApproachAppJson from '../resource/json/expression/ApproachApp.json';
import RecedeAppJson from '../resource/json/expression/RecedeApp.json';

import DefaultRandomImage from '../resource/img/action/shuffle.png';
import Hi1Image from '../resource/img/action/group-700.png';
import Hi2Image from '../resource/img/action/group-696.png';
import HighfiveImage from '../resource/img/action/group-708.png';
import ClapImage from '../resource/img/action/group-711.png';
import PosePicture1Image from '../resource/img/action/pose_picture1.png';
import PosePicture2Image from '../resource/img/action/pose_picture2.png';
import HandshakeImage from '../resource/img/action/handshakeleft.png';
import FightingImage from '../resource/img/action/fighting.png';

import BlinkImage from '../resource/img/display/group-742.png';
import JoyImage from '../resource/img/display/group-738.png';
import ShyImage from '../resource/img/display/group-718.png';
import CryImage from '../resource/img/display/group-733.png';
import CalmImage from '../resource/img/display/group-725.png';
import DizzyImage from '../resource/img/display/dizzy.png';

import Dance1 from  '../resource/json/reaction/Dance1.json'
import Dance2 from  '../resource/json/reaction/Dance2.json'
import Dance3 from  '../resource/json/reaction/Dance3.json'
import Dance4 from  '../resource/json/reaction/Dance4.json'
import Macro from "../data/model/Marco";
import {getCookie, setCookie} from "../utils/cookie";
import { SpeakingUiStore } from "../store/uistore/SpeakingUiStore";

const ControlConfig = async () => {

  const {ControlUiStore} = RootStore();
  ControlUiStore.likuStateJson = {
    sleep: {
      name: '리쿠 재우기',
      json: SleepJson
    },
    wakeUp: {
      name: '리쿠 깨우기',
      json: WakeUpJson
    }
  };

  ControlUiStore.reactionList = [
        // {
    //   name: '연속댄스',
    //   info: '춤과 노래를 합니다.',
    //   type: 'reaction: action Dance',
    //   json: [Dance1, Dance2, Dance3, Dance4],
    // },
    // {
    //   name: '엉덩이송',
    //   info: '',
    //   type: 'reaction: action Dance',
    //   json: Dance1,
    // },
    // {
    //   name: '곰세마리',
    //   info: '',
    //   type: 'reaction: action Dance',
    //   json: Dance2,
    // },
    // {
    //   name: '문어의 꿈',
    //   info: '',
    //   type: 'reaction: action Dance',
    //   json: Dance3,
    // },
    // {
    //   name: '방귀송',
    //   info: '',
    //   type: 'reaction: action Dance',
    //   json: Dance4,
    // },
    {
      name: '댄스',
      info: '춤과 노래를 합니다.',
      type: 'reaction: expression',
      json: DanceJson,
    },
    {
      name: '사진포즈',
      info: '포즈를 취합니다.',
      type: 'reaction: action',
      json: [PosePicture1Json, PosePicture2Json],
    },
    // SitDownChair: {
    {
      name: '앉기',
      info: '의자가 있는지 확인',
      type: 'reaction: expression',
      json: SitDownChairJson,
    },
    {
      name: '안녕!',
      info: '인사를 합니다.',
      type: 'reaction: action',
      json: HiJson,
    },
    {
      name: '하이파이브',
      info: '하이파이브 합니다.',
      type: 'reaction: action',
      json: HighfiveJson,
    },
    {
      name: '일어나기',
      info: '의자에서 일어납니다.',
      type: 'reaction: expression',
      json: StupChairJson,
    },
    {
      name: '악수',
      info: '악수를 청합니다.',
      type: 'reaction: action',
      json: HandshakeJson,
    },
    {
      name: '박수',
      info: '박수를 칩니다.',
      type: 'reaction: action',
      json: ClapJson,
    },
    {
      name: '화이팅!',
      info: '응원을 합니다.',
      type: 'reaction: action',
      json: FightingJson,
    },
    {
      name: '사랑해',
      info: '사랑해를 합니다.',
      type: 'reaction: action',
      json: LoveJson,
    },
    {
      name: '기지개',
      info: '',
      type: 'reaction: action',
      json: StretchingJson,
    },
    {
      name: '목 스트레칭',
      info: '',
      type: 'reaction: action',
      json: HeadTestJson,
    },
    {
      name: '유치원 OT',
      info: '',
      type: 'reaction: action Dance',
      json: KidOTJson.data,
    },
    {
      name: '걷기(앞)',
      info: '',
      type: 'reaction: expression',
      json: ApproachAppJson,
    },
    {
      name: '걷기(뒤)',
      info: '',
      type: 'reaction: expression',
      json: RecedeAppJson,
    },
  ];

  ControlUiStore.actionList = {
    defaultArray: {
      name: '기본 (랜덤)',
      action: [
        'point1',
        'scroll2',
        'general4',
        'general9',
        'touch',
        'pointing',
      ],
      icon: DefaultRandomImage,
    },
    greet_arm: {name: '인사(오른손)', icon: Hi1Image},
    greeting2: {name: '인사(양손)', icon: Hi2Image},
    highfive: {name: '하이파이브', icon: HighfiveImage},
    applaud: {name: '박수', icon: ClapImage},
    pose_picture1: {name: '사진포즈(한손)', icon: PosePicture1Image},
    pose_picture2: {name: '사진포즈(양손)', icon: PosePicture2Image},
    handshakeleft: {name: '악수(왼손)', icon: HandshakeImage},
    fighting: {name: '화이팅(양손)', icon: FightingImage},
  };

  ControlUiStore.displayList = {
    Blink: {name: 'Blink', icon: BlinkImage},
    Joy: {name: 'Joy', icon: JoyImage},
    Shy: {name: 'Shy', icon: ShyImage},
    Dizzy: {name: 'Dizzy', icon: DizzyImage},
    Calm: {name: 'Calm', icon: CalmImage},
    Cry: {name: 'Cry', icon: CryImage},
  };

  ControlUiStore.toneList = {
    default: {name: '기본', speed: -1, pitch: -1},
    tranquil: {name: '차분한', speed: 0, pitch: 0},
    determined: {name: '단호한', speed: -1, pitch: 0},
    quiet: {name: '조용한', speed: 1, pitch: 0},
    exasperated: {name: '격양된', speed: -2, pitch: -1},
    youthful: {name: '발랄한', speed: 0, pitch: -1},
  };

  let speakingList = await getCookie('MacroData');
  console.log('speakingList', speakingList);
  if (speakingList !== undefined) {
    speakingList.map(speaking => {
      console.log('speaking', speaking);
      if (speaking.macroName === '2') {
        speaking.text = '싫은데, 다른 선택지는 없나요?';
      } else if (speaking.macroName === '3') {
        speaking.text = '나 귀엽지? 사진찍어줘!';
      }
      SpeakingUiStore.speakingListUniversal.push(
        new Macro(
          speaking.idx,
          speaking.userIdx,
          speaking.macroName,
          // speaking.action,
          // speaking.display,
          speaking.text,
          // speaking.tone,
        ),
      );
    });
  } else {
    SpeakingUiStore.speakingListUniversal = [
      new Macro(
        null,
        null,
        '1',
        // 'defaultArray',
        // 'Blink',
        '안녕? 나는 리쿠라고해!',
        // 'default',
      ),
      new Macro(
        null,
        null,
        '2',
        // 'defaultArray',
        // 'Blink',
        // '나 귀엽지? 사진찍어줘!',
        '싫은데, 다른 선택지는 없나요?',
        // 'default',
      ),
      new Macro(
        null,
        null,
        '3',
        // 'defaultArray',
        // 'Blink',
        // '내 손바닥 쳐줄래?',
        '나 귀엽지? 사진찍어줘!',
        // 'default',
      ),
    ];

    // console.log('SpeakingUiStore.speakingListUniversal', SpeakingUiStore.speakingListUniversal);
    setCookie('MacroData', SpeakingUiStore.speakingListUniversal, {path: '/', maxAge: 72000000})
  }

  // if (speakingList === undefined) {
  //   setSpeakingStorage(SpeakingUiStore.speakingListUniversal);
  // }

}

export default ControlConfig;
