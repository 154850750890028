import styled from "styled-components";
import EditBtn from '../../../resource/img/invalid.png';
import speakBtn from '../../../resource/img/invalid-name.png';
import speakBtns from '../../../resource/img/useBtns.png';
import { FaTrash } from "react-icons/fa";
// import { AiOutlineSave } from 'react-icons/ai';
import { IoPencilOutline } from "react-icons/io5";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { defaultTheme } from "../Theme";

export const SpeakContentWrap = styled.div`
  width: 100%;
  height: 80%;
  /* margin-bottom: 0.5rem; */
  /* margin-left: 8%; */
  overflow-y: scroll;
  overflow-x: hidden; 
  ::-webkit-scrollbar {
    width: .4rem;
    background-color: rgba(244, 242, 241, 0.4);
  }
  ::-webkit-scrollbar-thumb {
    background-color:#ebebeb;
    border-radius: 0.8rem;
  }
  /* background-color: turquoise; */
`;

export const SpeakingBox = styled.div`
  position: relative;
  display: flex;
  width: 94%;
  height: 28%;
  align-items: center;
  box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: .5rem;
  justify-content: space-between;
  background-color: #ededed;
  /* margin-bottom: 1rem; */
  margin: .35rem 0 .3rem .4rem; 
`;

export const SpeakingControlBox = styled.div`
  display: flex;
  width: 94%;
  height: 100%;
  /* box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1); */
  background-color: white;
  border-radius: .5rem;
  flex-direction: column;
  margin-left: .5rem;
`;
export const SpeakingTTSScrollViews = styled.div`
  /* background-color: yellowgreen; */
  width: 100%;
  align-items: center;
  display: flex;
  height: 100%;
  /* padding-top: .325rem;
  line-height: .725rem; */
  border-radius: 3px;
  overflow: scroll;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
`;

export const EidtsWrap = styled.div`
  background: url(${speakBtn});
  background-size: cover;
  position: absolute;
  width: 15%;
  right: -.85rem;
  top: -.45rem;
  height: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  ${defaultTheme.device.tablet} {
    top: -1rem;
    right: -1.425rem;
  }
`;
export const EditingWraps = styled.div`
  position: absolute;
  width: 45%;
  height: 130%;
  right: -0.7rem;
  top: -.5rem;
  /* margin-top: 0.2rem; */
  background: url(${EditBtn});
  background-size: cover;
  display: flex;
  flex-direction: row;
  z-index: 99;
  justify-content: flex-end;
  ${defaultTheme.device.tablet} {
    height: 128%;
    width: 42%;
    top: -1rem;
    right: -1.8rem;
  }
`;
export const EditContents = styled.div`
  display: flex;
  flex-direction: row;
  /* position: absolute; */
  align-items: center;
  color: white;
  width: 40%;
  margin-top: 0.655rem;
  font-size: 0.625rem;
  ${defaultTheme.device.tablet} {
    font-size: 1rem;
  }
`;
export const EditBg = styled.img`
  width: 130%;
  height: 155%;
  top: -0.7rem;
  right: -0.42rem;
  position: absolute;
`;

export const Trash = styled(FaTrash)`
  z-index: 99;
  color: white;
  font-size: .85rem;
  margin: 0.25rem 0.25rem 0.25rem 0;
  ${defaultTheme.device.tablet} {
    font-size: 1rem;
    margin-right: 0.25rem;
  }
`;

export const Pencil = styled(IoPencilOutline)`
  right: 0.5rem;
  z-index: 99;
  color: white;
  top: 20%;
  margin: 0.25rem 0;
  font-size:1rem;
  ${defaultTheme.device.tablet} {
    font-size: 1.25rem;
    margin-right: 0.25rem;
  }
`;

export const CloseCircle = styled(AiOutlineCloseCircle)`
  right: 0.5rem;
  z-index: 99;
  color: white;
  margin: 1rem 0 1.2rem;
  font-size:1rem;
`;

export const EidtSaveWrap = styled.div`
  background: url(${speakBtns});
  background-size: cover;
  position: absolute;
  width: 15%;
  right: -.85rem;
  top: -.45rem;
  height: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  ${defaultTheme.device.tablet} {
    top: -1rem;
    right: -1.425rem;
  }
`;