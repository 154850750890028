import styled from "styled-components";

export const ActionHeaderTap = styled.div`
  width: 100%;
  height: 10%;
  background-color: aliceblue;
`;

export const ActionInputWrap = styled.div`
  width: 100%;
  height: 15%;

`;

export const ActionMainWrap = styled.div`

`;

export const ActionPlayWrap = styled.div`

`;