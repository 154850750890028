import React, {useState} from 'react'
import { UserContent, UserTitle, UserView, UserBox, UserProfile, UserText, UserAddButton, UserAddButtonText, EditWrap, EditIcon, UserEditWrap, TrashIcon, DashLine, UserSetting, UserBtnImg, PencilOutline, PencilOutlineEdit, UserPlus, UserCircle, UserEditMainWrap } from '../../style/home/HomeUserPresenter'
import FlatList from 'flatlist-react';
import { FaUserCircle, FaUserPlus } from "react-icons/fa";
import { defaultTheme } from '../../style/Theme';
import UserBtn from '../../../resource/img/userBtn.png'
import EditBtn from '../../../resource/img/userBtnLong.png';
import RootStore from '../../../store';
import { observer } from 'mobx-react';
import { EditContent, EditingWrap, OutlineSave,  PencilOutlines } from '../../style/control/SpeakControlPresenter';
import { Mqtt } from '../../../store/domainstore/mqtt';

const HomeUserPresenter = observer((props) => {
  const { ModalUiStore, UserStore, DeleteModalUiStore, LikuUiStore, SuccessFailureModalUiStore } = RootStore();
  const [visible, setVisible] = useState(false);
  const visibleTrue = () => setVisible(true);
  const visibleFalse = () => setVisible(false);

  return (
    <UserContent>
      <UserTitle>등록된 사용자</UserTitle>
      <UserView>
        {console.log(UserStore.userList)}
        {UserStore.userList.map((person, index) => (
           <UserBox key={index}>
            <UserProfile>
              <UserCircle />
              {/* {save === true  && props.speakingIndex === index && */}
              <UserText>
                {person}
              </UserText>
            </UserProfile>
           {visible === false ||  props.speakingIndex !== index ? (
             <>
             <div onClick={() => {
                  props.setSpeakingIndex(index);
                  visibleTrue();
                }}>
              <UserSetting src={UserBtn}
                   />
               <PencilOutlines />
             </div>
             </>
          ) : (
            props.speakingIndex === index  &&
            <>
              <UserEditWrap src={EditBtn} />
              <UserEditMainWrap>
                <EditWrap 
                  onClick={async() => {
                    if (LikuUiStore.thisLikuState !== '자는중') {
                      await UserStore.setReUser(person);
                    await ModalUiStore.setOpen('userCheckModal');
                    await Mqtt.setUserRetryTopic(person);
                    await Mqtt.getImageTopic();
                    await visibleFalse();
                    } else {
                      await SuccessFailureModalUiStore.setModalType('likuSleep');
                      await SuccessFailureModalUiStore.setResult(false);
                      await ModalUiStore.setOpen('successFailureModal');
                    }
                  }}>
                  <PencilOutlineEdit />
                  수정
                </EditWrap>
                <DashLine />
                <EditWrap onClick={async () =>  
                  {
                    await ModalUiStore.setOpen('waitModal');
                    await Mqtt.setUserDeleteTopic(person);
                    visibleFalse();
                  }}>
                  <TrashIcon />
                  제거
                </EditWrap>
              </UserEditMainWrap>
            </>
              )}
         </UserBox>
        ))}
      </UserView>
      <UserAddButton 
        onClick={async ()=> {
          if (LikuUiStore.thisLikuState !== '자는중') {
            ModalUiStore.setOpen('userEnrollmentModal')
          } else {
            await SuccessFailureModalUiStore.setModalType('likuSleep');
            await SuccessFailureModalUiStore.setResult(false);
            await ModalUiStore.setOpen('successFailureModal');
          }
        }}
        >
        <UserAddButtonText>사용자 추가</UserAddButtonText>
        <UserPlus /> 
      </UserAddButton>
    </UserContent>
  )
});

export default HomeUserPresenter
