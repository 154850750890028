import { observer } from 'mobx-react'
import React from 'react'
import { Mqtt } from '../../../store/domainstore/mqtt'
import { ColumnVolumeSlider, SliderText, RemoteVolumeBox, VolumeBox, VolumeIcon, VolumeTitleWrap } from '../../style/control/RemoteVolumePresenter'

const RemoteVolumePresenter = observer(() => {
  // console.log('mqtt.likuVolume', Mqtt.likuVolume);
  // console.log('mqtt.likuVolume', Mqtt.volumeAppList);
  // console.log('mqtt.likuVolume', Mqtt.volumeAppList.indexOf(Mqtt.likuVolume));
  const renderSlider = optionsArray => (
    <ColumnVolumeSlider 
      type="range"
      step="1"
      min="0" 
      max={optionsArray.length - 1}
      value={optionsArray.indexOf(Number(Mqtt.likuVolume))}
      onChange={(e) => {
        Mqtt.setLikuVolume(optionsArray[e.target.value]);
        Mqtt.setVolumeTopic(optionsArray[e.target.value]);
      }}
    />
  );


  return (
    <RemoteVolumeBox>
      <VolumeBox>
        <VolumeTitleWrap>
          {renderSlider(Mqtt.volumeAppList)}
          <VolumeIcon />
          <SliderText>{Mqtt.likuVolume}</SliderText>
        </VolumeTitleWrap>
      </VolumeBox>
    </RemoteVolumeBox>
  )
});

export default RemoteVolumePresenter
