import { observer } from 'mobx-react';
import React from 'react'
import { IoPencilOutline } from 'react-icons/io5';
import RootStore from '../../../../store';
import { ModalWrapper, SubmitButton, TitleWrap, CloseBtn, Close, CloseBtnTop} from '../../../style/modal/connect/LikuConnectModalPresenter';
import { ModalTitleWrap, ModalTitle, LikuStateContent, LikuStateMemo } from '../../../style/modal/HomeLikuStateModalPresenter';
import { TitleIconWrap } from '../../../style/modal/wifi/HomeLikuWifiModalPresenter';
import { defaultTheme } from '../../../style/Theme';
import LikuHardwareCheckPresenter from './LikuHardwareCheckPresenter';
import LikuNetworkCheckPresenter from './LikuNetworkCheckPresenter';

const HomeLikuStateMemoModalPresenter = observer(({open}) => {
	const { ModalUiStore, LikuUiStore } = RootStore();
  const onChange = (e) => {
    LikuUiStore.setThisLikuStateMemo(e.target.value);
  }; 

	return (
    <ModalWrapper
      visible={open}>
      <ModalTitleWrap>
        <TitleIconWrap>
          <TitleWrap>
            <IoPencilOutline size={defaultTheme.fonts.size.base} />
            <ModalTitle>리쿠의 상태를 입력하세요.</ModalTitle>
          </TitleWrap>
          <CloseBtnTop  onClick={ async () => {
         await ModalUiStore.setOpen('likuStateModal');
          }} >
            <Close />
          </CloseBtnTop>
        </TitleIconWrap>
			<LikuStateContent>
				<LikuHardwareCheckPresenter />
				<LikuNetworkCheckPresenter />
				<LikuStateMemo 
          value={LikuUiStore.thisLikuStateMemo} 
          cols="50" rows="10" 
          onChange={onChange}
          placeholder='이외 특이사항 메모' />
			</LikuStateContent>
			<SubmitButton
				onClick={() => {
          LikuUiStore.saveMemo();
          ModalUiStore.setOpen('likuStateModal');
          }}>등록</SubmitButton>
      </ModalTitleWrap>
      {/* {<CloseBtn onClick={() => ModalUiStore.setOpen('likuStateModal')} />} */}
  </ModalWrapper>
	)
});

export default HomeLikuStateMemoModalPresenter;
