import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
// import { defaultTheme } from '../../../style/Theme';
import RootStore from '../../../store/index';
// import { requestPermission } from '../../../../service/RequestPermission';
import Loader from 'react-loader-spinner';

import {postTokenFetchText} from '../../../store/api/ServerApi';

import {ModalWrap, ModalContexts, ModalInfo, ModalButton} from '../../style/modal/LikuVersionModal';

const LikuVersionModalContainer = observer(({setModal}) => {
    const { MainUpdateModalUiStore } = RootStore();


    const [version, setVersion] = useState('버전 체크 중...');

    useEffect(async () => {
        const ver = await MainUpdateModalUiStore.updateCheck();

        console.log('ver', ver);
        console.log('isUpdate', ver.isUpdate);
        console.log('likuVer', ver.likuVer);
        if (ver.isUpdate) setModal(false);
        else setVersion(ver.likuVer);

        // let url = `https://api.likuwithme.com/liku/liku/liku`;
        // console.log('LikuUiStore.thisLiku.name', LikuUiStore.thisLiku.name);
        // let name = LikuUiStore.thisLiku.name.replace('LIKU: ', '');
        // let data = {serial: name};
        // await postTokenFetchText(url, data).then(r => {
        //     console.log('rrr', r);
        //     let data = JSON.parse(r.replaceAll('"{','{').replaceAll('}"','}'));
        //     console.log('data', typeof data);
        //     console.log('data.likuVer', data.version.likuVer);
        //     setVersion(data.version.likuVer);
        // });

    }, []);

    return (
        <ModalWrap>
            <ModalContexts>
                <ModalInfo>
                    Liku Version : {version}
                </ModalInfo>
                <ModalButton onClick={() => setModal(false)}>확인</ModalButton>
            </ModalContexts>
        </ModalWrap>
    );
});

export default LikuVersionModalContainer;
