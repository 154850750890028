import React, { useState } from "react";
import {
  ChevronICon,
  IconWrap,
  MotionBox,
  MotionBoxWrap,
  MotionListIcon,
  MotionListItem,
  MotionListWrap,
  MotionTitle,
  MotionTitleWrap,
  MotionValue,
  MotionValueBox,
} from "../../style/control/ControlLikuSpeakContainer";
import FlatList from "flatlist-react";

const ControlLikuSpeakContainer = ({
  type,
  icon,
  data,
  value,
  choseFunction,
}) => {
  const [visible, setIsVisible] = useState(false);
  const [click, setClick] = useState();
  const handleChange = () => setIsVisible(!visible);
  const handlClick = (idx) => setClick(idx);

  const renderPerson = (person, idx) => {
    return (
      <MotionListItem
        key={idx}
        click={click === idx}
        onClick={() => {
          handlClick(idx);
          choseFunction(person);
          handleChange();
        }}
      >
        <MotionListIcon src={data[person].icon} />
        {data[person].name}
      </MotionListItem>
    );
  };

  return (
    <>
      <MotionBoxWrap>
        <MotionBox onClick={() => handleChange()}>
          <MotionTitleWrap>
            <IconWrap>
              {icon}
              <MotionTitle>{type}</MotionTitle>
            </IconWrap>
            <ChevronICon />
          </MotionTitleWrap>
        </MotionBox>
        {visible === true ? (
          <MotionListWrap>
            <FlatList list={Object.keys(data)} renderItem={renderPerson} />
          </MotionListWrap>
        ) : (
          <MotionValueBox>
            <MotionValue>
              {data[value] !== undefined &&
                data[value] !== null &&
                data[value].name}
            </MotionValue>
          </MotionValueBox>
        )}
      </MotionBoxWrap>
    </>
  );
};

export default ControlLikuSpeakContainer;
