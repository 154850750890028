import styled, {css, keyframes} from "styled-components";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
// import { defaultTheme } from "../Theme";
import { defaultTheme } from "../Theme";
import {VscChromeClose} from "react-icons/vsc";

export const Modal = styled.div`
  display: block;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 100;
`;

export const ModalContext = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  //box-shadow: 24;
`;

export const ModalTitleWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  //background-color: #1d39c4;
`;

export const Close = styled(VscChromeClose)`
  //margin-bottom: 0.125rem;
  font-size: 0.825rem;
  color: white;
`;


export const NextButton = styled.button`
  position: absolute;
  bottom: 0;
  
  width: 100%;
  height: 2.5rem;
  border: 0;

  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: ${props => props.disabled ? '#000000' : defaultTheme.colors.default};
`;
