import React, { useState } from 'react'
import { LikuSettingBoxWrap, LkiuSettingIcon, LkiuSettingIconDel, LkiuSettingIconMemo, LkiuSettingText } from '../../style/home/LikuSettingBoxPresenter';
import repeat from "../../../resource/img/repeat.png";
import receipt from "../../../resource/img/receipt.png";
import del from "../../../resource/img/delete.png";
import RootStore from '../../../store';
import { requestPermission } from '../../../service/RequestPermission';

const LikuSettingBoxPresenter = ({ title, id, size }) => {
	const { LikuUiStore, ModalUiStore } = RootStore();
	const [click, setClick] = useState(false);
	const handleChange = () => setClick(!click);

	return (
		<>
			<LikuSettingBoxWrap 
				size={size} click={click}
				onClick={() => {
					id === 'memo' && handleChange();
					id === 'restart' && ModalUiStore.setOpen('restartLikuModal');
					// id === 'restart' && LikuUiStore.preRestartLiku();
					id === 'memo' &&  ModalUiStore.setOpen('likuStateModal');
					id === 'del' && ModalUiStore.setOpen('deleteLikuModal');
					}}>
				{id === 'restart' && <LkiuSettingIcon src={repeat}  /> }
				{id === 'memo' && <LkiuSettingIconMemo src={receipt} opacity={click} /> }
				{id === 'del' && <LkiuSettingIconDel src={del} /> }
				<LkiuSettingText $type={title}>{title}</LkiuSettingText>
			</LikuSettingBoxWrap>
		</>
	)
}

export default LikuSettingBoxPresenter
