import styled from "styled-components";
import { defaultTheme } from "../Theme";

export const ReactionBox = styled.div`
  width: 30%;
  height: 40%;
  border-radius: 5px;
  margin: 0.43rem 0.3rem;
  background-color: white;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  padding: 0.25rem;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ReactionBoxs = styled.div`
  width: 30%;
  height: 40%;
  border-radius: 5px;
  margin: 0.43rem 0.25rem;
  background-color: white;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  padding: 0.25rem;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ReactionTitle = styled.div`
  font-size: 0.725rem;
  font-weight: bold;
  ${defaultTheme.device.tablet} {
    font-size: 1.125rem;
  }
`;

export const ReactionInfo = styled.div`
  font-size: 0.5rem;
  margin-top: -2%;
  letter-spacing: -0.01rem;
  ${defaultTheme.device.tablet} {
    font-size: 0.825rem;
  }
`;

export const ReactionInfoRed = styled.div`
  font-size: 0.5rem;
  margin-top: -2%;
  letter-spacing: -0.01rem;
  color: #e87282;
  font-weight: 600;
  ${defaultTheme.device.tablet} {
    font-size: 0.825rem;
  }
`;
