import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import HomeHeaderPresenter from '../presenter/HomeHeaderPresenter'
import { ContainerWrap, ContentWrap, MainImg } from '../style/ContainerStyled'
import { HeaderWrap } from '../style/header/HeaderStyled'
import bg from "../../resource/img/5.png";
import LikuConnectModalPresenter from '../presenter/modal/connect/LikuConnectModalPresenter';
import SuccessFailureModalPresenter from '../presenter/modal/SuccessFailureModalPresenter';
import WaitModalContainer from '../container/modal/WaitModalContainer';
import HomeLikuWiFiSettingModalPresenter from '../presenter/modal/connect/HomeLikuWiFiSettingModalPresenter';
import HomeLikuStateMemoModalPresenter from '../presenter/home/modal/HomeLikuStateMemoModalPresenter';
import HomeLikuUserModalPresenter from '../presenter/home/modal/HomeLikuUserModalPresenter';
import HomeLikuUserRecognizePresenter from '../presenter/home/modal/HomeLikuUserRecognizePresenter';
import DeleteModalPresenter from '../presenter/modal/DeleteModalPresenter';
import HomeLikuUserCheckModalPresenter from '../presenter/home/modal/HomeLikuUserCheckModalPresenter';
import RootStore from '../../store'
import HomeBluetoothModal from '../presenter/home/modal/HomeBluetoothModal'
import DeleteLikuModalPresenter from "../presenter/modal/DeleteLikuModalPresenter";

const IntroPage = observer((props) => {
	const { LikuUiStore, ModalUiStore, DeleteModalUiStore, DrawerStore, LikuConnectModalUiStore, SuccessFailureModalUiStore, WiFiSettingModalUiStore } = RootStore();

  return (
    <>
     	<ContainerWrap>
				<HeaderWrap>
					<HomeHeaderPresenter
						page='IntroPage'
						history={props.history}
						subTitle="← 리쿠를 추가/선택 해주세요." />
				</HeaderWrap>
				<ContentWrap>
          <MainImg src={bg} />
				</ContentWrap>
			</ContainerWrap>
			<LikuConnectModalPresenter
				open={ModalUiStore.open.likuConnectModal}
				setOpen={ModalUiStore.setOpen.bind(ModalUiStore)}
				likuConnectFunction={LikuConnectModalUiStore.likuConnectFunction.bind(
					LikuConnectModalUiStore)}
			/>
			<SuccessFailureModalPresenter
					open={ModalUiStore.open.successFailureModal}
					setOpen={ModalUiStore.setOpen.bind(ModalUiStore)}
					result={SuccessFailureModalUiStore.result}
					modal={SuccessFailureModalUiStore.modal}
				/>
			<WaitModalContainer />
			<HomeLikuWiFiSettingModalPresenter
					open={ModalUiStore.open.wifiSettingModal}
					setOepn={ModalUiStore.setOpen.bind(ModalUiStore)}
					resetWiFi={WiFiSettingModalUiStore.resetWifi.bind(
						WiFiSettingModalUiStore,
					)}
					setWiFiFunction={WiFiSettingModalUiStore.setWiFi.bind(
						WiFiSettingModalUiStore,
					)}
					IsDisabled={WiFiSettingModalUiStore.IsDisabled}
				/>
				<HomeLikuStateMemoModalPresenter
					open={ModalUiStore.open.likuStateModal}
					setOepn={ModalUiStore.setOpen.bind(ModalUiStore)}
				/>
				<HomeLikuUserModalPresenter
					open={ModalUiStore.open.userEnrollmentModal}
					setOepn={ModalUiStore.setOpen.bind(ModalUiStore)}
				/>
				<HomeLikuUserCheckModalPresenter
					open={ModalUiStore.open.userCheckModal}
					setOepn={ModalUiStore.setOpen.bind(ModalUiStore)}
				/>
				<HomeLikuUserRecognizePresenter
					open={ModalUiStore.open.userRecognizeModal}
					setOepn={ModalUiStore.setOpen.bind(ModalUiStore)}
				/>
				<HomeBluetoothModal
					open={ModalUiStore.open.bluetoothModal}
					setOepn={ModalUiStore.setOpen.bind(ModalUiStore)}
				/>
				<DeleteModalPresenter
          open={ModalUiStore.open.deleteModal}
          setOpen={ModalUiStore.setOpen.bind(ModalUiStore)}
          item={DeleteModalUiStore.item}
          setDeleteFunction={DeleteModalUiStore.setDeleteFunction.bind(
            DeleteModalUiStore,
          )} />

		<DeleteLikuModalPresenter
			open={ModalUiStore.open.deleteLikuModal}
			setOpen={ModalUiStore.setOpen.bind(ModalUiStore)}
			item={DeleteModalUiStore.item}
			history={props.history}
		/>
    </>
  )
});

export default IntroPage
