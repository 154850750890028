import { Spin } from 'antd';
import React from 'react'
import { SpinModalContent, SpinnerModalWrap } from '../../style/modal/connect/LikuConnectModalPresenter';

const SpinnerModal = ({   
  open,
}) => {
  return (
		<SpinnerModalWrap
		visible={open}
	>
    <SpinModalContent>
      <Spin size='large' />
    </SpinModalContent>
  </SpinnerModalWrap>
  )
}

export default SpinnerModal;
