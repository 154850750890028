import React from 'react'
import { ModalWrapper } from '../../style/modal/connect/LikuConnectModalPresenter';
import { ModalFailureIcon, ModalInfoText, ModalSuccessIcon, ModalTitleText, ModalWrap } from '../../style/modal/modal';

const SuccessFailureModalPresenter = ({   
  open,
  setOpen,
  result,
  modal,
}) => {
  return (
		<ModalWrapper
		visible={open}
	>
    <ModalWrap>
      {result ? 
        <ModalSuccessIcon  />
          : 
          <ModalFailureIcon 
					// restart={result} 
					/>
        } 
			<ModalTitleText>{result ? modal.success : modal.failure}</ModalTitleText>
			<ModalInfoText>{result ? '' : modal.failureInfo}</ModalInfoText>
    </ModalWrap>  
  </ModalWrapper>
  )
}

export default SuccessFailureModalPresenter
