import styled from "styled-components";
import { defaultTheme } from "../Theme";
import { BsAlarm } from 'react-icons/bs';
import { ImStop2 } from "react-icons/im";

export const ControlWrap = styled.div`
  position: relative;
  height: 88%;
  width: 94%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0  auto;
  flex-direction: column;
  background-color: white;
  border-radius: .625rem;
  box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.16);
`;

export const StateContent = styled.div`
  height: 15%;
  width: 100%;
  margin: .25rem 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: end;
`;

export const LikuStateBtn = styled.div`
  display: flex;
  /* margin-right: ${props => 
    props.title === 'state' ? '0%' : '.625rem'}; */
  justify-content: center;
  align-items: center;
  border-radius: .5rem;
  background-color: white;
  font-size: .625rem;
  font-weight: bold;
  box-shadow: 2px 1px 5px 0 rgba(0, 0, 0, 0.18);
  margin-top: ${props => 
    props.title === 'state' ? '0rem' : '.5rem'};
  height: ${props => 
    props.title === 'state' ? '32%' : '60%'};
  width: ${props => 
    props.title === 'state' ? '100%' : '45%'};
    ${defaultTheme.device.tablet} {
      font-size: 1rem;
    }
`;

export const LikuMotionImg = styled.img`
  height: 40%;
  /* width: 20%; */
`;

export const LikuBoardWrap = styled.div`
  height: 60%;
  width: 100%;
  background-color: white;
  border-radius: .5rem; 
  position: relative;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.16);
`;

export const LikuBoard = styled.div`

`;

export const LikuIconWrap = styled.div`
  position: absolute;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  left: 0;
  top: -34px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

`;

export const LikuIconImageWrap = styled.div`
  background-color: ${props =>
  props.state === '자는중' ? '#929292' : '#929292'};
  width: 75%;
  height: 75%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LikuIconImage = styled.img`
 height: 70%;
`;

export const LikuStopWrap = styled.div`
  position: absolute;
  width: 40%;
  height: 40%;
  right: 0;
  bottom: -38px;
  border-radius: .5rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0.16);
`;

export const LikuStopBtnWrap = styled.div`
  position: absolute;
  bottom: .1rem;
  right: .25rem;
  width: 34%;
  height: 26%;
  border-radius: .5rem;
  background-color: #4a4a4a;
  display: flex;
  margin-bottom: .25rem;
  /* align-items: center; */
  justify-content: center;
  ${defaultTheme.device.tablet} {
    width: 30%;
  }
`;

export const LikuStopBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: .725rem;
  color: white;
  font-weight: bold;
  ${defaultTheme.device.tablet} {
    font-size: 1rem;
  }
`;

export const AlarmIcon = styled(BsAlarm)`
  ${defaultTheme.device.tablet} {
    font-size: 1.5rem;
  }
`;

export const StopIcon = styled(ImStop2)`
  margin-right: 0.2rem;
  ${defaultTheme.device.tablet} {
    font-size: 1.5rem;
  }
`;

export const LikuVolumeWrap = styled.div`
  height: 40%;
  width: 100%;
  display: flex;
  border-radius: 1rem;
  align-items: center;
  margin: .3rem 0;
  justify-content: center;
  /* box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.16); */
`;

export const LikuVolumeWrapMain = styled.div`
  height: 34%;
  width: 100%;
  display: flex;
  border-radius: 1rem;
  align-items: center;
  margin: .125rem 0;
  /* justify-content: center; */
  /* background-color: saddlebrown; */
  /* box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.16); */
`;