import {observable, runInAction} from 'mobx';
// import UserRepository from '../../data/repository/UserRepository';
import User from '../../data/model/User';
import { getCookie } from '../../utils/cookie';
import {LikuUiStore} from '../uistore/LikuUiStore';
import { ModalUiStore } from '../uistore/modal/ModalUiStore';
import {SuccessFailureModalUiStore} from '../uistore/modal/SuccessFailureModalUiStore';

const UserStore = observable({
  userList: [],
  user: '',
  userReEnrollment: false,
  userClick: false,
  userRequest: '',

  setUserRequest(topic) {
    this.userRequest = topic; 
  },

  setTrueClick() {
    this.userClick = true;
  },

  setFalseClick() {
    this.userClick = false;
  },

  setUserList(name) {
    this.userList = name;
  },

  setUser(name) {
    this.user = name;
    // console.log('name', this.user);
  },

  setReEnrollment() {
    this.userReEnrollment = !this.userReEnrollment;
    console.log('userReEnrollment', this.userReEnrollment);
  },

  setReUser(name) {
    this.user = name;
    // console.log('name', this.user);
  },

  setResetUser() {
    this.user = '';
    this.setFalseClick();
    // console.log('name', this.user);
  },

  nameCheck(name) {
    let pattern =
      /[\{\}\[\]\/?.,;:|\)*~`!^♡☆×÷₩¥£•○●□■◇€°※¤《》¡¿\-_+<>@\#$%&\\\=\(\'\"[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]]/;
    console.log('!pattern.test(name)', !pattern.test(name));
    console.log('name.length', name.length);
    console.log('userList', this.userList);
    console.log('userListtttttt', this.userList.indexOf(name) === -1);
    console.log('aaaa', 
    this.userList.indexOf(name) === -1 &&
    name.length >= 2 &&
    !pattern.test(name));
    if (
      this.userList.indexOf(name) === -1 &&
      name.length >= 2 &&
      !pattern.test(name)
    ) {
      return true;
    } else {
      return false;
    }
  },


  findDefaultUser() {
    return this.users.find(e => e.userName === '일반');
  },
});

export {UserStore};
