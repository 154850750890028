import { ServerStore } from "../domainstore/ServerStore";

export async function authenticate() {
  let URL = `${ServerStore.BASE_URL}/authenticate`;
  let BODY = ServerStore.account;

  return await fetch(URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(BODY),
  })
    .then(text => text.json())
    .then(result => {
      // console.log('authenticate:', result);
      return result;
    })
    .catch(error => {
      // console.log('authenticate : ', error);
    });
}

export async function serverFetchJson(URL, BODY) {
  return await fetch(URL, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Connection: 'close',
      type: 'getUserData',
      Authorization: `Bearer ${ServerStore.tokenJson.token}`,
    },
    Connection: 'close',
    type: 'getUserData',
    body: JSON.stringify(BODY), //data
  })
    .then(text => text.json())
    .then(result => {
      // console.log('serverFetch:', result);
      return result;
    })
    .catch(error => {
      // console.log('serverFetch : ', error);
    });
}

export async function postTokenFetchText(URL, BODY) {
  return await fetch(URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ServerStore.tokenJson.token}`,
    },
    Connection: 'close',
    type: 'getUserData',
    body: JSON.stringify(BODY),
  })
      .then(text => text.text())
      .then(result => {
        console.log('result', result);
        return result;
      })
      .catch(error => {
        console.log('serverFetch : ', error);
      });
}

export async function serverFetchText(URL, BODY) {
  return await fetch(URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ServerStore.tokenJson.token}`,
    },
    body: JSON.stringify(BODY),
  })
    .then(text => text.text())
    .then(result => {
      return result;
    })
    .catch(error => {
      // console.log('serverFetch : ', error);
    });
}
