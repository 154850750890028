import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react'
import { GrClose } from 'react-icons/gr';
import { IoPencilOutline } from 'react-icons/io5';
import RootStore from '../../../../store';
import { Mqtt } from '../../../../store/domainstore/mqtt';
import { Close, CloseBtn, CloseBtnTop, ModalWrapper, SubmitButton, TitleWrap } from '../../../style/modal/connect/LikuConnectModalPresenter';
import { LikuUserContent, ModalTitle, ModalTitleWrap, UseName, UserSub, UserTitle, UserWrap } from '../../../style/modal/HomeLikuStateModalPresenter';
import { TitleIconWrap } from '../../../style/modal/wifi/HomeLikuWifiModalPresenter';
import { defaultTheme } from '../../../style/Theme';

const HomeLikuUserModalPresenter = observer(({open}) => {
	const { ModalUiStore, UserStore } = RootStore();
	const onChange = (e) => {
    UserStore.setUser(e.target.value);
		UserStore.nameCheck(e.target.value);
		if(UserStore.nameCheck(e.target.value)) {
			UserStore.setTrueClick();
		} else { 
			UserStore.setFalseClick();
		}
  };

	const UserEnrollment = () => {
			ModalUiStore.setOpen('userEnrollmentModal');
			Mqtt.setUserNameTopic();
			ModalUiStore.setOpen('userCheckModal');
			Mqtt.getImageTopic();
	}

	const UserReEnrollment = async () => {
			await ModalUiStore.setOpen('userEnrollmentModal');
			await Mqtt.setUserRetryTopic(UserStore.user);
			await ModalUiStore.setOpen('userCheckModal');
	}

	useEffect(() => {
		console.log('UserStore.userReEnrollment', UserStore.userReEnrollment);
	}, [])

	return (
    <ModalWrapper
      visible={open}>
      <ModalTitleWrap>
        <TitleIconWrap>
          <TitleWrap>
            <IoPencilOutline size={defaultTheme.fonts.size.base} />
            <ModalTitle>사용자 정보를 입력하세요.</ModalTitle>
          </TitleWrap>
					<CloseBtnTop onClick={ async () => {
						await ModalUiStore.setOpen('userEnrollmentModal');
						await UserStore.setResetUser();
					}} >
						<Close />
      		</CloseBtnTop>
        </TitleIconWrap>
			<LikuUserContent>
				<UserWrap>
					<UserTitle>이름</UserTitle>
					<UserSub>:</UserSub>
					<UseName value={UserStore.user} onChange={onChange} placeholder='사용자 이름을 입력하세요.' />
				</UserWrap>
			</LikuUserContent>
			<SubmitButton
				click={UserStore.userClick}
				onClick={() => {
					UserStore.userClick === true && UserEnrollment();
				}}>등록</SubmitButton>
      </ModalTitleWrap>
  </ModalWrapper>
	)
});

export default HomeLikuUserModalPresenter
