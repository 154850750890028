import {observer} from 'mobx-react'
import React, {useEffect, useState} from 'react'
import {AiOutlineCaretDown, AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai';
import {RiErrorWarningLine} from 'react-icons/ri';
import RootStore from '../../../../store';
import {
    Border,
    PasswordHelperText,
    PasswordHelperTextWrap,
    PasswordIcon,
    PasswordInput,
    PasswordWrap,
    SelectText,
    SettingContext,
    WifiListDrop,
    WifiNameButton,
    WiFiNameText
} from '../../../style/modal/connect/HomeLikuWiFiSettingContainer';

const HomeLikuWiFiSettingContainer = observer(() => {
    const {WiFiSettingModalUiStore, LikuWebPostStore} = RootStore();
    const [visible, setVisible] = useState(false);
    // const [ wifiPassword, setWifiPassword ] = useState('');
    const [passwordType, setPasswordType] = useState({
        type: 'password',
        visible: false
    });
    const showWifiList = () => setVisible(!visible);

    const onChangePassword = (e) => {
        WiFiSettingModalUiStore.setWiFiPassword(e.target.value);
    };
    const onChangeId = (e) => {
        WiFiSettingModalUiStore.setWiFiId(e.target.value);
    };

    useEffect(() => {
        WiFiSettingModalUiStore.IsDisabledWifiButton();
    }, []);

    const handlePasswordType = e => {
        setPasswordType(() => {
            if (!passwordType.visible) {
                return {type: 'text', visible: true};
            }
            return {type: 'password', visible: false};
        })
    }
    return (
        <>
            <SettingContext>
                <WifiNameButton onClick={async () => {
                    // WiFiSettingModalUiStore.resetWifi();
                    await showWifiList();
                }}>
                    <WiFiNameText>{WiFiSettingModalUiStore.choseWiFi.ssid}</WiFiNameText>
                    <AiOutlineCaretDown/>
                </WifiNameButton>
                <Border/>
                {WiFiSettingModalUiStore.choseWiFi.auth === '802.1x' && (
                    <>
                        <PasswordWrap>
                            <PasswordInput
                                placeholder='아이디 입력'
                                bordered={false}
                                style={{marginTop: ' 4%'}}
                                maxLength={32}
                                // type={passwordType.type}
                                value={WiFiSettingModalUiStore.wifiId}
                                onChange={onChangeId}
                            />
                            <PasswordIcon onClick={handlePasswordType} visible={false}>
                                {passwordType.visible ? <AiOutlineEye size='1.25rem'/> :
                                    <AiOutlineEyeInvisible size='1.25rem'/>}
                            </PasswordIcon>
                        </PasswordWrap>
                        <Border/>
                        {/*<PasswordHelperTextWrap*/}
                        {/*    visible={WiFiSettingModalUiStore.hasErrors()}*/}
                        {/*>*/}
                        {/*    <RiErrorWarningLine size='1.25rem' color='red' style={{marginBottom: '0.15rem'}}/>*/}
                        {/*    <PasswordHelperText>아이디 8자리 이상 입력해주세요.</PasswordHelperText>*/}
                        {/*</PasswordHelperTextWrap>*/}
                    </>
                )}
                {WiFiSettingModalUiStore.choseWiFi.key !== 'off' && (
                    <>
                        <PasswordWrap>
                            <PasswordInput
                                placeholder='비밀번호 입력'
                                bordered={false}
                                style={{marginTop: ' 4%'}}
                                maxLength={63}
                                placeholder="비밀번호 입력"
                                type={passwordType.type}
                                value={WiFiSettingModalUiStore.wifiPassword}
                                onChange={onChangePassword}
                            />
                            <PasswordIcon onClick={handlePasswordType} visible={true}>
                                {passwordType.visible ? <AiOutlineEye size='1.25rem'/> :
                                    <AiOutlineEyeInvisible size='1.25rem'/>}
                            </PasswordIcon>
                        </PasswordWrap>
                        <Border/>
                        {WiFiSettingModalUiStore.choseWiFi.auth !== '802.1x' && (
                            <PasswordHelperTextWrap
                                visible={WiFiSettingModalUiStore.hasErrors()}
                            >
                                <RiErrorWarningLine size='1.25rem' color='red' style={{marginBottom: '0.15rem'}}/>
                                <PasswordHelperText>비밀번호 8자리 이상 입력해주세요.</PasswordHelperText>
                            </PasswordHelperTextWrap>
                        )}
                    </>
                )}
            </SettingContext>
            {
                visible === true &&
                <WifiListDrop>
                    {LikuWebPostStore.wifiList.map((wifi, idx) => (
                        <SelectText
                            onClick={async () => {
                                WiFiSettingModalUiStore.setChoseWifi(wifi);
                                await showWifiList();
                            }}
                            value={wifi.id} key={idx}>{wifi.id}</SelectText>
                    ))}
                </WifiListDrop>

            }
        </>
    );
});

export default HomeLikuWiFiSettingContainer;
