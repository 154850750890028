import React from 'react'
import RootStore from '../../../store';
import { Close, CloseBtnTop, ModalWrapper } from '../../style/modal/connect/LikuConnectModalPresenter';
import { LikuRestartModalWrap, ButtonContent, SubmitButtons } from '../../style/modal/LikuRestartModalPresenter';
import {  ModalTitle2, ModalTitleText, ModalWrap } from '../../style/modal/modal';

const LikuRestartModalPresenter = ({
  open,
  setOpen,
  history
   }) => {
    const { LikuUiStore, ModalUiStore } = RootStore();

    return (
		<ModalWrapper
		visible={open}
	>
    <LikuRestartModalWrap>
      <ModalTitle2>
        <ModalTitleText>리쿠를 재시작 하시겠습니까?</ModalTitleText>
        <CloseBtnTop  onClick={() => ModalUiStore.setOpen('restartLikuModal')} >
          <Close />
        </CloseBtnTop>
      </ModalTitle2>
      <ButtonContent
        onClick={ async () =>{
          await ModalUiStore.setOpen('restartLikuModal');
          await LikuUiStore.preRestartLiku();
        }}>
        <SubmitButtons title='재시작'>확인</SubmitButtons>
      </ButtonContent>
    </LikuRestartModalWrap>
  </ModalWrapper>
  )
}

export default LikuRestartModalPresenter
