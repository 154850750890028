import React from "react";
import RootStore from "../../../store";
import {
  ReactionBox,
  ReactionInfo,
  ReactionInfoRed,
  ReactionTitle,
} from "../../style/control/ControlReactionContainer";

const ControlReactionContainer = () => {
  const { ControlUiStore } = RootStore();
  const renderPerson = (person, idx) => {
    return (
      <ReactionBox
        key={idx}
        onClick={() => {
          ControlUiStore.controlFunction(person, idx);
        }}
      >
        <ReactionTitle>{person.name}</ReactionTitle>
        {person.name === "앉기" ? (
          <ReactionInfoRed>{person.info}</ReactionInfoRed>
        ) : (
          <ReactionInfo>{person.info}</ReactionInfo>
        )}
      </ReactionBox>
    );
  };

  return (
    <>
      {ControlUiStore.reactionList.map((person, idx) => (
        <ReactionBox
          key={idx}
          onClick={() => {
            ControlUiStore.controlFunction(person, idx);
          }}
        >
          <ReactionTitle>{person.name}</ReactionTitle>
          {person.name === "앉기" ? (
            <ReactionInfoRed>{person.info}</ReactionInfoRed>
          ) : (
            <ReactionInfo>{person.info}</ReactionInfo>
          )}
        </ReactionBox>
      ))}
    </>
  );
};

export default ControlReactionContainer;
