const fonts = {
  size: {
    mini: '0.8rem',
    semini: '0.9rem',
    sm: '1.2rem',
    base: '1.6rem',
    lg: '2rem',
    xl: '2.5rem',
    sb: '3rem',
    big: '5rem',
    title: '6rem', 
  },
  weight: {
    light: 100,
    normal: 400,
    bold: 600
  }
};

const size = {
  mobile: '425px',
  tablet: "768px",
  largeTablet: "1024px",
  smallDesktop: "1280px",
  desktop: "1620px",
  largeDesktop: "2048px",
  bigDesktop: "2568px",
}

const colors = {
  modalButton: '#757575',
  default: '#4a4a4a',
  activation: '#5e9bf5',
  error: '#fe0000',
  white: '#feffff',
  darkWhite: '#fefeff',
  gray: '#e3e4e8',
  darkGray: '#929292',
  border: '#e2e2e2',
  primary: '#4a4a4a',
  background: '#fe0000',
};

const device = {
  mobile: `@media only screen and (min-width: ${size.mobile})`,
  tablet: `@media only screen and (min-width: ${size.tablet})`,
  largeTablet: `@media only screen and (min-width: ${size.largeTablet})`,
  smallDesktop: `@media only screen and (min-width: ${size.smallDesktop})`,
  desktop: `@media only screen and (min-width: ${size.desktop})`,
  largeDesktop: `@media only screen and (min-width: ${size.largeDesktop})`,
  bigDesktop: `@media only screen and (min-width: ${size.bigDesktop})`,
};


export const defaultTheme = {
  fonts,
  colors,
  device
}