import styled from "styled-components";
import { ButtonBox, ViewBox } from "../Box/Box";
import { defaultTheme } from "../Theme";
import { IoPencilOutline } from "react-icons/io5";
import UserBtn from '../../../resource/img/userBtn.png'
import { FaUserPlus, FaUserCircle, FaTrash } from "react-icons/fa";

export const UserContent = styled(ViewBox)`
  height: 100%;
  width: 100%;
  display: flex;
  /* margin-left: 2%; */
  align-items:  center;
  border-radius: 12px;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  justify-content: center;
  padding: 0 8%;
`;

export const UserTitle = styled.div`
  width: 100%;
  height: 12%;
  font-size: ${defaultTheme.fonts.size.semini};
  margin-top: 3%;
  font-weight: bold;
  //background: red;
  ${defaultTheme.device.tablet} {
    font-size: 1rem;
  }
`;

export const UserView = styled.div`
  width: 100%;
  max-height: 62%;
  /* height: 62%; */
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 2% 0;
  ::-webkit-scrollbar {
    width: .4rem;
    background-color: rgba(244, 242, 241, 0.4);
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ededed;
    border-radius: 0.8rem;
  }
`;

export const UserBox = styled.div`
	position: relative;
  //width: 22%;
  width: 97%;
  height: 2.3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f3f3f3;
  margin-bottom: 3%;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
  
  ${defaultTheme.device.tablet} {
    height: 4rem;
  }
`;

export const UserProfile = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 100%;
  padding: 0% 5%;
  align-items: center;
  justify-content: flex-start;
  /* background-color: darkcyan; */
`;

export const UserSetting = styled.img`
  width: 20%;
  height: 102%;
  top: 4%;
  position: absolute;
  right: -0.5rem;
  object-fit: cover;
  /* background: url(${UserBtn});
  background-size: cover; */
  ${defaultTheme.device.tablet} {
    width: 20%;
    height: 100%;
    right: -1.025rem;
    top: 0;
  }
`;

export const UserBtnImg = styled.img`
	/* position: absolute; */
	/* top: -5px; */
  margin-top: -0.3rem;
  right: -8px;
	width:60px;
	height: 55px;
`;

export const UserText = styled.div`
  font-size: .825rem;
  margin-left: 3%;
  /* background-color: red; */
  //text-align: center;
  ${defaultTheme.device.tablet} {
    font-size: 1.125rem;
  }
`;

export const UserAddButton = styled(ButtonBox)`
  display: flex;
  flex-direction: row;
  background: ${defaultTheme.colors.gray};
  width: 100%;
  height: 2.125rem;
  /* height: 32%; */
  /* height: 12%; */
  margin-top: 2%;
  margin-bottom: 3%;
  align-items: center;
  justify-content: center;
  ${defaultTheme.device.tablet} {
    height: 25%;
    margin-top: 2%;
  }
`;

export const UserAddButtonText = styled.div`
  font-size: ${defaultTheme.fonts.size.mini};
  font-weight: bold;
  margin: 2%;
  text-align: center;

  ${defaultTheme.device.tablet} {
    font-size: 1rem;
  }
`;

export const UserPlus = styled(FaUserPlus)`
 font-size: 1rem;
 ${defaultTheme.device.tablet} {
   font-size: 1.25rem;
 }
`;

export const UserCircle = styled(FaUserCircle)`
 font-size: 1.425rem;
 ${defaultTheme.device.tablet} {
   font-size: 2.5rem;
 }
`;

export const PencilOutline = styled(IoPencilOutline)`
  font-size: 1.5rem;
  position: absolute;
  color: white;
  right: 6px;
  top: 8px;
`;

export const PencilOutlineEdit = styled(IoPencilOutline)`
  font-size: 1.2rem;
  /* position: absolute; */
  color: white;
  /* right: 6px;
  top: 8px; */
  ${defaultTheme.device.tablet} {
    font-size: 1.8rem;
  }
`;

export const UserEditWrap = styled.img`
  position: absolute;
  width: 44%;
  height: 140%;
  object-fit: fill;
  top: -.4rem;
  right: -.5rem;
  ${defaultTheme.device.tablet} {
    top: -.4rem;
    right: -.8rem;
    width: 40%;
    height: 132%;
  }
`;

export const DashLine = styled.div`
  border-right: 2px dotted white;
  opacity: 0.5;
  height: 50%;
  /* width: 1%; */
  margin: 0 .5rem;
  /* margin-top: .75rem; */
`;

export const UserEditMainWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
  height: 100%;
  width: 40%;
  z-index: 99;
`;

export const EditWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  color: white;
  /* background-color: salmon;/ */
  font-size: 0.625rem;
  margin-top: 0.2rem;
  margin-right: 0.4rem;

  ${defaultTheme.device.tablet} {
    font-size: .8rem;
    margin-top: 0.5rem;
  margin-right: 0.25rem;
  }
`;

export const EditIcon = styled(IoPencilOutline)`
  font-size: 1rem;
  color: white;
  margin-bottom: 0.2rem;
`;

export const TrashIcon = styled(FaTrash)`
  font-size: 0.825rem;
  color: white;
  margin: 0.2rem 0;
  ${defaultTheme.device.tablet} {
    font-size: 1.5rem;
  }
`;

export const UserEditImg = styled.div`
  width: 100%;
  height: 130%;
  margin-left: 0.3rem;
  margin-top: -0.25rem;

`;