import {observable} from 'mobx';
import Liku from '../../data/model/Liku';
import {LikuStore} from './LikuStore';
import { LikuWebPostStore } from '../uistore/LikuWebPostStore';
import { requestPermission } from '../../service/RequestPermission';
import { LikuUiStore } from '../uistore/LikuUiStore';
import { WiFiSettingModalUiStore } from '../uistore/modal/WiFiSettingModalUiStore';
import {Mqtt} from "./mqtt";
import {MainUpdateModalUiStore} from "../uistore/MainUpdateModalUiStore";

const BluetoothStore = observable({
  async setWiFi(wifi) {
    console.log('=======', wifi)
    let index = LikuStore.findLiku(LikuUiStore.thisLiku);
    await LikuStore.setLikuWiFi(index, LikuWebPostStore.wifiInfo);
    // await this.disconnectLiku();
    console.log('wifiInfo.state : ', LikuWebPostStore.wifiInfo.state)
    WiFiSettingModalUiStore.setWiFiInfo();
    if (LikuWebPostStore.wifiInfo.state?.includes('_changeFail')) {
      console.log('false');
      return false;
    } else if (
     LikuWebPostStore.wifiInfo.state === 'CONNECTED' ||
     LikuWebPostStore.wifiInfo.state === 'COMPLETED'
    ) {
      console.log('true');
      // WiFiSettingModalUiStore.setWiFiInfo();
      return true;
    } else {
      console.log('false');
      // WiFiSettingModalUiStore.setWiFiInfo();
      return false;
    }
  },


  async updateLiku(liku, wifiData) {
    console.log('LikuWebPostStore.LikuData', LikuWebPostStore.LikuData);
    if (LikuWebPostStore.LikuData !== false) {
      let updateData = await LikuStore.updateLiku(liku, LikuWebPostStore.LikuData, wifiData);
      await LikuStore.saveLiku();
      console.log('updateData', updateData);
      return updateData;
    } else {
      return false;
    }
  },

  async enrollmentLiku(choseLikiu) {
    let liku = new Liku(
      choseLikiu.id,
      choseLikiu.name,
      null,
      LikuWebPostStore.LikuData.wifi,
      LikuWebPostStore.LikuData.battery
    );
    // console.log('likuliku', liku);
    await LikuStore.addLiku(liku);
    await LikuStore.saveLiku();
    // console.log('Likustore.saveLiku', LikuStore.likus);
    await this.disconnectLiku();
    return true;
  },

  async disconnectLiku() {
    // await RosStore.rosClose();
    await requestPermission({ type: "Liku_disconnect" });
  },

});

export {BluetoothStore};
