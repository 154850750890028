import styled from "styled-components";
import {defaultTheme} from "../Theme";

export const Modal = styled.div`
  display: block;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 999;
`;

export const Context = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  //box-shadow: 24;
  
  height: 20%;
  width: 80%;
  //padding: 3% 5%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const ContextWrap = styled.div`
  width: 90%;
  height: 70%;
  //padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  font-size: 6vw;
  text-align: center;
  vertical-align: center;
  width: 100%;
  margin: 0;
  //height: 10%;
  //margin-top: 2%;
`;

export const Info = styled.span`
  font-size: 3.5vw;
  text-align: center;
  width: 100%;
  //height: 15%;
  //margin: 8% 0;
`;

export const Button = styled.button`
  width: 100%;
  height: 30%;
  background-color: #757575;
  border: none;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  //text-align: left;
  font-size: 1rem;
  color: #d0d0d0;
`;
