import React, { useState } from 'react'
import { CheckContent, Item, MainText, OutlineCheck, OutlineChecks, RadioButton, RadioButtonLabel, RadioWrap, SubText, TextWrap, Wrapper } from '../../../style/modal/LikuStateCheckPresenter'

const LikuHardwareCheckPresenter = () => {
	const [selectHardware, setselectHardware] = useState('hdcomplete');
  const [click, setClick] = useState(1);
  const handleClick = () => setClick(0);
	const onChange1 = (e) => {
		setselectHardware(e.target.value);
    handleClick();
	}

  return (
		<CheckContent>
      <TextWrap>
        <MainText>하드웨어</MainText>
        <SubText>(관절, lcd등)</SubText>
      </TextWrap>
      <RadioWrap>
        <Wrapper>
          <Item>
            원활
            <RadioButton
              type="radio"
              name="radio"
              value="hdcomplete"
              color='blue'
              checked={selectHardware === "hdcomplete"}
              onChange={onChange1} />
            <RadioButtonLabel />
            {/* <OutlineCheck on={click ? 1 : 0} /> */}
          </Item>
          <Item>
            <div>문제</div>
            <RadioButton
              type="radio"
              name="radio"
              color='red'
              value="hdproblem"
              checked={selectHardware === "hdproblem"}
              onChange={onChange1} />
            <RadioButtonLabel />
            {/* <OutlineChecks on={click ? 1 : 0} /> */}
          </Item>
        </Wrapper>
      </RadioWrap>
		</CheckContent>
	)
}

export default LikuHardwareCheckPresenter;
