import styled from "styled-components";
import { ViewBox } from "../Box/Box";

export const WiFiControlBox = styled.img`
  width: 50%;
  /* height: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 1rem;
  justify-content: space-around; */
  /* border-bottom: ${props => props.$type == 'once' ? '' : '1px dashed #afaeae;'} */
`;

export const MenuTitle = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  color: #4a4a4a;
  display: flex;
  align-items: center;
  /* margin-top: .25rem; */
`;

export const IconImage = styled.img`
  /* width: 55%; */
  height: 42%;
  height: 42%;
  /* margin-bottom: 0.25rem; */
`;

export const MenuSubtitle = styled.div`
  font-size: .5rem;
  color: #4a4a4a;
`;

export const ImageButton = styled.div`
  //width: 100%;
  //height: 82%;
  //display: flex;
  //align-items: center;
  //justify-content: space-evenly;
  //border-radius: 20px;
  //box-shadow: inset 0 0 20px 0 rgba(0, 0, 0, 0.16);
  //background-color: #fefeff;


  width: 100%;
  height: 46%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 20px;
  box-shadow: inset 0 0 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #fefeff;

`;

export const IconImageWrap = styled.div`
  //display: flex;
  //width: 65%;
  //height: 100%;
  //flex-direction: column;
  //align-items: center;
  //justify-content: center;
  ///* justify-content: space-between; */


  display: flex;
  width: 80%;
  height: 55%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

`;

export const LangImage = styled.img`
  //height: 70%;

  height: 72%;
  margin-left: 0.25rem;

`;

export const OnOffImage = styled.img`
  ///* width: 64%; */
  //height: 70%;

  width: 64%;
  /* height: 70%; */

`;
