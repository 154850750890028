import styled from "styled-components";
import { HiOutlineCheck } from "react-icons/hi";
import { defaultTheme } from "../Theme";

export const CheckContent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 0.625rem;
`;

export const HardWareContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const TextWrap = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
	/* margin-right: 0.625rem; */
	width: 45%;
	/* background-color: rebeccapurple; */
`;

export const MainText = styled.div`
	font-size: 0.825rem;
	font-weight: 500;
	margin-right: 0.3rem;
	${defaultTheme.device.tablet} {
		font-size: 1.1rem;
	}
`;

export const SubText = styled.div`
	font-size: 0.6rem;
	${defaultTheme.device.tablet} {
		font-size: 0.825rem;
	}
`;

export const StateFieldset = styled.fieldset`
	font-size: 0.825rem;
	margin: 0 0.3rem;
	display: flex;
	align-items: center;
	flex-direction: row;
`;
export const StateRadio = styled.input`
		margin: 0 0.325rem;
  &:checked  {
    background: ${(props) => props.color};
  }
`;

export const RadioWrap = styled.div`
	/* width: 40%; */
`;

export const Wrapper = styled.form`
  /* height: auto; */
	width: 100%;
  box-sizing: border-box;
	display: flex;
	flex-direction: row;
	/* background-color: yellowgreen; */
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  height: 1rem;
	position: relative;
  box-sizing: border-box;
  /* border-radius: 2px; */
	font-size: 0.8rem;
	margin-right: 0.625rem;
	${defaultTheme.device.tablet} {
		font-size: 1rem;
		margin-top: .5rem;
	}
`;

export const RadioButtonLabel = styled.label`
	position: relative;
  width: 0.825rem;
  height: 0.825rem;
  border-radius: 50%;
  background: white;
	border: 1px solid #4a4a4a;
	margin-right: 1px;
`;

export const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  margin-right: -10px;
	
  &:checked + ${RadioButtonLabel} {
    background: ${props => 
		props.color === 'blue' ? 'skyblue' : 'pink'};
		border: none;
    /* &::after {
      display: block;
      color: white;
      width: 0.8rem;
      height: 0.8rem;
      margin: 4px;
    } */
  }
`;

export const OutlineCheck = styled(HiOutlineCheck)`
	position: absolute;
	top: 15%;
	right: 1px;
	display: ${props =>
		props.check ? 'none' : 'block'};
	color: white;
`;
export const OutlineChecks = styled(HiOutlineCheck)`
	position: absolute;
	top: 15%;
	right: 1px;
	display: ${props =>
		props.on ? 'block' : 'none'};
	color: white;
`;