import ControlConfig from "./ControlConfig";
import LikuConfig from "./LikuConfig";
import PermissionConfig from "./PermissionConfig";
import ServerConfig from "./ServerConfig";
import SpeakinListConfig from "./SpeakinListConfig";
const RootConfig = () => {
  ControlConfig();
  ServerConfig();
  PermissionConfig();
  SpeakinListConfig();
  LikuConfig();
};

export default RootConfig;